import {onError} from '@apollo/client/link/error'
import {toast} from 'react-toastify'
import {gql, Observable, Operation} from '@apollo/client'

import {client} from 'apollo/client'
import {ContainerId} from 'components/Toast/config'
import {removeUser} from 'repository/services'
import {isProductionNedyxEnv, shouldSuppressError, toCamelCase, uploadFile, uploadText} from 'utils'
import {downloadAction} from 'utils/downloadFromUrl'
import {getCookieForConnection} from 'templates/Settings/Widget/Connection/Auth/authenticationRequests'
import {
  setErrorVar,
  setIsAuthenticatedVar,
  setErrorMessageVar,
  setUserDataVar,
} from './cache/app.cache'
import {DataSourceOptionsDocument} from 'generated/graphql-operations'
import {cache} from './cache/cache'
import {ConnStatusT} from 'templates/Settings/Widget/Connection/model'
import {handleAffectedContent} from '../repository/graphql'

const logout = () => {
  setIsAuthenticatedVar(false)
  setUserDataVar(null)
  removeUser()
}

const createResponse = (operation: Operation, responseChannel: string) => {
  const {query, variables, operationName} = operation

  return async (eventdata = '') => {
    if ('operation' in query.definitions[0] && query.definitions[0].operation) {
      const operationType = query.definitions[0].operation

      const schema = gql`
        ${query}
      `
      const context = {
        ...operation.getContext(),
        headers: {
          ...operation.getContext().headers,
          eventChannel: responseChannel,
        },
      }

      const data = await (operationType === 'query'
        ? client.query({
            query: schema,
            context,
            variables: {...variables, eventdata},
            fetchPolicy: 'network-only',
          })
        : client.mutate({
            mutation: schema,
            context,
            variables: {...variables, eventdata},
          }))

      if (data.data) {
        const commands = data.data[toCamelCase(operationName)]?.commandResults
        if (commands) handleAffectedContent(commands)
      }
    }
  }
}

const updateConnectionStatus = (id: number, connectionStatus: ConnStatusT) => {
  cache.updateQuery(
    {
      query: DataSourceOptionsDocument,
      variables: {id},
    },
    (cachedValue) =>
      cachedValue && {
        getDataSourceOptions: {
          ...cachedValue.getDataSourceOptions,
          connectionStatus,
        },
      },
  )
}

const errorLink = onError(({graphQLErrors, networkError, operation, forward}) => {
  if (graphQLErrors && graphQLErrors[0]?.extensions) {
    const {code, applicationId, title, permission, validationErrors, workspaceName, data} =
      graphQLErrors[0].extensions

    if (code === 'REQUEST_FE_ACTION_ANSWER') {
      const {feAction, responseChannel} = graphQLErrors[0].extensions
      const runResponse = createResponse(operation, responseChannel)

      if (feAction === 'DOWNLOAD') {
        downloadAction(data.fileName, data.tempFolderPathForUI).then(runResponse)
      }

      if (['QUESTION', 'MESSAGE', 'CONNECT', 'SCANCODE'].includes(feAction)) {
        setErrorMessageVar({type: feAction, data, runResponse})
      }

      if (['UPLOAD', 'UPLOADFILE'].includes(feAction)) {
        const successHandler: Parameters<typeof uploadText | typeof uploadFile>[0] = (fileText) => {
          runResponse(JSON.stringify({accepted: true, value: fileText}))
        }

        const errorHandler: Parameters<typeof uploadText | typeof uploadFile>[1] = (value = '') => {
          runResponse(JSON.stringify({accepted: true, value}))
        }

        if (feAction === 'UPLOAD') uploadText(successHandler, errorHandler)
        if (feAction === 'UPLOADFILE') uploadFile(successHandler, errorHandler)
      }
      return
    }

    if (code === 'SET_VALID_DATASOURCE_CREDENTIALS_ERROR' && data) {
      return new Observable((observer) => {
        const {query, variables, operationName} = operation

        if ('operation' in query.definitions[0] && query.definitions[0].operation) {
          const widgetId = data.value.id || data.value.widgetId || variables.id
          const options = {options: data.value, widgetId}
          const operationType = query.definitions[0].operation
          const schema = gql`
            ${query}
          `

          if (operationName === 'ConnectToDataSource') {
            updateConnectionStatus(widgetId, 'PENDING')
          }

          getCookieForConnection(options)
            .then(async (status) => {
              const context = {
                ...operation.getContext(),
                headers: {
                  ...operation.getContext().headers,
                  eventChannel: data.responseChannel,
                },
              }

              const resp = await (operationType === 'query'
                ? client.query({
                    query: schema,
                    context,
                    variables: {...variables, eventdata: JSON.stringify({status})},
                    fetchPolicy: 'network-only',
                  })
                : client.mutate({
                    mutation: schema,
                    context,
                    variables: {...variables, eventdata: JSON.stringify({status})},
                  }))

              if (
                operationName === 'ConnectToDataSource' &&
                resp?.data?.connectToDataSource.affectedWidgets[0]
              ) {
                updateConnectionStatus(
                  widgetId,
                  resp.data.connectToDataSource.affectedWidgets[0].data.dataSourceOptions
                    .connectionStatus,
                )
              }
            })
            .then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              }

              forward(operation).subscribe(subscriber)
            })
        }
      })
    }

    if (code === 'UNAUTHENTICATED') return logout()

    if (code === 'WORKSPACE_NOT_SPECIFIED' || code === 'WORKSPACE_NOT_AVAILABLE') {
      const workspace = localStorage.getItem('workspaceName')
      if (workspace && workspaceName !== workspace)
        return window.location.replace('/not-found-workspace')

      localStorage.removeItem('workspaceName')
      return logout()
    }

    if (applicationId && title) {
      setErrorVar({
        message: graphQLErrors[0].message,
        payload: {applicationId, title, permission},
      })
      return
    }

    if (['SESSION_EXPIRED', 'COOKIE_EXPIRED'].includes(code)) {
      sessionStorage.removeItem('pagesByApps')
      sessionStorage.removeItem('currentPageId')
      return logout()
    }

    // todo: this is a good spot to log these errors to an error reporting service
    graphQLErrors.map(({message}) => {
      setErrorVar({message})

      if (message.includes('server error')) return message

      if (message === 'Argument Validation Error' && validationErrors?.length) {
        const error = validationErrors[0].constraints.matches
        toast.error(error, {toastId: 'apollo-validation-error', containerId: ContainerId.ROOT})
        return error
      }

      if (!shouldSuppressError(message)) {
        toast.error(message, {
          toastId: 'apollo-error',
          // (: ¯\_(ツ)_/¯ :)
          containerId: ContainerId[message.match(/last editor of/i)?.length ? 'DIALOG' : 'ROOT'],
        })
      }
      return message
    })
  }

  if (networkError) {
    setErrorVar({message: networkError.message})
    !isProductionNedyxEnv &&
      toast.error(networkError.message, {
        toastId: 'apollo-network-error',
        containerId: ContainerId.ROOT,
      })
  }
})

export default errorLink
