export function includesOne<T>(haystack: T[], needle: T[]): boolean {
  return needle.some((it) => haystack.includes(it))
}

export function includesAll<T>(haystack: T[], needle: T[]): boolean {
  return needle.every((it) => haystack.includes(it))
}

export function getIntersection<T>(arrA: T[], arrB: T[]): T[] {
  return arrA.filter((it) => arrB.includes(it))
}

export function getDifference<T>(arrA: T[], arrB: T[]): T[] {
  return arrA.filter((it) => !arrB.includes(it))
}

export function getSymmetricalDifference<T>(arrA: T[], arrB: T[]): T[] {
  return getDifference(arrA, arrB).concat(arrB.filter((it) => !arrA.includes(it)))
}

export function getUnion<T>(arrA: T[], arrB: T[]): T[] {
  return [...new Set([...arrA, ...arrB])]
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  return b[orderBy] < a[orderBy] ? -1 : b[orderBy] > a[orderBy] ? 1 : 0
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function getComparator<Key extends keyof any>(
  isDesc: boolean,
  orderBy: Key,
): (a: {[key in Key]: any}, b: {[key in Key]: any}) => number {
  return isDesc
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export function stableSortByType<T>(
  array: T[],
  comparator: (a: T, b: T) => number,
  orderTypes: {[key: string]: string},
): T[] {
  const result: {[key: string]: T[]} = {}

  for (const [key, name] of Object.entries(orderTypes)) {
    result[name] = array.filter((obj: T) => (obj as Record<string, unknown>)[key] === name)

  }


  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
