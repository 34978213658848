import { ActivePageBlockType } from 'apollo'

type HelperLinksType = ActivePageBlockType | 'widget' | 'function' | 'constant' | 'feature'

export const NEDYX_COMMUNITY = 'https://nedyx.invisionservice.com'
const BASE_URL = 'https://docs.nedyx.com'

export const helperLinks: { [key in HelperLinksType]?: string } = {
  codeEditor: `${BASE_URL}/clearlines-functions/general-aspects`,
  constant: `${BASE_URL}/constant`,
  feature: `${BASE_URL}/appendix/feature-preview`,
  function: `${BASE_URL}/clearlines-functions`,
  main: `${BASE_URL}/welcome-to-nedyx`,
  manageUsers: `${BASE_URL}/security/user-management/adding-users-to-a-workspace`,
  shareApp: `${BASE_URL}/security/user-management/share-an-app-with-other-users`,
  widget: `${BASE_URL}/clearlines-functions/general-aspects/widget-references`,
}

export enum GHRepo {
  FE = 'https://github.com/eteamio/nedyx-app-frontend',
  BE = 'https://github.com/eteamio/nedyx-app-backend',
}

export const functionLinks: { [key: string]: string } = {
  abs: '/mathematical-functions/abs',
  actioncolumn: '/action-functions/actioncolumn',
  actioncontent: '/action-functions/actioncontent',
  actionrow: '/action-functions/actionrow',
  adduser: '/user-management-functions/adduser',
  aggregate: '/range-functions/aggregate',
  and: '/logical-functions/and',
  applist: '/user-management-functions/applist',
  appsharelist: '/user-management-functions/appsharelist',
  callurl: '/import-export-functions/callurl',
  cell: '/range-functions/cell',
  cellref: '/range-functions/cellref',
  char: '/text-functions/char',
  clear: '/range-functions/clear',
  click: '/action-functions/click',
  close: '/navigation-functions/close',
  code: '/text-functions/code',
  column: '/range-functions/column',
  columns: '/range-functions/columns',
  combine: '/range-functions/combine',
  convert: '/range-functions/convert',
  count: '/range-functions/count',
  createdate: '/date-functions/createdate',
  createmember: '/text-functions/createmember',
  csvtorange: '/range-functions/csvtorange',
  cumulate: '/range-functions/cumulate',
  date: '/date-functions/date',
  datetotext: '/date-functions/datetotext',
  day: '/date-functions/day',
  deletefile: '/import-export-functions/deletefile',
  diff: '/range-functions/diff',
  download: '/import-export-functions/download',
  downloadfile: '/import-export-functions/downloadfile',
  empty: '/range-functions/empty',
  execute: '/database-functions/execute',
  excelcreate: '/import-export-functions/excelcreate',
  exp: '/mathematical-functions/exp',
  uploadfile: '/import-export-functions/uploadfile',
  filter: '/range-functions/filter',
  find: '/text-functions/find',
  getdata: '/database-functions/getdata',
  getdbcolumnnames: '/database-functions/getdbcolumnnames',
  getfilelist: '/import-export-functions/getfilelist',
  getselection: '/widget-functions/getselection',
  getstatement: '/database-functions/getstatement',
  gettimezoneoffset: '/date-functions/gettimezoneoffset',
  gettype: '/range-functions/gettype',
  geturlparam: '/navigation-functions/geturlparam',
  hash: '/user-management-functions/hash',
  hiddentext: '/text-functions/hiddentext',
  hour: '/date-functions/hour',
  htmltojsontext: '/text-functions/htmltojsontext',
  if: '/logical-functions/if',
  insertrange: '/range-functions/insertrange',
  intersection: '/range-functions/intersection',
  isempty: '/logical-functions/isempty',
  left: '/text-functions/left',
  len: '/text-functions/len',
  level: '/range-functions/level',
  ln: '/mathematical-functions/ln',
  loadwidgetcontent: '/widget-functions/loadwidgetcontent',
  log: '/mathematical-functions/log',
  lookup: '/range-functions/lookup',
  loop: '/logical-functions/loop',
  lower: '/text-functions/lower',
  max: '/mathematical-functions/max',
  message: '/navigation-functions/message',
  mid: '/text-functions/mid',
  millisecond: '/date-functions/millisecond',
  min: '/mathematical-functions/min',
  minute: '/date-functions/minute',
  mod: '/mathematical-functions/mod',
  month: '/date-functions/month',
  mround: '/mathematical-functions/mround',
  name: '/widget-functions/name',
  not: '/logical-functions/not',
  now: '/date-functions/now',
  number: '/mathematical-functions/number',
  open: '/navigation-functions/open',
  openwebpage: '/navigation-functions/openwebpage',
  or: '/logical-functions/or',
  page: '/widget-functions/page',
  pdfcreate: '/import-export-functions/pdfcreate',
  postmessage: '/import-export-functions/postmessage',
  pi: '/mathematical-functions/pi',
  question: '/navigation-functions/question',
  rand: '/mathematical-functions/rand',
  range: '/range-functions/range',
  rangetocsv: '/range-functions/rangetocsv',
  rangetohierarchy: '/range-functions/rangetohierarchy',
  removeuser: '/user-management-functions/removeuser',
  right: '/text-functions/right',
  round: '/mathematical-functions/round',
  row: '/range-functions/row',
  rows: '/range-functions/rows',
  second: '/date-functions/second',
  sendemail: '/import-export-functions/sendemail',
  sequence: '/range-functions/sequence',
  setselection: '/widget-functions/setselection',
  shareapp: '/user-management-functions/shareapp',
  sort: '/range-functions/sort',
  sqrt: '/mathematical-functions/sqrt',
  starttimer: '/widget-functions/starttimer',
  stoptimer: '/widget-functions/stoptimer',
  storewidgetcontent: '/widget-functions/storewidgetcontent',
  substitute: '/text-functions/substitute',
  sum: '/mathematical-functions/sum',
  text: '/text-functions/text',
  today: '/date-functions/today',
  transpose: '/range-functions/transpose',
  trim: '/text-functions/trim',
  unique: '/range-functions/unique',
  update: '/widget-functions/update',
  updateuser: '/user-management-functions/updateuser',
  upload: '/import-export-functions/upload',
  scancode: '/import-export-functions/scancode',
  upper: '/text-functions/upper',
  userlist: '/user-management-functions/userlist',
  username: '/user-management-functions/username',
  visiblehierarchy: '/range-functions/visiblehierarchy',
  weekday: '/date-functions/weekday',
  year: '/date-functions/year',
}
