import {memo, ReactNode} from 'react'

import {client} from 'apollo'
import {
  Maybe,
  PageByIdDocument,
  PageByIdQuery,
  usePopupPageByIdQuery,
  useWidgetsByPageIdQuery,
} from "generated/graphql-operations";
import {PAGE_SIZE} from 'static/constants'
import checkFirstOpening from 'utils/isFirstOpening'

import PagePopupContainer from './PagePopupContainer'
import {getPosition} from './helpers'
import {IPopupPageDetails} from './index'
import {Loader} from '../index'

export type pageGeometryType = {top: number; left: number; pageHeight: number; pageWidth: number}

interface IProps {
  popupPageDetails: IPopupPageDetails
  children?: ReactNode
  prevPageId: number
  index: number
}

const PagePopupQuery = ({popupPageDetails, children, prevPageId, index}: IProps) => {
  const {pageId, widgetGeometry, cellPosition} = popupPageDetails

  const {data, loading} = usePopupPageByIdQuery({variables: {id: pageId}})
  const {data: widgetData, loading: widgetLoading} = useWidgetsByPageIdQuery({
    variables: {pageId, isFirstOpening: checkFirstOpening()},
    fetchPolicy: 'network-only',
  })

  if (loading || !data || widgetLoading || !widgetData) return <Loader $isInside />
  const {styles} = data.getPageById

  const backgroundPage: Maybe<PageByIdQuery['getPageById']> = client.readQuery({
    query: PageByIdDocument,
    variables: { id: prevPageId },
  })?.getPageById

  const {hasBorder, borderSize, pageWidth, pageHeight} = backgroundPage?.styles || {}

  const pageSize = {
    width: Number(pageWidth) || PAGE_SIZE.maxWidth,
    height: Number(pageHeight) || PAGE_SIZE.maxHeight,
    border: hasBorder ? Number(borderSize || 0) : 0,
  }

  const popupSize = {
    height: Number(styles.pageHeight),
    width: Number(styles.pageWidth),
  }

  const pageGeometry = getPosition(pageSize, popupSize, widgetGeometry, cellPosition, index)

  return (
    <PagePopupContainer
      pageId={pageId}
      styles={styles}
      widgets={widgetData.getWidgetsByPageId.affectedWidgets}
      isModal={!!widgetGeometry}
      pageGeometry={pageGeometry}
    >
      {children}
    </PagePopupContainer>
  )
}

export default memo(PagePopupQuery)
