import {WidgetGeometry} from 'generated/graphql-operations'

const OFFSET_VALUE = 10;

type TConfig = {
  pageSize: {width: number; height: number};
  widgetsGeometry: WidgetGeometry[];
  currentGeometry: WidgetGeometry;
};

export const verifyCreatePosition = ({
  pageSize,
  widgetsGeometry,
  currentGeometry: {
    x: currX,
    y: currY,
    width: currWidth,
    height: currHeight,
    order: currOrder,
  },
}: TConfig): WidgetGeometry => {
  let widgetOrderBiggest = currOrder;
  for (const {order} of widgetsGeometry) {
    if (order > widgetOrderBiggest) widgetOrderBiggest = order;
  }

  const verifyWidgetGeo = (currentOffset: number): number => {
    const hasSamePos = widgetsGeometry.some((geometry) => {
      const {x, y, width, height} = geometry;
      return (
        currX + currentOffset === x &&
        currY + currentOffset === y &&
        currHeight === height &&
        currWidth === width
      );
    });
    return hasSamePos ? verifyWidgetGeo(currentOffset + OFFSET_VALUE) : currentOffset;
  };

  const positionOffset = verifyWidgetGeo(0);
  const xPos =
    pageSize.width > currX + currWidth + positionOffset
      ? currX + positionOffset
      : pageSize.width - currWidth;

  const yPos =
    pageSize.height > currY + currHeight + positionOffset
      ? currY + positionOffset
      : pageSize.height - currHeight;

  return {
    height: currHeight,
    width: currWidth,
    x: Math.trunc(Math.max(xPos, 0)),
    y: Math.trunc(Math.max(yPos, 0)),
    order: widgetOrderBiggest + 1,
  };
};
