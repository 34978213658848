
import {IList} from 'components'
import {WidgetGeometry} from 'generated/graphql-operations'
import {IWidgetGeometryIncludeKeyID, IWidgetGeometryWidthID} from '../../../apollo'

interface IProps {
  widgetsGeometry: IWidgetGeometryIncludeKeyID;
  selectedWidgets: number[];
  setGeometry: (arg: IWidgetGeometryWidthID[]) => void;
}

export const orderAction = ({widgetsGeometry, selectedWidgets, setGeometry}: IProps) => {
  const widgetOrderTop = (): number => {
    const orderArray = Object.values(widgetsGeometry).map(
      (geometry: WidgetGeometry) => geometry.order,
    );
    const orderSorted = orderArray.sort((a, b) => b - a);
    return orderSorted[0] || 1;
  };

  const widgetOrderBottom = () => {
    const orderArray = Object.values(widgetsGeometry).map(
      (geometry) => geometry.order,
    );
    const orderSorted = orderArray.sort((a, b) => b - a);

    return orderSorted[orderSorted.length - 1] || -1;
  };

  const getCoveredWidgets =
    (geometry: WidgetGeometry) =>
    (geo: WidgetGeometry): boolean =>
      geo.y + geo.height >= geometry.y &&
      geo.x + geo.width >= geometry.x &&
      geo.y <= geometry.y + geometry.height &&
      geo.x <= geometry.x + geometry.width;

  return [
    {
      name: 'Send to front',
      action: () => {
        const widgetGeometrySelected: IWidgetGeometryWidthID[] = selectedWidgets.map(
          (id: number) => ({
            ...widgetsGeometry[id],
            id,
          }),
        );
        const sortGeo = widgetGeometrySelected.sort((a, b) => a.order - b.order);
        const orderTop = widgetOrderTop();
        const newPositions = sortGeo.map((geo: IWidgetGeometryWidthID, index) => ({
          ...geo,
          order: orderTop + index + 1,
        }));

        setGeometry(newPositions);
      },
    },
    {
      name: 'Send to back',
      action: () => {
        const widgetGeometrySelected: IWidgetGeometryWidthID[] = selectedWidgets.map(
          (id: number) => ({
            ...widgetsGeometry[id],
            id,
          }),
        );
        const sortGeo = widgetGeometrySelected.sort((a, b) => b.order - a.order);
        const orderBottom = widgetOrderBottom();
        const newPositions: IWidgetGeometryWidthID[] = sortGeo.map(
          (geo: IWidgetGeometryWidthID, index: number) => ({
            ...geo,
            order: orderBottom - index - 1,
          }),
        );

        setGeometry(newPositions);
      },
    },
    {
      name: 'Send frontward',
      action: () => {
        const geoArr = Object.values(widgetsGeometry);
        const newPositions: IWidgetGeometryWidthID[] = selectedWidgets.map(
          (key: number) => {
            const geo = widgetsGeometry[key];
            const biggerOrder = geoArr
              .filter(getCoveredWidgets(geo))
              .map(({order}): number => order)
              .filter((order: number) => order >= geo.order)
              .sort((a: number, b: number) => a - b);

            return {
              id: key,
              ...geo,
              order: biggerOrder.length > 1 ? biggerOrder[1] + 1 : geo.order,
            };
          },
        );

        setGeometry(newPositions);
      },
    },
    {
      name: 'Send backward',
      action: () => {
        const geoArr = Object.values(widgetsGeometry);

        const newPositions: IWidgetGeometryWidthID[] = selectedWidgets.map(
          (key: number) => {
            const geo = widgetsGeometry[key];

            const lessOrder = geoArr
              .filter(getCoveredWidgets(geo))
              .map(({order}): number => order)
              .filter((order) => order <= geo.order)
              .sort((a: number, b: number) => b - a);

            return {
              id: key,
              ...geo,
              order: lessOrder.length > 1 ? lessOrder[1] - 1 : geo.order,
            };
          },
        );

        setGeometry(newPositions);
      },
    },
  ] as IList[];
};
