import breakpoints from './breakpoints'
import button from './button'
import colors from './colors'
import menu from './menu'
import shadow from './shadow'
import {typography, ITypography} from './typography'

interface IStyles {
  [key: string]: string | number
}

export interface IThemesMain {
  breakpoints: IStyles
  typography: ITypography
  colors: {[key in keyof typeof colors]: typeof colors[key]}
  button: {[key in keyof typeof button]: typeof button[key]}
  brdr: IStyles
  shadow: IStyles
  menu: IStyles
  // eslint-disable-next-line
  menuItem: any // todo: provide a strict type for this!
}

interface IThemes {
  main: IThemesMain

  // eslint-disable-next-line
  [key: string]: any // todo: provide a strict type for this!
}

// TODO: remove menu and create dotes list
const themes: IThemes = {
  main: {
    breakpoints,
    colors,
    typography,
    button,
    shadow,
    brdr: {
      small: '2px',
      main: '4px',
      big: '6px',
      round: '50%',
    },
    ...menu,
  },
}

export default themes
