import {createContext} from 'react'

import {TUndoRedoNames, TUndoRedoVariables} from 'hooks/undoRedo/useUndoRedo'
import {IWidgetGeometryWidthID, PageWidget} from 'apollo'

// Context for app title
export const AppTitlesContext = createContext<string[]>([])
//----------------------------------------------------

// Context for sending context to deep, without using a list of child
export const WidgetsContext = createContext<PageWidget[]>([])
//----------------------------------------------------

// Here is next widget and prev widget selection
export const WidgetSelectionAction = createContext<(hasShift: boolean) => void>(() => null)
//----------------------------------------------------

// Here is saving current status and actions of selection widgets
export interface IWidgetFrameContext {
  isSelecting: boolean
  selectingWidgetsId: number[]
}

export const WidgetsFrameContext = createContext({
  isSelecting: false,
  selectingWidgetsId: [],
} as IWidgetFrameContext)
//----------------------------------------------------

// Context for connecting to geometry inside all widget components.
export const WidgetGeometryContext = createContext<(geometry: IWidgetGeometryWidthID[]) => void>(
  () => null,
)
//----------------------------------------------------

// Data for toggleSelectStatus
export type WidgetEditStatusType = (status: boolean | ((st: boolean) => boolean)) => null | void

export const WidgetEditStatusContext = createContext<WidgetEditStatusType>(() => null)
//----------------------------------------------------

// Undo Redo through components
export interface IUndoRedoContext {
  clearHistory: () => void
  undo: () => void
  redo: () => void
  canUndo: boolean
  canRedo: boolean
}

export const UndoRedoContext = createContext({
  clearHistory: () => null,
  undo: () => null,
  redo: () => null,
  canUndo: false,
  canRedo: false,
} as IUndoRedoContext)

export const SaveUndoRedoContext = createContext<
  (type: TUndoRedoNames, variables: TUndoRedoVariables) => void
>(() => null)
//----------------------------------------------------

// Context for page navigation through pages using redirectToPage function with pageId
export const PageNavigationContext = createContext<(pageId: number) => void>(() => null)
//----------------------------------------------------
