import {useReactiveVar} from '@apollo/client'

import {
  AppsDocument,
  AppsQuery,
  Maybe,
  RequestDefaultAppTitleDocument,
  SharedApplication,
  useCopyAppsMutation,
  useCreateAppMutation,
  useDeleteAppMutation,
  useEditAppMutation,
  useExportAppMutation,
  useSetAppPositionMutation,
  useUploadAppImageMutation,
} from 'generated/graphql-operations'
import {cache, setAppIdVar, setShowAllAppsVar} from 'apollo'
import {downloadFromUrl} from 'utils/downloadFromUrl'
import {onError} from 'utils/serverErrorToast'

export const useApps = () => {
  const appId = useReactiveVar(setAppIdVar)
  const showAllApps = useReactiveVar(setShowAllAppsVar)

  const [createApp] = useCreateAppMutation({
    refetchQueries: [{query: AppsDocument}, {query: RequestDefaultAppTitleDocument}],
    onError,
  })

  const [copyApps] = useCopyAppsMutation({
    refetchQueries: [{query: AppsDocument}],
    onError,
  })

  const [editApp] = useEditAppMutation({
    refetchQueries: [{query: AppsDocument}, {query: RequestDefaultAppTitleDocument}],
    onError,
  })

  const [uploadAppImage] = useUploadAppImageMutation({
    refetchQueries: [{query: AppsDocument}],
    onError,
  })

  const [runDeleteApp] = useDeleteAppMutation({
    refetchQueries: [{query: RequestDefaultAppTitleDocument}],
    update(cacheRef, {data}) {
      if (typeof data?.deleteApp === 'number') {
        cacheRef.updateQuery(
          {
            query: AppsDocument,
            variables: {showAllApps},
          },
          (cachedValue) => ({
            getApps: cachedValue.getApps.filter(
              (sharedApp: SharedApplication) => sharedApp.application.id !== data.deleteApp,
            ),
          }),
        )
      }
    },
    onError,
  })

  const [exportApp] = useExportAppMutation({
    onCompleted(resp) {
      const apps: Maybe<AppsQuery> = cache.readQuery({
        query: AppsDocument,
        variables: {showAllApps},
      })
      const currentApp = apps?.getApps.find((app) => app.application.id === appId)

      return downloadFromUrl(
        `${process.env.REACT_APP_URL}/temp/${resp.exportApp}`,
        `nedyx_${currentApp?.application.title ?? ''}.zip`,
      )
    },
    onError,
  })

  const [setAppPosition] = useSetAppPositionMutation({
    refetchQueries: [{query: AppsDocument, variables: {showAllApps}}],
    onError,
  })

  return {
    copyApps,
    createApp,
    deleteApp: runDeleteApp,
    editApp,
    exportApp,
    setAppPosition,
    uploadAppImage,
  }
}
