import {ToastContainerProps, TypeOptions} from 'react-toastify'
import {ReactElement} from 'react'
import {Check, Info, WarningCircle, X} from '@phosphor-icons/react'

const TOASTS_MAX = 3

export enum ContainerId {
  ROOT = 'root-toast-container',
  DIALOG = 'dialog-toast-container',
}

const toastIconMapper: {[t in TypeOptions]: ReactElement} = {
  default: <Info size={18} weight='bold' />,
  error: <X size={12} weight='bold' />,
  info: <Info size={18} weight='bold' />,
  success: <Check size={12} weight='bold' />,
  warning: <WarningCircle size={12} weight='bold' />,
}

export const defaultToastProps: ToastContainerProps = {
  enableMultiContainer: true,
  draggable: false,
  icon: ({type}) => toastIconMapper[type],
  limit: TOASTS_MAX,
  position: 'top-center',
  theme: 'colored',
}
