import {isDevEnv, isTestEnv} from './env'

const MAX_WIDGET_NAME_LENGTH = 128
const MAX_PAGE_PAGE_LENGTH = 128
const MAX_APP_NAME_LENGTH = 128
const MIN_WORKSPACE_NAME_LENGTH = 4
const MIN_WIDGET_LETTERS_LENGTH = 3
const MIN_PASSWORD_LENGTH = 8

const NUMBERS = (value: number) => value >= 48 && value <= 57
const UPPERCASE = (value: number) => value >= 65 && value <= 90
const LOWERCASE = (value: number) => value >= 97 && value <= 122

export const validateAppName = (value: string): string => {
  if (value.length > MAX_APP_NAME_LENGTH) {
    return 'The maximum length of the name is 128 characters'
  }
  if (value.match(/[":*?<>\\|/]/)) {
    return 'The following characters are not allowed for app names: ":*?<>\\|/'
  }
  return ''
}

export const validatePageName = (value: string): string => {
  if (!value.length) {
    return 'The name must contain at least one character'
  }
  if (!value.match(/^[A-Za-z0-9_]*$/)) {
    return "Allowed characters for names are 'a-z, A-Z, 0-9, _'"
  }
  if (value.length > MAX_PAGE_PAGE_LENGTH) {
    return 'The maximum length of the name is 128 characters'
  }
  return ''
}

export const validateWidgetName = (value: string): string => {
  if (!value.trim().length) {
    return 'The name must contain at least one character'
  }
  if (!value.match(/^[A-Za-z0-9_]*$/)) {
    return "Allowed characters for names are 'a-z, A-Z, 0-9, _'"
  }
  if (value.length > MAX_WIDGET_NAME_LENGTH) {
    return 'The maximum length of the name is 128 characters'
  }
  if (value.replace(/[0-9_]/g, '').length < MIN_WIDGET_LETTERS_LENGTH) {
    return 'The name must contain at least 3 letters'
  }
  return ''
}

export const validateEmail = (value: string): string => {
  if (!value.trim()) {
    return 'Should not be empty'
  }
  if (
    !value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  ) {
    return 'Should be valid email address'
  }
  return ''
}

export const validateBlank = (value: string): string =>
  value.trim().length === 0 ? 'Should not be empty' : ''

export const validateNameLength = (value: string, minLength: number, fieldName: string): string =>
  value.trim().length < minLength
    ? fieldName + ' should be longer than or equal to 3 characters'
    : ''

export const validatePassword = (value: string): string => {
  if (!value.trim()) {
    return 'Should not be empty'
  }
  const charCodes = (value ?? '').split('').map((key) => key.charCodeAt(0))
  if (
    !(charCodes.some(NUMBERS) && charCodes.some(UPPERCASE) && charCodes.some(LOWERCASE)) ||
    value.trim().length < MIN_PASSWORD_LENGTH
  ) {
    return `Must contain at least ${MIN_PASSWORD_LENGTH} characters with uppercase, lowercase and numbers.`
  }
  return ''
}

export const validateWorkspaceName = (value: string): string => {
  if (!value.trim()) {
    return 'Should not be empty'
  }
  if (/\s/g.test(value)) {
    return 'Should not contain empty spaces.'
  }
  if (value.trim().length < MIN_WORKSPACE_NAME_LENGTH) {
    return `Must contain at least ${MIN_WORKSPACE_NAME_LENGTH} alphanumerical characters.`
  }
  const charCodes = (value ?? '').split('').map((key) => key.charCodeAt(0))
  const allAlphanumerical = charCodes.every(
    (code) => NUMBERS(code) || UPPERCASE(code) || LOWERCASE(code) || code === 32,
  )
  if (!allAlphanumerical) {
    return 'Must contain only alphanumerical characters.'
  }
  return ''
}

export const validateMatch = (compareWith: string) => (value: string) => {
  if (!value?.trim()) {
    return 'Should not be empty'
  }
  return compareWith === value ? '' : 'Passwords do not match.'
}

// Obviously, this will cover only the typical cases, but that should be enough
const getDomainWithoutSubdomain = (url: string) => {
  const COMPLEX_COUNT = 4
  const dotSplit = new URL(url).hostname.split('.')
  return dotSplit.slice(-(dotSplit.length === COMPLEX_COUNT ? COMPLEX_COUNT - 1 : 2)).join('.')
}

const imageRegex = new RegExp(/\.(gif|jpe?g|png|webp|bmp|svg)$/i)
export const isImage = (imgUrl: string, origin: string): boolean => {
  // Accept any image url in localhost, 'cuz image uploading does not work locally anyway...
  if (isDevEnv) {
    return imageRegex.test(imgUrl)
  }

  const domain = isTestEnv ? 'nedyx.io' : getDomainWithoutSubdomain(origin)
  return imgUrl.toLowerCase().includes(domain.toLowerCase()) && imageRegex.test(imgUrl)
}
