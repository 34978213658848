import {useContext} from 'react'
import {toast} from 'react-toastify'
import {useReactiveVar} from '@apollo/client'

import {setAppIdVar} from 'apollo'
import {PageNavigationContext} from 'repository/context'
import {omitProps} from 'utils'
import {useWidgets} from './useWidgets'

import {
  PagesDocument,
  useCopyPagesMutation,
  useCopyPagesToClipboardMutation,
  useCreatePageMutation,
  useExecutePageActionMutation,
  usePastePagesFromClipboardMutation,
  useRenamePageMutation,
  useSetPageAvailabilityInLiveModeMutation,
  useSetPageStylesMutation,
} from 'generated/graphql-operations'
import {ContainerId} from 'components/Toast/config'

export const usePages = () => {
  const appId = useReactiveVar(setAppIdVar)
  const redirectToPage = useContext(PageNavigationContext)

  const {affectedContentHandler} = useWidgets()

  const [createPage] = useCreatePageMutation({
    update(cacheRef, {data}) {
      data?.createPage &&
        cacheRef.updateQuery(
          {
            query: PagesDocument,
            variables: {appId},
          },
          (cachedValue) => ({
            pages: [
              ...cachedValue.pages,
              {...omitProps(data.createPage, ['styles', 'actions']), widgets: []},
            ],
          }),
        )
    },
    onCompleted: (data) => {
      if (data?.createPage) redirectToPage(data.createPage.id)
    },
    onError: () => null,
  })

  const [copyPages] = useCopyPagesMutation({
    refetchQueries: [{query: PagesDocument, variables: {appId}}],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data?.copyPages) redirectToPage(data.copyPages[0].id)
    },
    onError: () => null,
  })

  // if we want to copy and paste page using ctrl + v
  const [copyPagesToClipboard] = useCopyPagesToClipboardMutation({
    onError: () => null,
  })

  const [pastePagesFromClipboard] = usePastePagesFromClipboardMutation({
    refetchQueries: [{query: PagesDocument, variables: {appId}}],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data?.pastePagesFromClipboard) redirectToPage(data.pastePagesFromClipboard[0].id)
    },
    onError: () => null,
  })

  const [renamePage] = useRenamePageMutation({
    awaitRefetchQueries: true,
  })

  const [setPageStyles] = useSetPageStylesMutation({
    onError: () =>
      toast.error('This style change could not be applied', {
        containerId: ContainerId.ROOT,
        toastId: 'set-page-styles-error',
      }),
  })

  const [executePageAction] = useExecutePageActionMutation({
    onCompleted({executePageAction: res}) {
      affectedContentHandler(res.commandResults)
    },
    onError: () => null,
  })

  const [setPageAvailabilityInLiveMode] = useSetPageAvailabilityInLiveModeMutation({
    refetchQueries: [{query: PagesDocument, variables: {appId}}],
    awaitRefetchQueries: true,
  })

  return {
    createPage,
    renamePage,
    copyPages,
    copyPagesToClipboard,
    pastePagesFromClipboard,
    executePageAction,
    setPageAvailabilityInLiveMode,
    setPageStyles,
  }
}
