import {useReactiveVar} from '@apollo/client'
import {useEffect, useState} from 'react'

import {
  deselectAllWidgets,
  readFragment,
  setCellGlobalPosition,
  setPopupPageDetails,
  setWidgetsGeometryVarWithId,
  TCellPosition,
} from 'apollo'
import {IPopupPage} from 'static/constants'
import {useKeyCode} from 'hooks'
import {usePages} from 'repository/graphql'
import {
  Maybe,
  WidgetGeometry,
  WidgetFieldsFragment,
  WidgetFieldsFragmentDoc,
} from 'generated/graphql-operations'

import PagePopupQuery from './PagePopupQuery'

interface IProps {
  backgroundPageId: number
}

export interface IPopupPageDetails extends IPopupPage {
  cellPosition?: TCellPosition
  widgetGeometry?: WidgetGeometry
}

export default function PagePopup({backgroundPageId}: IProps) {
  const popupPageDetails = useReactiveVar(setPopupPageDetails)
  const cellGlobalPosition = useReactiveVar(setCellGlobalPosition)
  const widgetsGeometryWithId = useReactiveVar(setWidgetsGeometryVarWithId)
  const [popups, addPopup] = useState<IPopupPageDetails[]>([])
  const {executePageAction} = usePages()

  useKeyCode(['Escape'], () => {
    const lastPageId = popups[popups.length - 1]?.pageId
    lastPageId && setPopupPageDetails({status: 'close', pageId: lastPageId})
  })

  useEffect(() => {
    if (popupPageDetails && popupPageDetails.status !== 'inProcess') {
      const lastPage = popups[popups.length - 1]
      const {status, pageId, isCell, widgetId} = popupPageDetails

      const checkPopup = (): IPopupPage[] | void => {
        if (status === 'closeAll') {
          executePageAction({variables: {type: 'CLOSE', pageId}})
          return []
        }
        if (status === 'close' && pageId === lastPage?.pageId) {
          executePageAction({variables: {type: 'CLOSE', pageId}})
          return popups.slice(0, -1)
        }
        if (
          // prettier-ignore
          (status === 'openPopup' && popups.length === 0) ||
          (status === 'openPopup' && popups.length === 1 && !popups[0]?.widgetId && !!popupPageDetails.widgetId)
        ) {
          if (widgetId) {
            const widget: Maybe<WidgetFieldsFragment> = readFragment({
              id: `Widget:${widgetId}`,
              fragment: WidgetFieldsFragmentDoc,
              fragmentName: 'WidgetFields',
            })
            const widgetGeometry = widgetsGeometryWithId[widgetId] || widget?.geometry
            const cellPosition = isCell && cellGlobalPosition ? cellGlobalPosition : undefined

            return [...popups, {...popupPageDetails, widgetGeometry, cellPosition}]
          }

          return [...popups, popupPageDetails]
        }
      }

      const data = checkPopup()

      if (data) {
        addPopup(data)
        deselectAllWidgets()
        setPopupPageDetails(
          data.length > 0 ? {...data[data.length - 1], status: 'inProcess'} : null,
        )
        setCellGlobalPosition(null)
      }
    }
  }, [cellGlobalPosition, executePageAction, popupPageDetails, popups, widgetsGeometryWithId])

  return popups[0] ? (
    <PagePopupQuery popupPageDetails={popups[0]} index={0} prevPageId={backgroundPageId}>
      {popups[1] && (
        <PagePopupQuery popupPageDetails={popups[1]} index={1} prevPageId={popups[0].pageId} />
      )}
    </PagePopupQuery>
  ) : null
}
