import {ReactNode} from 'react'
import styled from 'styled-components'

type PropTypes = {
  children: ReactNode
  align?: 'left' | 'center' | 'right'
  className?: string
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'small' | 'em' | 'i' | 'strong' | 'b'
  variant?: 'h1' | 'h2' | 'h3' | 'subtitle' | 'body1' | 'body2'
}

export default function Typography({
  align,
  children,
  className,
  tag = 'p',
  variant = 'body1',
}: PropTypes) {
  return (
    <StyledElem as={tag} $align={align} className={className} $variant={variant}>
      {children}
    </StyledElem>
  )
}

const StyledElem = styled.div<{$align: PropTypes['align']; $variant: PropTypes['variant']}>`
  text-align: ${({$align}) => $align};

  ${({theme, $variant}) => {
    switch ($variant) {
      case 'h1':
        return `
          font-size: ${theme.typography.h1.fontSize};
          font-weight: ${theme.typography.h1.fontWeight};
          line-height: 1.1875;
          color: ${theme.colors.header};
        `
      case 'h2':
        return `
          font-size: ${theme.typography.h2.fontSize};
          font-weight: ${theme.typography.h2.fontWeight};
          line-height: 1.4;
          color: ${theme.colors.header};
        `
      case 'h3':
        return `
          font-size: ${theme.typography.h3.fontSize};
          font-weight: ${theme.typography.h3.fontWeight};
          line-height: 1.31;
          color: ${theme.colors.header};
        `
      case 'subtitle':
        return `
          font-size: ${theme.typography.subtitle.fontSize};
          font-weight: ${theme.typography.subtitle.fontWeight};
          line-height: 1.55;
          color: ${theme.colors.header};
        `
      case 'body1':
        return `
          font-size: ${theme.typography.body1.fontSize};
          font-weight: ${theme.typography.body1.fontWeight};
          line-height: 1.4;
          color: ${theme.colors.text};
          margin-bottom: 8px;
        `
      case 'body2':
        return `
          font-size: ${theme.typography.body1boldSmall.fontSize};
          font-weight: ${theme.typography.body1boldSmall.fontWeight};
          line-height: 1.5;
          color: ${theme.colors.text};
          margin-bottom: 7px;
        `
      default:
        return ''
    }
  }}
`
