import {Widget} from 'generated/graphql-operations'

export type WidgetStylesT = {[key: string]: Widget['styles'] | null}
export const getNonNullStyles = (styles: WidgetStylesT): WidgetStylesT => {
  const cleanedStyles = {} as WidgetStylesT
  for (const key in styles) {
    if (Object.prototype.hasOwnProperty.call(styles, key) && styles[key] !== null)
      cleanedStyles[key] = styles[key]
  }
  return cleanedStyles
}
