import styled from 'styled-components'

import {WidgetStyles} from 'generated/graphql-operations'

import {marchingAnts} from 'theme/animations'
import {ZOrder} from 'theme/constants'
import {minWidgetSizes} from 'static/constants'

export const WidgetBlockWrapper = styled.div<{
  $interactive: boolean
  $isActive: boolean
  $isActiveWidgetTitle: boolean
  $isEditMode: boolean
  $isSelected: boolean
  $order: number
  $styles: WidgetStyles
}>`
  min-width: ${minWidgetSizes.width}px;
  min-height: ${minWidgetSizes.height}px;
  position: absolute;
  z-index: ${({$order}) => $order};
  ${(p) => (p.$isSelected ? `outline: 2px solid ${p.theme.colors.primary}` : '')};
  opacity: ${(p) => (!p.$isActive && p.$isSelected ? 0.75 : 1)};
  user-select: none;
  cursor: ${({$interactive, $isSelected}) =>
    $isSelected ? 'move' : $interactive ? 'pointer' : 'default'};
  border-radius: ${(p) => `${p.$styles.borderRadius}px`};
  box-shadow: ${(p) => (p.$styles.hasShadow ? '0 2px 6px rgba(0, 0, 0, 0.12)' : 'none')};

  ${(p) => {
    if (p.$isActiveWidgetTitle) {
      return `
        &::before {
          content: "";
          position: absolute;
          top: -2px; right: -2px; bottom: -2px; left: -2px;
          border-radius: inherit;
          ${marchingAnts(20, 2, p.theme.colors.primary)};
          animation-duration: .42s;
          animation-timing-function: linear;
          animation-iteration-count: infinite;
        }
      `
    }
  }};

  &:hover {
    ${(p) => {
      if (p.$isActiveWidgetTitle) return 'box-shadow: none'
      if (p.$isSelected) return `box-shadow: 0 0 0 2pt ${p.theme.colors.primary}`
      if (p.$isEditMode) return 'box-shadow: 0 0 0 2pt lightblue'
    }};
  }

  .rnd-box--bottomLeft,
  .rnd-box--bottomRight,
  .rnd-box--topRight,
  .rnd-box--topLeft {
    position: absolute;
    width: 10px;
    height: 10px;
    border: 1px solid ${(p) => p.theme.colors.mainBackground};
    border-radius: ${(p) => p.theme.brdr.round};
    background-color: ${(p) => p.theme.colors.primary};
  }
  .rnd-box--bottomLeft {
    left: -6px;
    bottom: -6px;
    cursor: nesw-resize;
  }
  .rnd-box--bottomRight {
    right: -6px;
    bottom: -6px;
    cursor: nwse-resize;
  }
  .rnd-box--topRight {
    right: -6px;
    top: -6px;
    cursor: nesw-resize;
  }
  .rnd-box--topLeft {
    left: -6px;
    top: -6px;
    cursor: nwse-resize;
  }

  .rnd-box--left,
  .rnd-box--right {
    position: absolute;
    height: 100%;
    width: 10px;
    top: 0;
    cursor: ew-resize;
  }
  .rnd-box--top,
  .rnd-box--bottom {
    position: absolute;
    width: 100%;
    height: 10px;
    cursor: ns-resize;
  }

  .rnd-box--right {
    right: -5px;
  }
  .rnd-box--left {
    left: -5px;
  }
  .rnd-box--bottom {
    bottom: -5px;
  }
  .rnd-box--top {
    top: -5px;
  }
`

const sharedStyles = `
  position: absolute;
  z-index: ${ZOrder.RED_LINE};
`

export const DistributeHorizontally = styled.div`
  ${sharedStyles};
  border-bottom: 1px dashed red;
`

export const DistributeVertically = styled.div`
  ${sharedStyles};
  border-right: 1px dashed red;
`
