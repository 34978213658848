export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

export const capitalizeFirstLetterWithoutUnderscore = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase().replace(/_/, ' ')

export const formatEnum = (v: string) =>
  v
    .replace(/([a-z])(?=[A-Z])/gm, '$1_')
    .split('_')
    .map(capitalizeFirstLetter)
    .join(' ')

export const truncateText = (text: string, limit = 50): string =>
  text.length > limit ? `${text.slice(0, limit).trimEnd()}…` : text

export const pluralise = (word: string, quantity: number): string =>
  quantity === 1 ? word : `${word}s`

export const toCamelCase = (str: string) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ''
    return index === 0 ? match.toLowerCase() : match.toUpperCase()
  })
}

const CHARS = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

export const generatePassword = (length = 12): string => {
  let password = ''
  for (let i = 0, n = CHARS.length; i < length; i += 1) {
    password += CHARS.charAt(Math.floor(Math.random() * n))
  }
  return password
}

export const clearAllSpaces = (text: string): string => {
  return text.replace(/\s/g, '')
}

export const isHtml = (str: string) => {
  const htmlRegex = /<\/?[a-z][\s\S]*>/i
  return htmlRegex.test(str)
}
