import {
  ComponentProps,
  CSSProperties,
  memo,
  MouseEvent,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
} from 'react'
import styled from 'styled-components'
import {ToastContainer} from 'react-toastify'

import {ActivePageBlockType, setActivePageBlock} from 'apollo'
import breakpoints from 'theme/breakpoints'
import {ContainerId, defaultToastProps} from '../Toast/config'

import {CloseButton} from 'components/Button/CloseButton'
import ModalStockFooter from './StockFooter'
import Typography from '../Typography'

interface IProps {
  children: ReactNode
  onCancel: () => void
  activePageBlockName?: ActivePageBlockType
  disableCloseOutside?: boolean
  disableEscapeKeyDown?: boolean
  padding?: CSSProperties['padding']
  stockFooterProps?: ComponentProps<typeof ModalStockFooter>
  title?: string
  useStockFooter?: boolean
  width?: number
}

const Modal = ({
  activePageBlockName,
  children,
  disableCloseOutside,
  disableEscapeKeyDown,
  onCancel,
  padding,
  stockFooterProps = {},
  title,
  useStockFooter = true,
  width,
}: IProps) => {
  const coordsRef = useRef<{x: number; y: number} | null>(null)
  const dialogRef: RefObject<HTMLDialogElement> = useRef(null)

  useEffect(() => {
    const dialogElem = dialogRef.current
    if (dialogElem) {
      dialogElem.showModal()
      return () => dialogElem.close()
    }
  }, [])

  useEffect(() => {
    if (disableEscapeKeyDown) {
      const handler = (e: KeyboardEvent) => e.code === 'Escape' && e.preventDefault()
      window.addEventListener('keydown', handler)
      return () => window.removeEventListener('keydown', handler)
    }
  }, [disableEscapeKeyDown])

  // W/o this subscription, the existing modals won't reappear after being closed with Esc key.
  // In any closing scenario, we must call the `onCancel` callback.
  useEffect(() => {
    const dialogElem = dialogRef.current
    if (dialogElem) {
      dialogElem.addEventListener('close', onCancel)
      return () => dialogElem.removeEventListener('close', onCancel)
    }
  }, [onCancel])

  useEffect(() => {
    setActivePageBlock(activePageBlockName || 'modal')

    return () => {
      setActivePageBlock('main')
    }
  }, [activePageBlockName])

  // We'll record the mousedown coordinates. If they match with the mouseup coordinates,
  // that means user clicked on the same spot and did not drag the mouse.
  // E.g. if a modal contains an input field, you may drag the mouse copying its content,
  // but the modal will close if your cursor ends up beyond the content boundaries.
  // This is annoying, so we need to prevent it.
  const handleMouseDown = (e: MouseEvent<HTMLElement>) =>
    (coordsRef.current = {x: e.clientX, y: e.clientY})

  const handleOutsideClick = (e: MouseEvent<HTMLElement>) => {
    const dialogDimensions = dialogRef.current?.getBoundingClientRect()
    if (
      dialogDimensions &&
      e.clientX === coordsRef.current?.x &&
      e.clientY === coordsRef.current?.y &&
      (e.clientX < dialogDimensions.left ||
        e.clientX > dialogDimensions.right ||
        e.clientY < dialogDimensions.top ||
        e.clientY > dialogDimensions.bottom)
    ) {
      onCancel()
      coordsRef.current = null
    }
  }

  return (
    <Dialog
      ref={dialogRef}
      onMouseDown={disableCloseOutside ? undefined : handleMouseDown}
      onClick={disableCloseOutside ? undefined : handleOutsideClick}
      $p={padding}
      $w={width}
    >
      {title && (
        <ModalHeader>
          <Typography tag='h3' variant='h3'>{title}</Typography>
          <CloseButton type='button' onClick={onCancel} />
        </ModalHeader>
      )}
      {children}
      {useStockFooter && <ModalStockFooter {...stockFooterProps} />}

      {/* To still display toasts on top of all in the top layer, we need a separate container */}
      <ToastContainer {...defaultToastProps} containerId={ContainerId.DIALOG} />
    </Dialog>
  )
}

export default memo(Modal)

const DEFAULT_WIDTH = 450
const DEFAULT_PADDING = '30px 35px'

const Dialog = styled.dialog<{$p?: CSSProperties['padding']; $w?: number}>`
  box-sizing: border-box;
  width: clamp(310px, 75%, ${({$w = DEFAULT_WIDTH}) => $w + 'px'});
  min-width: 310px;
  padding: ${({$p = DEFAULT_PADDING}) => $p};
  border: 0;
  overflow-x: hidden;
  border-radius: ${(p) => p.theme.brdr.main};
  background-color: ${(p) => p.theme.colors.mainBackground};
  animation: fade-in 0.25s ease-out;

  @media (max-width: ${breakpoints.sm}px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(5px);
  }
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`
