import styled from 'styled-components'

export const Flex = styled.div<{$m?: string; $p?: string; $jc?: string}>`
  display: flex;
  justify-content: ${(p) => p.$jc || 'space-between'};
  align-items: center;
  margin: ${(p) => p.$m || '0 0 8px 0'};
  padding: ${(p) => p.$p || 0};
`

Flex.displayName = 'FlexContainer'
