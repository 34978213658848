import {useCallback, useMemo} from 'react'
import {debounce} from 'throttle-debounce'
import {toast} from 'react-toastify'
import {useReactiveVar} from '@apollo/client'

import {useWidgets} from 'repository/graphql'
import {
  cache,
  IWidgetGeometryIncludeKeyID,
  IWidgetGeometryWidthID,
  setSelectedWidgetsVar,
  setWidgetsGeometryVarWithId,
} from 'apollo'
import {WidgetFieldsFragmentDoc} from 'generated/graphql-operations'
import {ContainerId} from 'components/Toast/config'

const DELAY = 600

export const useReposition = () => {
  const {moveWidgets} = useWidgets()

  const widgetsGeometryWithId: IWidgetGeometryIncludeKeyID = useReactiveVar(
    setWidgetsGeometryVarWithId,
  )

  const debounceDataSave = useMemo(
    () =>
      debounce(DELAY, (widgetsGeometries: IWidgetGeometryWidthID[]) =>
        moveWidgets({
          variables: {widgetsGeometries},
          optimisticResponse: {
            moveWidgets: widgetsGeometries.map(({id, ...geometry}) => ({
              geometry: {...geometry, __typename: 'WidgetGeometry'},
              id,
              __typename: 'Widget',
            })),
          },
        }),
      ),
    [moveWidgets],
  )

  return useCallback(
    (widgetsGeometries: IWidgetGeometryWidthID[]): IWidgetGeometryWidthID[] | void => {
      try {
        const newWidgetsGeometryWithId: IWidgetGeometryIncludeKeyID = {}
        const oldGeo: IWidgetGeometryWidthID[] = []
        const selectedIds: number[] = []

        for (const {id, ...geometry} of widgetsGeometries) {
          oldGeo.push({...widgetsGeometryWithId[id], id})
          newWidgetsGeometryWithId[id] = geometry
          selectedIds.push(id)

          const hasData = cache.updateFragment(
            {
              id: `Widget:${id}`,
              fragment: WidgetFieldsFragmentDoc,
              fragmentName: 'WidgetFields',
            },
            (data) => ({...data, geometry: {...geometry, __typename: 'WidgetGeometry'}}),
          )

          if (!hasData) return debounceDataSave.cancel()
        }

        setWidgetsGeometryVarWithId({
          ...widgetsGeometryWithId,
          ...newWidgetsGeometryWithId,
        })

        setSelectedWidgetsVar(selectedIds)
        debounceDataSave(widgetsGeometries)

        return oldGeo
      } catch (e) {
        toast.warning('Lost undo-redo of code editor data', {containerId: ContainerId.ROOT})
      }
    },
    [debounceDataSave, widgetsGeometryWithId],
  )
}
