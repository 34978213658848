import {useCallback} from 'react'
import {toast} from 'react-toastify'

import {ContainerId} from 'components/Toast/config'
import {setSelectedStyleTabVar, setSelectedWidgetsVar, setWidgetScrollPositions} from 'apollo'
import {useSetScrollRefIdMutation, useSetWidgetsFormatsMutation, Widget} from 'generated/graphql-operations'

export type TFormat = {
  ids: Widget['id'][]
  newFormat: Widget['format']
  oldFormat: Widget['format']
}

export const useFormatRollback = () => {
  const [setWidgetsFormats] = useSetWidgetsFormatsMutation({
    onError: () => toast.error('This format change could not be applied', {
      containerId: ContainerId.ROOT,
      toastId: 'set-widgets-formats-error',
    }),
  })

  return useCallback(
    async ({ids, newFormat, oldFormat}: TFormat): Promise<TFormat | void> => {
      try {
        const {data} = await setWidgetsFormats({variables: {ids, newFormat: oldFormat}})
        if (!data?.setWidgetsFormats) return

        setSelectedWidgetsVar(ids)
        setSelectedStyleTabVar('Content')
        return {ids, oldFormat: newFormat, newFormat: oldFormat}
      } catch (e) {
        toast.warning('Lost undo-redo of content setting data', {containerId: ContainerId.ROOT})
      }
    },
    [setWidgetsFormats],
  )
}

export type TScrollBinding = {
  ids: Widget['id'][]
  newTargetId: Widget['scrollRefId']
  oldTargetId: Widget['scrollRefId']
}

export const useScrollBinding = () => {
  const [setScrollRefId] = useSetScrollRefIdMutation()

  return useCallback(
    async ({ids, newTargetId, oldTargetId}: TScrollBinding): Promise<TScrollBinding | void> => {
      try {
        const {data} = await setScrollRefId({
          variables: {sourceIds: ids, targetId: oldTargetId},
        })
        if (!data?.setScrollRefId) return

        setSelectedWidgetsVar(ids)
        oldTargetId &&
          setWidgetScrollPositions({
            [oldTargetId]: {top: 0, left: 0, scrolledByUser: true},
          })
        return {ids, oldTargetId: newTargetId, newTargetId: oldTargetId}
      } catch (e) {
        toast.warning('Lost undo-redo of scroll binding data', {containerId: ContainerId.ROOT})
      }
    },
    [setScrollRefId],
  )
}
