import {useCallback} from 'react'
import {toast} from 'react-toastify'
import {
  Widget,
  useConnectToDataSourceMutation,
  useDisconnectFromDataSourceMutation,
  useSetDataSourceOptionsMutation,
  DataSourceOptions,
  DataSourceOptionsDocument,
  DbObjectDetailsInputType,
} from 'generated/graphql-operations'
import {client, setSelectedStyleTabVar, setSelectedWidgetsVar} from 'apollo'
import {omitProps, stripTypenames} from 'utils'
import {ConnStatusT} from 'templates/Settings/Widget/Connection/model'
import {ContainerId} from 'components/Toast/config'

const handleError = (text: string) => toast.error(text, {containerId: ContainerId.ROOT})

export const ignoredPropsForUndo: Array<keyof DataSourceOptions> = [
  '__typename',
  'reference',
  'dbObjectOptions',
]

export type ConnectionActionType = 'Sending DB fields' | 'Entities Change'

type DataType = Omit<DataSourceOptions, '__typename' | 'reference' | 'dbObjectOptions'>

export type TConnectionData = {
  id: Widget['id']
  type: ConnectionActionType
  oldData: DataType
  newData: DataType
}

export const useConnectionRollback = () => {
  const [connectToDataSource] = useConnectToDataSourceMutation({
    onError: () => handleError('DB connection error. Please try again.'),
  })
  const [disconnectFromDataSource] = useDisconnectFromDataSourceMutation({
    onError: () => handleError('Could not disconnect from the DB. Please try again.'),
  })
  const [setDataSourceOptions] = useSetDataSourceOptionsMutation({
    onError: () => handleError('Error updating connection field(s)'),
  })

  return useCallback(
    async (value: TConnectionData): Promise<TConnectionData | void> => {
      try {
        const {id, type, oldData, newData} = value
        setSelectedWidgetsVar([id])
        setSelectedStyleTabVar('Connection')

        if (type === 'Sending DB fields') {
          const isDisconnected = ['DISCONNECTED', 'NOTSET'].includes(
            oldData.connectionStatus as ConnStatusT,
          )

          const dataSourceOptions = omitProps(oldData, ['connectionStatus', 'selectedDBEntities'])

          await setDataSourceOptions({variables: {id, dataSourceOptions}})
          await (isDisconnected ? disconnectFromDataSource : connectToDataSource)({variables: {id}})

          client.refetchQueries({include: [DataSourceOptionsDocument]})

          return {
            id,
            type,
            oldData: {...newData, connectionStatus: isDisconnected ? 'CONNECTED' : 'DISCONNECTED'},
            newData: {...oldData},
          }
        }

        if (type === 'Entities Change') {
          await setDataSourceOptions({
            variables: {
              id,
              dataSourceOptions: {
                selectedDBEntities: stripTypenames(
                  oldData.selectedDBEntities,
                ) as DbObjectDetailsInputType,
              },
            },
          })
          client.refetchQueries({include: [DataSourceOptionsDocument]})
          return {id, type, oldData: newData, newData: oldData}
        }
      } catch (e) {
        toast.warning('Lost undo-redo of widget connection', {containerId: ContainerId.ROOT})
      }
    },
    [disconnectFromDataSource, connectToDataSource, setDataSourceOptions],
  )
}
