import {toast} from 'react-toastify'
import {ContainerId} from 'components/Toast/config'

export const downloadFromUrl = async (url: string, filename: string): Promise<void> => {
  try {
    const res = await fetch(url)

    const blob = await res.blob()
    const newBlob = new Blob([blob])

    const blobUrl = window.URL.createObjectURL(newBlob)

    const link = document.createElement('a')
    link.href = blobUrl
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.parentNode?.removeChild(link)

    // clean up Url
    window.URL.revokeObjectURL(blobUrl)
  } catch {
    toast.error('File download error', {containerId: ContainerId.ROOT})
  }
}

export const downloadAction = async (fileName: string, tempFolderPathForUI: string): Promise<string> => {
  if (fileName && tempFolderPathForUI) {
    const url = `${process.env.REACT_APP_URL}/temp/${tempFolderPathForUI}/${fileName}`
    await downloadFromUrl(url, fileName)
  }
  return ''
}
