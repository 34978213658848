import {StrictMode} from 'react'
import ReactDOM from 'react-dom'
import {toast} from 'react-toastify'

import * as serviceWorker from './service-worker'

import App from './App'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('app-root'),
)

reportWebVitals()
serviceWorker.register(toast)
