import {ChangeEvent, InputHTMLAttributes, KeyboardEvent, MouseEvent, useEffect, useState} from 'react'
import styled from 'styled-components'

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  changeHandler?: (name: string, value: string | number) => void
  discardOnEscape?: boolean // preferably, handle this in `onKeyDown` (if parent component is also controlled)
}

export default function InputField({name, value, changeHandler, className, onKeyDown, discardOnEscape, ...rest}: IProps) {
  const [inputValue, setInputValue] = useState(value)
  useEffect(() => setInputValue(value), [value])

  const [hasOverflow, setHasOverflow] = useState(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    if (name && changeHandler) changeHandler(e.target.name, e.target.value)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation()
    discardOnEscape && event.code === 'Escape' && setInputValue(value)
    onKeyDown && onKeyDown(event)
  }

  // Beats me why this freaking input does not want to work with refs!
  // If you use the inputRef, scrollWidth will always be identical to clientWidth!
  // There's no clear answer to this on them internets.
  const handleHover = (elem: HTMLElement) => setHasOverflow(elem.scrollWidth > elem.clientWidth)

  return (
    <StyledInput
      className={['as-textfield', className].filter(Boolean).join(' ')}
      name={name}
      value={inputValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onMouseEnter={(e: MouseEvent) => handleHover(e.target as HTMLElement)}
      title={hasOverflow ? inputValue?.toString() : undefined}
      {...rest}
    />
  )
}

const StyledInput = styled.input`
  border: none;
  font-weight: bold;
  text-align: right;
  border-bottom: 1px solid ${(p) => p.theme.colors.light};

  &:read-only,
  &:disabled {
    opacity: 0.42;
  }

  &:read-only {
    cursor: default;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:hover:not(:disabled):not(:read-only),
  &:focus:not(:disabled):not(:read-only) {
    border-bottom-color: ${(p) => p.theme.colors.text};
  }
`
