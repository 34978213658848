import {
  ActionName,
  ACTIONS,
  ActionMap,
  DEFAULT_ACTIONS,
  WidgetName,
} from 'static/constants'
import {ActionType} from 'generated/graphql-operations'

export const getCurrentActionName = (
  actionType?: ActionName,
  widgetType: WidgetName = 'PAGE',
): ActionName => {
  const defaultProperty = DEFAULT_ACTIONS[widgetType] || DEFAULT_ACTIONS.DEFAULT
  if (!actionType) return defaultProperty
  return ACTIONS[widgetType].includes(actionType) ? actionType : defaultProperty
}

export const getCurrentActionType = (
  actionName?: ActionName,
  widgetType: WidgetName = 'PAGE',
): ActionType => ActionMap[getCurrentActionName(actionName, widgetType)]
