// Note: you'll need to provide animation-duration, timing function, etc. SEPARATELY!
export function marchingAnts(width: number, height: number, color: string): string {
  return `
    @keyframes marching-ants {
      0% { background-position: 0 0, 0 100%, 0 0, 100% 0; }
      100% { background-position: ${width}px 0, -${width}px 100%, 0 -${width}px, 100% ${width}px; }
    }
      background-image: linear-gradient(to right, ${color} 50%, #fff 50%), linear-gradient(to right, ${color} 50%, #fff 50%), linear-gradient(to bottom, ${color} 50%, #fff 50%), linear-gradient(to bottom, ${color} 50%, #fff 50%);
      background-size: ${width}px ${height}px, ${width}px ${height}px, ${height}px ${width}px, ${height}px ${width}px;
      background-position: 0 0, 0 100%, 0 0, 100% 0;
      background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
      animation-name: marching-ants;
  `
}
