import {
  ButtonHTMLAttributes,
  CSSProperties,
  ChangeEvent,
  ComponentProps,
  InputHTMLAttributes,
  KeyboardEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'
import styled from 'styled-components'

import { Caret, DropDownList, IList } from "components";
import {clamp, hasOverflow} from 'utils'

const MIN_INPUT_WIDTH = 40
const MAX_DROP_DOWN_HEIGHT = 200

// Extend when needed
type InputProps = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'autoComplete' | 'disabled' | 'id' | 'max' | 'maxLength' | 'min' | 'name' | 'type'
>

interface IProps {
  dataList: string[]
  decorations?: ReactNode[] // the order of icons must match that of dataList items
  displayAsTextField?: boolean // if input[type="number"] should have those spin buttons
  dropdownProps?: Omit<ComponentProps<typeof DropDownList>, 'children' | 'list' | 'selection'>
  inputProps?: InputProps
  isDebouncedCallback?: boolean // if `onChange` should fire when user's changing the input (make sure you really pass a debounced callback!)
  isLoading?: boolean
  maxWidth?: CSSProperties['maxWidth']
  minWidth?: CSSProperties['minWidth']
  maxHeight?: CSSProperties['maxHeight']
  onChange: (val: string, name?: string) => void
  onClickDropDownIcon?: (visible: boolean) => void
  onlySelect?: boolean
  textAlign?: CSSProperties['textAlign']
  value: string // todo: move `value` to `inputProps` for consistency
}

export default function Combobox({
  dataList,
  decorations,
  displayAsTextField,
  dropdownProps,
  inputProps,
  isDebouncedCallback,
  isLoading,
  maxWidth,
  minWidth = MIN_INPUT_WIDTH,
  maxHeight = MAX_DROP_DOWN_HEIGHT,
  onChange,
  onClickDropDownIcon,
  onlySelect,
  textAlign,
  value: initialValue,
}: IProps) {
  const [inputValue, setInputValue] = useState(initialValue)
  useEffect(() => setInputValue(initialValue), [initialValue])

  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target
    let newValue = value

    if (inputProps && inputProps.type === 'number' && inputProps.min && inputProps.max) {
      const numericValue = +value

      if (isNaN(numericValue)) newValue = ''
      else if (+inputProps.min < 0 && numericValue === 0) newValue = value
      else newValue = clamp(Math.floor(numericValue), +inputProps.min, +inputProps.max).toString()
    }

    setInputValue(newValue)
    isDebouncedCallback && onChange(newValue, name)
  }

  const handleBlur = () => {
    if (inputValue !== initialValue) {
      onChange(inputValue, inputProps?.name)
    }
  }

  const handleKeyDown = (
    onToggle: () => void,
    onClose: () => void,
    e: KeyboardEvent<HTMLInputElement>,
  ) => {
    if (onlySelect && e.code === 'Space') {
      e.preventDefault()
      onToggle()
    }

    if (e.code === 'Escape' || e.code === 'Tab') {
      onClose()
      !onlySelect && e.code === 'Escape' && setInputValue(initialValue)
    }

    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault()
      handleBlur()
    }
  }

  const {positionX, ...ddProps} = dropdownProps || {}

  return (
    <DropDownList
      isLoading={isLoading}
      listWidth='max-content'
      maxHeight={maxHeight}
      positionX={positionX || 'right: 0;'}
      selection={initialValue}
      list={dataList.map(
        (val, i): IList => ({
          action: () => {
            setInputValue(val)
            onChange(val, inputProps?.name)
          },
          name: val,
          ...(decorations?.[i] && {decoration: decorations[i]}),
        }),
      )}
      {...ddProps}
    >
      {(toggleView, visible, close) => (
        <>
          {decorations && decorations.length > 0 && (
            <DecorationWrapper>
              {decorations[dataList.indexOf(inputValue as never)] ||
                decorations[dataList.indexOf(+inputValue as never)]}
            </DecorationWrapper>
          )}
          <InputWrap $maxWidth={maxWidth} $minWidth={minWidth} className='combo-wrapper'>
            <WidthFiller aria-hidden='true'>{inputValue}</WidthFiller>
            <StyledInput
              className={clsx('combo-input', {
                'as-textfield': inputProps?.type === 'number' && displayAsTextField,
              })}
              $textAlign={textAlign}
              value={inputProps?.type === 'number' && isNaN(+inputValue) ? '' : inputValue}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown.bind(null, toggleView, close)}
              readOnly={onlySelect}
              ref={inputRef}
              $underline={!onlySelect}
              onClick={() => !inputProps?.disabled && onlySelect && toggleView()}
              title={hasOverflow(inputRef.current) ? inputValue : undefined}
              {...inputProps}
            />
          </InputWrap>
          <DropdownButton
            disabled={inputProps?.disabled}
            isOpen={visible}
            onClickCapture={() => {
              toggleView()
              onClickDropDownIcon && onClickDropDownIcon(!visible)
            }}
            type='button'
          />
        </>
      )}
    </DropDownList>
  )
}

type ButtonProps = {
  isOpen: boolean
  caretSize?: number
}

export const DropdownButton = ({
  caretSize = 13,
  isOpen,
  ...rest
}: ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps) => (
  <StyledButton {...rest}>
    <Caret isOpen={isOpen} size={caretSize} />
    <span className='visually-hidden'>{isOpen ? 'Close' : 'Open'} menu</span>
  </StyledButton>
)

const StyledInput = styled.input<{
  $textAlign?: string
  $underline?: boolean
}>`
  position: absolute;
  left: 0;
  width: 100%;
  font-weight: 700;
  line-height: 1;
  text-align: ${({$textAlign = 'right'}) => $textAlign};
  cursor: ${(p) => (p.readOnly ? 'pointer' : 'text')};
  border-bottom: ${({$underline, theme}) =>
    $underline ? `1px solid ${theme.colors.light}` : 'none'};

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    border-bottom-color: ${(p) => p.theme.colors.text};
  }

  &:disabled {
    opacity: 0.4;
  }
`

const InputWrap = styled.span<{$maxWidth: IProps['maxWidth']; $minWidth: IProps['minWidth']}>`
  position: relative;
  min-width: ${({$minWidth}) => (typeof $minWidth === 'number' ? `${$minWidth}px` : $minWidth)};
  max-width: ${({$maxWidth}) => (typeof $maxWidth === 'number' ? `${$maxWidth}px` : $maxWidth)};
  margin-right: 10px;
  white-space: nowrap;
`

// https://css-tricks.com/auto-growing-inputs-textareas/#aa-resizing-actual-input-elements
const WidthFiller = styled.span`
  visibility: hidden;
  padding-right: 5px;
  padding-left: 5px;
`

const StyledButton = styled.button`
  padding: 0 3px;
  width: 16px;
  height: 16px;
  background-color: ${(p) => p.theme.colors.outerBorder};
  border-radius: 4px;

  &:disabled {
    cursor: default;
    opacity: 0.42;
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: ${({theme}) => theme.colors.light};
  }

  svg {
    height: 12px;
    width: 10px;
    pointer-events: none;
  }
`

const DecorationWrapper = styled.span`
  vertical-align: middle;
  width: 22px;
  height: 22px;

  svg {
    width: inherit;
    height: inherit;
  }
`
