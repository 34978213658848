export let firstOpen = true

export default () => {
  if (firstOpen) {
    firstOpen = false
    return true
  }

  return false
}
