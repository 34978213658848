import {PureComponent} from 'react'
import {toast} from 'react-toastify'

import {truncateText} from 'utils'
import {ContainerId} from '../Toast/config'

const MESSAGE_MAX = 70

type PropTypes = {
  appendText?: string
  prependText?: string
}

// Use this component if you want to catch an error and just show a toast.
// Remember: this still won't catch errors in your event handlers!
// https://reactjs.org/docs/error-boundaries.html#how-about-event-handlers
export default class ErrorBoundaryWithToast extends PureComponent<PropTypes> {
  constructor(props: PropTypes) {
    super(props)
  }

  // Note: don't implement getDerivedStateFromError, otherwise the toast won't be shown,
  // and the error will propagate to the closest error boundary.

  componentDidCatch(error: Error) {
    const {appendText, prependText} = this.props
    toast.error(
      [
        prependText,
        truncateText(error.message || 'No error message provided', MESSAGE_MAX),
        appendText,
      ]
        .filter(Boolean)
        .join(' '),
      {
        toastId: 'error-boundary-toast',
        containerId: ContainerId.ROOT,
      },
    )
    // todo: here is another good spot to log the error to an error reporting service
  }

  render() {
    return this.props.children
  }
}
