import {CSSProperties} from 'react'
import {css} from 'styled-components'
import {toast} from 'react-toastify'

import colors from 'theme/colors'
import {ContainerId} from 'components/Toast/config'
import {
  CELL_PADDING,
  GOOGLE_FONTS,
  SANS_SERIF_FONTS,
  SERIF_FONTS,
  TBorderPosition,
  TFont,
  defaultBorderPositions,
} from './constants'
import {getDifference, isDateISOString, WIDGET_ERRORS} from 'utils'
import {ValueType} from 'apollo'
import {WidgetBgPatternType, WidgetStyles} from 'generated/graphql-operations'

const getFallbackFont = (font?: TFont): string => {
  if (!font) return 'system-ui, ui-sans-serif, sans-serif'
  return SERIF_FONTS.includes(font)
    ? `${SERIF_FONTS.filter((f) => f !== font).slice(0, 2).join(', ')}, serif`
    : `${SANS_SERIF_FONTS.filter((f) => f !== font).slice(0, 2).join(', ')}, sans-serif`
}

// This will only download small CSS files with `@font-face` declarations.
// The actual fonts will be fetched automatically when needed,
// i.e. when browser encounters a CSS rule `font-family: 'Your Font Name'`.
export const appendCustomFonts = () => {
  const existingLinks = [...document.head.querySelectorAll('link')].map((l) => l.href)
  const urlsToAppend = getDifference(GOOGLE_FONTS, existingLinks)
  if (urlsToAppend.length) {
    urlsToAppend.forEach((url) => {
      const node = document.createElement('link')
      node.rel = 'stylesheet'
      node.href = url
      document.head.appendChild(node)
    })
  }
}

export function widgetBSToCSS(wStyles?: WidgetStyles): CSSProperties {
  if (wStyles) {
    return {
      alignItems: wStyles.verticalAlignment || undefined,
      backgroundColor: wStyles.hasBackground ? wStyles.background || undefined : undefined,
      borderColor: wStyles.borderColor || undefined,
      borderRadius: wStyles.borderRadius && !isNaN(+wStyles.borderRadius) ? +wStyles.borderRadius : undefined,
      borderWidth: wStyles.borderSize && !isNaN(+wStyles.borderSize) ? +wStyles.borderSize : undefined,
      color: wStyles.fontColor || undefined,
      fontFamily: wStyles.fontFamily
        ? `${wStyles.fontFamily}, ${getFallbackFont(wStyles.fontFamily as TFont)}`
        : undefined,
      fontSize: wStyles.fontSize ? +wStyles.fontSize : undefined,
      fontStyle: wStyles.fontItalic ? 'italic' : undefined,
      fontWeight: wStyles.fontWeight ? 'bold' : undefined,
      justifyContent: wStyles.fontTextAlign || undefined,
      textAlign: wStyles.fontTextAlign as CSSProperties['textAlign'] || undefined,
      textDecoration: wStyles.fontUnderline ? 'underline' : undefined,
    }
  }
  return {}
}

export const setPadding = (styles: WidgetStyles): string => {
  const {fontTextAlign, verticalAlignment} = styles
  return `
    padding-right: ${fontTextAlign === 'right' ? CELL_PADDING : 0}px;
    padding-left: ${fontTextAlign === 'left' ? CELL_PADDING : 0}px;
    padding-top: ${verticalAlignment === 'baseline' ? CELL_PADDING : 0}px;
    padding-bottom: ${verticalAlignment === 'end' ? CELL_PADDING : 0}px;
  `
}

const loadingNames: {[key: string]: NodeJS.Timeout} = {}
const startLoading = () => document.body.classList.add('is-loading')
const stopLoading = () => document.body.classList.remove('is-loading')
export function setLoadingCursor(isLoading: boolean, curLoadingName: string): [NodeJS.Timeout, () => void] {
  if (isLoading && loadingNames[curLoadingName] === undefined) {
    loadingNames[curLoadingName] = setTimeout(startLoading, 1000)
  }

  if(!isLoading && loadingNames[curLoadingName]) {
    if (document.body.classList.contains('is-loading')) stopLoading()
    else clearTimeout(loadingNames[curLoadingName])

    delete loadingNames[curLoadingName]
  }

  return [loadingNames[curLoadingName], stopLoading]
}

export const isWordWrap = (wordWrap: WidgetStyles['wordWrap'], isTable = false): boolean =>
  typeof wordWrap === 'boolean' ? wordWrap : !isTable

export const setCSSBorders = (styles: WidgetStyles): string => {
  const {borderSize, borderColor, borderPosition, hasBorder} = styles
  if (hasBorder) {
    return (borderPosition || defaultBorderPositions)
      .map((pos) => `border-${pos}: ${borderSize}px solid ${borderColor};`)
      .join('\n')
  }
  return ''
}

type BorderValue = Record<TBorderPosition, number>

export const getBorderValue = (wStyles?: WidgetStyles): BorderValue => {
  if (wStyles && wStyles.hasBorder && wStyles.borderSize && !isNaN(+wStyles.borderSize)) {
    const positions = wStyles?.borderPosition || defaultBorderPositions
    return defaultBorderPositions.reduce(
      (acc, pos) => ({
        ...acc,
        [pos]: positions.includes(pos) ? Number(wStyles.borderSize) : 0,
      }),
      {},
    ) as BorderValue
  }

  return {top: 0, right: 0, bottom: 0, left: 0}
}

export const showStylesError = () =>
  toast.error('This style change could not be applied', {
    containerId: ContainerId.ROOT,
    toastId: 'widget-style-mutation-error',
  })

export const displayTooltipForValue = (value: ValueType, isImage: boolean): boolean => {
  if (isImage || value === '') return false
  if (
    typeof value === 'boolean' ||
    (typeof value === 'string' && ['true', 'false'].includes(value.toLowerCase()))
  )
    return false
  if (value !== null && (typeof value === 'number' || !Number.isNaN(+value))) return false
  if (isDateISOString(value)) return false
  return typeof value === 'string' && !WIDGET_ERRORS.includes(value as typeof WIDGET_ERRORS[number])
}

type TOpts = {
  lineColor?: string
  lineWidth?: number
  width?: number
  height?: number
}

const patternToDegree: {[key in WidgetBgPatternType]: number} = {
  HORIZONTAL: 90,
  VERTICAL: 0,
  DIAGONAL_FORWARD: 45,
  DIAGONAL_BACKWARD: 135,
}

// Inlined SVG is SO MUCH easier to deal with than CSS gradients!
// It's also consistent across browsers and does not make your eyes bleed out.
// https://codepen.io/code_monkey/pen/yLGvNrN
export const generateHatchedBackground = (
  pattern: WidgetBgPatternType,
  {lineColor = colors.text, lineWidth = 4, width = 4, height = 4}: TOpts = {},
) => {
  return css`
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25'%3E%3Cdefs%3E%3Cpattern id='diagonalHatch' width='${width}' height='${height}' patternTransform='rotate(${patternToDegree[pattern]})' patternUnits='userSpaceOnUse' stroke-width='${lineWidth}' %3E%3Cline x1='0' y1='0' x2='0' y2='10' stroke='${lineColor.replace('#', '%23')}' /%3E%3C/pattern%3E%3C/defs%3E%3Crect width='100%25' height='100%25' fill='url(%23diagonalHatch)' /%3E%3C/svg%3E");
  `
}
