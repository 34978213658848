window.addEventListener('beforeunload', function () {
  sessionStorage.tabsopened--
})

export const registerTabDuplicationEvent = (onTabDuplicate: () => void) => {
  if (sessionStorage.tabsopened == 'NaN' || sessionStorage.tabsopened == null || sessionStorage.tabsopened == 'undefined') {
    sessionStorage.setItem('tabsopened', '0')
  }
  sessionStorage.tabsopened++

  if (sessionStorage.tabsopened >= 2) {
    onTabDuplicate()
    sessionStorage.setItem('tabsopened', '1')
  }
}
