import {memo, MouseEvent as ReactMouseEvent, RefObject, useEffect, useRef, useState} from 'react'
import {debounce} from 'throttle-debounce'
import {useReactiveVar} from '@apollo/client'

import {
  PageWidget,
  TableWidgetState,
  setActiveWidgetTitleVar,
  setCodeEditorStatusVar,
  setSelectedWidgetsVar,
  setTableWidgetStateVar,
  setVisibleInEditMode,
  setOpenedMenuId,
} from 'apollo'
import {WidgetGeometryContext, WidgetEditStatusContext} from 'repository/context'
import {WidgetGeometry as SchemaWidgetGeometry, Widget} from 'generated/graphql-operations'

import {TDirection} from 'templates/Page/hooks/geometryFunctions'
import {setLoadingCursor} from 'templates/Settings/Widget/Styles/utils'
import {dummyClickableWidgets} from './config'
import SetWidgetType from './SetWidgetType'
import {WidgetBlockWrapper} from './activeWidgetStyles'
import WidgetActionsPanel from './WidgetActionsPanel'
import {handleTableReset, isTableElem} from './WidgetTypes/Table/tableHelpers'
import {hasAction, useWidgets} from 'repository/graphql'
import {IGeoActions} from '../Page/hooks/useGeometryFunctions'

type TArrowKey = 'ArrowRight' | 'ArrowLeft' | 'ArrowUp' | 'ArrowDown'

const argsMap: {[key in TArrowKey]: [number, number]} = {
  ArrowRight: [1, 0],
  ArrowLeft: [-1, 0],
  ArrowUp: [0, -1],
  ArrowDown: [0, 1],
}

const directions: TDirection[] = [
  'top',
  'right',
  'bottom',
  'left',
  'topRight',
  'bottomRight',
  'bottomLeft',
  'topLeft',
]

const runEditModeOnlyInEditMode: Widget['type'][] = ['CELL']
const runEditModeByDoubleClick: Widget['type'][] = ['CELL', 'IMAGE', 'TEXT']

const WAITING_NEXT_CLICK = 300

// Define if we show "cursor: pointer" on widget
const isInteractive = (
  {id, type, enabled}: Pick<Widget, 'id' | 'type' | 'enabled'>,
  isEditable: boolean,
  isEditMode: boolean,
): boolean => {
  if (isEditMode || type === 'TABLE') return false

  switch (type) {
    case 'CELL':
      return isEditable || hasAction('Widget', id, 'CLICK')
    case 'BUTTON':
    case 'CHART':
    case 'IMAGE':
      return enabled && hasAction('Widget', id, 'CLICK')
    case 'MENU':
      return enabled
    default:
      return false
  }
}

interface IProps extends IGeoActions {
  widget: PageWidget
  geometry: SchemaWidgetGeometry
  isEditMode: boolean
  isActive: boolean
  isSelected: boolean
  isStatic: boolean
  isMenuOpen: boolean
}

const WidgetGeometry = ({
  widget,
  geometry,
  isEditMode,
  isActive,
  isSelected,
  isStatic,
  isMenuOpen,
  setGeometry = () => null,
  setPositionKeyThrottle,
  changeGeometry,
}: IProps) => {
  const {enabled, type, title, styles, visible, editableEvaluated, id: widgetId} = widget
  const rndRef: RefObject<HTMLDivElement> = useRef(null)

  const selectedWidgets = useReactiveVar(setSelectedWidgetsVar)
  const activeWidgetTitle = useReactiveVar(setActiveWidgetTitleVar)
  const isVisibleInEditMode = useReactiveVar(setVisibleInEditMode)
  const codeEditorStatus = useReactiveVar(setCodeEditorStatusVar)
  const isCodeEditorEditMode = codeEditorStatus === 'active'
  const tableWidgetState = useReactiveVar(setTableWidgetStateVar)
  const isCellSelected = tableWidgetState === TableWidgetState.CELL_SELECTED

  const {executeAction} = useWidgets()

  const [isEditStatus, toggleEditStatus] = useState(false)

  // Even if widget is hidden, user should still be able to reveal it (only visually)
  // by referring to its title in the formula editor.
  const hideInEditMode = isCodeEditorEditMode
    ? title !== activeWidgetTitle?.title && !isVisibleInEditMode && !visible
    : !isVisibleInEditMode && !visible

  // there is repetition here, but it is easier to understand. when will merge to main i update it
  const hasAbilityMove = isSelected && isStatic && !isEditStatus && !isMenuOpen
  const hasAbilityResize = isActive && !isEditStatus && !isCellSelected && !isMenuOpen
  const hasActivePanel = isActive && isStatic && !isEditStatus && !isCellSelected && !isMenuOpen

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (hasAbilityResize && setPositionKeyThrottle && event.key in argsMap) {
        const pos = argsMap[event.key as keyof typeof argsMap]
        setPositionKeyThrottle(pos, event.type)
        event.preventDefault()
      }
    }
    document.addEventListener('keyup', handler)
    document.addEventListener('keydown', handler)
    return () => {
      document.removeEventListener('keyup', handler)
      document.removeEventListener('keydown', handler)
    }
  }, [hasAbilityResize, setPositionKeyThrottle])

  const onClickAction = hasAction('Widget', widgetId, 'CLICK')
    ? debounce(WAITING_NEXT_CLICK, (e: ReactMouseEvent) => {
        if (isStatic && e.detail === 1 && enabled && dummyClickableWidgets.includes(type)) {
          setLoadingCursor(true, 'executeAction')
          executeAction({
            variables: {
              type: 'CLICK',
              widgetId,
              previousActionColumn: 1,
              currentActionColumn: 1,
              previousActionRow: 1,
              currentActionRow: 1,
              isEditMode,
            },
          }).finally(()=> {
            setLoadingCursor(false, 'executeAction')
          })
        }
      })
    : () => null

  const runClick = (event: ReactMouseEvent) => {
    if (widget.type !== 'TEXT') event.stopPropagation() // TEXT's dropdowns won't close otherwise
    if (isEditStatus || !isStatic) return

    !isMenuOpen && enabled && setOpenedMenuId(null)

    if (isEditMode) {
      if (event.ctrlKey || event.metaKey || event.shiftKey) {
        if (isCodeEditorEditMode) {
          setActiveWidgetTitleVar({title, isSelect: true})
        } else if (isSelected) {
          setSelectedWidgetsVar(selectedWidgets.filter((id) => id !== widgetId))
        } else {
          setSelectedWidgetsVar([...selectedWidgets, widgetId])
        }
      } else {
        if (isActive) {
          isCodeEditorEditMode
            ? setCodeEditorStatusVar('save_execute')
            : onClickAction(event)
        } else {
          setCodeEditorStatusVar(isCodeEditorEditMode ? 'save' : 'init')
        }
        setSelectedWidgetsVar([widgetId])
      }
    } else {
      onClickAction(event)
      // prettier-ignore
      if (!isEditStatus && runEditModeOnlyInEditMode.includes(type) && enabled && editableEvaluated.value[0][0]){
        toggleEditStatus(true)
      }
    }
  }

  const runDoubleClick = () => {
    if (isEditMode && runEditModeByDoubleClick.includes(type)) {
      toggleEditStatus(true)
    }
  }

  const mouseDown = (e: ReactMouseEvent<HTMLElement>, resizerSide: TDirection | 'center') => {
    if (e.button === 0) {
      if (isCellSelected && !isTableElem(e)) {
        // If user's clicking on another widget while the focus is still in one of table's cell inputs,
        // we imperatively blur it to trigger the `onBlur` callback (as mousedown fires before blur).
        if (document.activeElement && document.activeElement instanceof HTMLInputElement) {
          document.activeElement.blur()
        }
        handleTableReset() // reset active table to facilitate selection of another widget
      }
      if (changeGeometry && hasAbilityMove) {
        e.stopPropagation()
        !isCellSelected && changeGeometry(rndRef.current, e, resizerSide, widgetId, geometry)
      }
    }
  }

  return (
    <WidgetEditStatusContext.Provider value={toggleEditStatus}>
      <WidgetGeometryContext.Provider value={setGeometry}>
        <WidgetBlockWrapper
          data-entity='widget'
          data-print-id={`widgetName${title}`}
          data-testid='active-widget-block'
          hidden={isEditMode ? hideInEditMode : !visible}
          onClick={runClick}
          onDoubleClick={runDoubleClick}
          onMouseDown={(e: ReactMouseEvent<HTMLElement>) => mouseDown(e, 'center')}
          ref={rndRef}
          style={{
            width: geometry.width,
            height: geometry.height,
            left: geometry.x,
            top: geometry.y,
          }}
          $interactive={isInteractive(
            {id: widgetId, type, enabled},
            !!editableEvaluated.value[0][0],
            isEditMode,
          )}
          $isActive={isActive}
          $isActiveWidgetTitle={
            isCodeEditorEditMode &&
            title === activeWidgetTitle?.title &&
            (type === 'TABLE' ? !activeWidgetTitle?.reference : true)
          }
          $isEditMode={isEditMode}
          $isSelected={isSelected && !isCellSelected}
          $order={geometry.order}
          $styles={styles}
        >
          <SetWidgetType isEditStatus={isEditStatus} widget={widget} isActive={isActive} />

          {hasAbilityResize &&
            directions.map((d) => (
              <div key={d} className={`rnd-box--${d}`} onMouseDown={(e) => mouseDown(e, d)} />
            ))}
        </WidgetBlockWrapper>

        {hasActivePanel && <WidgetActionsPanel geometry={geometry} widgetId={widgetId} />}
        {/* This div will contain a portal for a table cell toolbar, so don't remove it */}
        <div id={`cell-toolbar-w-${widgetId}`} />
      </WidgetGeometryContext.Provider>
    </WidgetEditStatusContext.Provider>
  )
}

export default memo(WidgetGeometry)
