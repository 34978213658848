import {useCallback} from 'react'
import {toast} from 'react-toastify'

import {
  useEnableWidgetsMutation,
  useSetWidgetsEditableMutation,
  useSetWidgetsVisibilityMutation,
  Widget,
} from 'generated/graphql-operations'
import {ContainerId} from 'components/Toast/config'

export type TStatus = {
  ids: Widget['id'][]
  action: 'enabled' | 'visible' | 'editable'
  isSelected: boolean
}

export const useStatusRollback = () => {
  const [enableWidgets] = useEnableWidgetsMutation()
  const [toggleWidgetsVisibility] = useSetWidgetsVisibilityMutation()
  const [toggleWidgetEditable] = useSetWidgetsEditableMutation()

  return useCallback(
    async ({ids, action, isSelected}: TStatus): Promise<TStatus | void> => {
      try {
        if (action === 'enabled') {
          await enableWidgets({variables: {ids, enabled: !isSelected}})
        }
        if (action === 'visible') {
          await toggleWidgetsVisibility({variables: {ids, visible: !isSelected}})
        }
        if (action === 'editable') {
          await toggleWidgetEditable({variables: {ids, editable: [[!isSelected]]}})
        }

        return {ids, action, isSelected: !isSelected}
      } catch (e) {
        toast.warning('Lost undo-redo of widget status', {containerId: ContainerId.ROOT})
      }
    },
    [enableWidgets, toggleWidgetsVisibility, toggleWidgetEditable],
  )
}
