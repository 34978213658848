import {toast} from 'react-toastify'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ignored = self.__WB_MANIFEST // can't create production build w/o this BS

const isLocalhost = Boolean(
  location.hostname === 'localhost' ||
  location.hostname === '[::1]' ||
  location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
)

const registerValidSW = async (swUrl: string, toaster: typeof toast) => {
  try {
    await navigator.serviceWorker.register(swUrl)
  } catch (error) {
    toaster.error('Error during service worker registration: ' + error, {
      toastId: 'service-worker-registration-error',
      hideProgressBar: true,
    })
  }
}

const checkValidServiceWorker = async (swUrl: string, toaster: typeof toast) => {
  try {
    const response = await fetch(swUrl)
    if (
      response.status === 404 ||
      response.headers.get('content-type')?.indexOf('javascript') === -1
    ) {
      const registration = await navigator.serviceWorker.ready
      await registration.unregister()
      location.reload()
    } else {
      await registerValidSW(swUrl, toaster)
    }
  } catch (error) {
    toaster('No internet connection found. App is running in offline mode.', {
      toastId: 'no-network-connection',
    })
  }
}

export function register(toaster: typeof toast) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, location.href)
    if (publicUrl.origin !== location.origin) return

    addEventListener('load', () => {
      const url = `${process.env.PUBLIC_URL}/service-worker.js`
      let isOnline = navigator.onLine

      addEventListener('online', () => {
        if (!isOnline) {
          toaster('🚀 You\'re back online!', {
            toastId: 'app-is-back-online',
            hideProgressBar: true,
            pauseOnFocusLoss: false,
          })
          isOnline = true
        }
      })

      addEventListener('offline', () => {
        toaster.warn('The app is running offline, some functionality may be unavailable', {
          toastId: 'app-is-offline',
          hideProgressBar: true,
          pauseOnFocusLoss: false,
        })
        isOnline = false
      })

      const funcToCall = isLocalhost ? checkValidServiceWorker : registerValidSW
      funcToCall(url, toaster)
    })
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister()
    })
  }
}
