export enum ZOrder {
  RED_LINE = 987654,
  WIDGET_PANEL,
  TEXT_WIDGET_TOOLBAR,
  SELECTED_FRAME,
  POPUP_PAGE_BACKGROUND,
  PAGE_NAVIGATION,
  PAGE_SETTINGS,
  PAGE_HEADER,
  POPUP_PAGE,
  MENU,
  TOOLTIP,
  TOAST,
}
