import {CSSProperties, ReactNode} from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import CopyRight from 'components/Copyright'

import pageBackground from 'static/images/svgs/pageBackground.svg'
import {ReactComponent as Logo} from 'static/images/svgs/nedyx.svg'

interface IProps {
  children: ReactNode
  styles?: CSSProperties
}

const LoginWrapper = ({children, styles}: IProps) => (
  <StandalonePageContainer>
    <MainContainer $width={styles?.width} $padding={styles?.padding}>
      <Logo />
      {children}
    </MainContainer>
    <CopyRight />
  </StandalonePageContainer>
)

export default LoginWrapper

const StandalonePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 50px;
  background: ${(p) => p.theme.colors.lightest} url(${pageBackground}) 50% 50% no-repeat fixed;
  background-size: cover;

  > p {
    margin-bottom: 0;
  }
`

const MainContainer = styled.main<{
  $width?: CSSProperties['width']
  $padding?: CSSProperties['padding']
}>`
  box-sizing: border-box;
  width: ${(p) => p.$width ?? '410px'};
  max-width: 90%;
  box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: ${(p) => p.theme.colors.mainBackground};
  padding: ${(p) => p.$padding ?? '40px 0 32px 0'};
  text-align: center;
  margin: auto;
  
  > svg {
    width: 136px;
    height: 30px;

    #logo-text path {
      fill: ${({ theme }) => theme.colors.darkMain};
    }
  }
`

export const BackLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  ${(p) => p.theme.typography.body1Small};
  color: ${(p) => p.theme.colors.primary};
  font-weight: 700;
  
  span {
    border-bottom: 1px solid transparent;
  }
  
  &:hover span {
    border-bottom-color: currentColor;
  }
`
