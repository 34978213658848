import {ApolloLink} from '@apollo/client'
import apolloLogger from 'apollo-link-logger'
import {createUploadLink} from 'apollo-upload-client'
import {setContext} from '@apollo/client/link/context'

import {getAccessToken} from 'repository/services'
import {getUrlParam, getWorkspace} from 'hooks'
import errorLink from './errors'

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

function changingHeaders() {
  const fullURL = location.href

  const token = getAccessToken()
  const workspaceName = getWorkspace()
  const currentAppId = getUrlParam(location.pathname, 'app')
  const currentPageId = getUrlParam(location.pathname, 'page')
  const mode =
    sessionStorage.getItem('mode') || new URLSearchParams(location.search).get('mode') || 'live'
  const tracing = sessionStorage.getItem('tracing') || ''
  let sessionId = sessionStorage.getItem('sessionId')

  if (!sessionId) {
    sessionId = Math.random().toString()
    sessionStorage.setItem('sessionId', sessionId)
  }
  //
  // const channel = sessionStorage.getItem('channel')
  // if (channel) sessionStorage.removeItem('channel')

  return {
    authorization: token ? `Bearer ${token}` : '',
    currentappid: currentAppId,
    currentpageid: currentPageId,
    sessionid: sessionId,
    mode,
    timezone: timeZone,
    tracing,
    fullURL,
    workspaceName,
  }
}

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  fetchOptions: {
    timeout: 600000, // 10 minutes in milliseconds
  },
})

const authLink = setContext((_, {headers}) => ({
  headers: {
    ...headers,
    ...changingHeaders(),
  },
  credentials: 'include',
}))

export default ApolloLink.from([authLink, errorLink, apolloLogger, uploadLink])
