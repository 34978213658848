import {memo, ReactNode, useMemo} from 'react'
import styled from 'styled-components'

import {PageWidget, setPopupPageDetails} from 'apollo'
import {PAGE_WRAPPER} from 'static/constants'
import {PageStyles, WidgetFieldsFragment} from 'generated/graphql-operations'
import {pageGeometryType} from './PagePopupQuery'
import {ZOrder} from 'theme/constants'

import WidgetView from 'templates/Widget/WidgetView'

interface IProps {
  widgets: WidgetFieldsFragment[]
  styles: PageStyles
  isModal: boolean
  pageId: number
  pageGeometry: pageGeometryType
  children?: ReactNode
}

const PagePopupContainer = ({styles, widgets, isModal, pageId, pageGeometry, children}: IProps) => {
  const close = () => isModal && setPopupPageDetails({status: 'close', pageId})

  const widgetsMemo = useMemo(
    () =>
      widgets.map((widget) => ({
        ...widget,
        paddingOfPopupPage: Number(styles.pageHeight),
      })),
    [styles.pageHeight, widgets],
  )

  return (
    <>
      <StyledPopupPageMask onClick={close} $isModal={isModal} />
      <PopupContainer $pageGeometry={pageGeometry} className={PAGE_WRAPPER}>
        <PopupPage $styles={styles}>
          {widgetsMemo.map((widget) => (
            <WidgetView key={widget.id} widget={widget as PageWidget} geometry={widget.geometry} />
          ))}
        </PopupPage>
        {children}
      </PopupContainer>
    </>
  )
}

export default memo(PagePopupContainer)

const StyledPopupPageMask = styled.div<{$isModal: boolean}>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: ${({$isModal}) => ($isModal ? 'transparent' : 'rgba(235, 233, 228, 0.3)')};
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: ${ZOrder.POPUP_PAGE_BACKGROUND};
`

const PopupContainer = styled.div<{$pageGeometry: pageGeometryType}>`
  ${({$pageGeometry}) => `
    max-width: ${$pageGeometry.pageWidth}px;
    max-height: ${$pageGeometry.pageHeight}px;
    left: ${$pageGeometry.left}px;
    top: ${$pageGeometry.top}px;
  `}
  overflow: auto;
  position: absolute;
  z-index: ${ZOrder.POPUP_PAGE};

  & {
    scrollbar-color: ${(p) => p.theme.colors.lightest};
  }

  &::-webkit-scrollbar {
    background-color: ${(p) => p.theme.colors.lightest};
  }
`

const PopupPage = styled.div<{$styles: PageStyles}>`
  width: ${(p) => p.$styles.pageWidth}px;
  height: ${(p) => p.$styles.pageHeight}px;
  margin: 1px;
  background-color: ${(p) => p.$styles.background};
  border: ${(p) =>
    p.$styles.hasBorder ? `${p.$styles.borderSize}px solid ${p.$styles.borderColor}` : 'none'};
`
