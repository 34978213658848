import {useRef} from 'react'
import {useDrop} from 'react-dnd'

import {PageWidget} from 'apollo'
import {useWidgets} from 'repository/graphql'
import {widgetSizes} from 'static/constants'
import {WidgetType} from 'generated/graphql-operations'

export const DND_WIDGET_TYPE = 'widget'
export type DndWidget = {
  ofType: WidgetType
}

export function useWidgetDrop(
  pageId: number,
  widgets: PageWidget[],
): {
  isActive: boolean
  dropRef: (elem: HTMLDivElement) => void
} {
  const ref = useRef<HTMLDivElement>()

  const {createWidget} = useWidgets()

  let widgetOrderBiggest = 0
  for (const widget of widgets) {
    const {order} = widget.geometry
    if (order > widgetOrderBiggest) widgetOrderBiggest = order
  }

  const [{canDrop, isOver}, drop] = useDrop({
    accept: DND_WIDGET_TYPE,
    drop(item: DndWidget, monitor) {
      const offset = monitor.getSourceClientOffset()
      if (offset && ref.current) {
        const type = item.ofType
        const sizes = widgetSizes[type.toLowerCase() as keyof typeof widgetSizes]

        const dropTargetXy = ref.current.getBoundingClientRect()
        const xLeft: number = Math.trunc(offset.x - dropTargetXy.left)
        const xRight: number = Math.trunc(xLeft + sizes.width)
        const x: number =
          dropTargetXy.width > xRight ? xLeft : xLeft - (xRight - dropTargetXy.width)

        const yTop: number = Math.trunc(offset.y - dropTargetXy.top)
        const yBottom: number = Math.trunc(yTop + sizes.height)
        const y: number =
          dropTargetXy.height > yBottom ? yTop : yTop - (yBottom - dropTargetXy.height)

        createWidget({
          variables: {
            pageId,
            type,
            geometry: {
              height: sizes.height,
              width: sizes.width,
              x: Math.trunc(xLeft > 0 && x >= 0 ? x : 0),
              y: Math.trunc(yTop > 0 && y >= 0 ? y : 0),
              order: widgetOrderBiggest + 1,
            },
          },
        })
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const isActive = canDrop && isOver

  return {
    dropRef: (elem: HTMLDivElement) => {
      ref.current = elem
      drop(ref)
    },
    isActive,
  }
}
