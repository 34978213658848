import {useState} from 'react'
import styled from 'styled-components'

export const useRipple = () => {
  const [ripple, setRipple] = useState<JSX.Element | null>(null)

  const onRippleClick = (event: MouseEvent) => {
    const currentTarget = event.currentTarget as Element
    const rect = currentTarget.getBoundingClientRect()

    const d = Math.max(currentTarget.clientWidth, currentTarget.clientHeight)
    const left = `${event.clientX - rect.left - d / 2}px`
    const top = `${event.clientY - rect.top - d / 2}px`
    const rippleElement = newRippleElement(d, left, top)

    setRipple(rippleElement)
  }

  const newRippleElement = (w: number, left: string, top: string) => (
    <Ripple
      style={{
        width: w,
        height: w,
        left,
        top,
      }}
      onAnimationEnd={() => setRipple(null)}
    />
  )

  return {
    ripple,
    onRippleClick,
  }
}

const Ripple = styled.div`
  position: absolute;
  z-index: 6;
  border-radius: ${(p) => p.theme.brdr.round};
  background-color: rgba(255, 255, 255, 0.2);
  transform: scale(0);
  animation: ripple 0.8s ease-in-out;

  @keyframes ripple {
    to {
      opacity: 0;
      transform: scale(10);
    }
  }
`
