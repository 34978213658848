import {makeVar} from '@apollo/client'
import {ActionName} from 'static/constants'

export type CodeEditorStatusType =
  | 'save'
  | 'execute'
  | 'save_execute'
  | 'save_deselect'
  | 'execute_button'
  | 'escape'
  | 'active'
  | 'init'

interface IActiveWidgetTitle {
  title: string
  isSelect?: boolean
  reference?: string // anything after the '!'
}

export const setCodeEditorStatusVar = makeVar<CodeEditorStatusType>('init')
export const setCodeEditorOpenVar = makeVar(false)
export const setActiveWidgetTitleVar = makeVar<IActiveWidgetTitle | null>(null)
export const setActiveActionTypeVar = makeVar<ActionName | undefined>(undefined)
