import {memo, RefObject, SyntheticEvent, useRef, useState} from 'react'
import clsx from 'clsx'
import styled from 'styled-components'
import {useReactiveVar} from '@apollo/client'

import {useKeyCode, useOutsideClick} from 'hooks'
import {setActivePageSizeVar} from 'apollo'
import {ZOrder} from 'theme/constants'
import {WidgetGeometry} from 'generated/graphql-operations'

import {useDelete} from './hooks/useDelete'
import {useCopyPaste} from './hooks/useCopyPaste'

import ActionsList from './ActionsList'
import {ReactComponent as DotsIcon} from 'static/images/svgs/dots-vertical.svg'

interface IProps {
  geometry: WidgetGeometry
  widgetId: number
}

const PANEL_MARGIN = 40
const PANEL_WIDTH = 34
const PADDING = 4

const WidgetActionsPanel = ({geometry, widgetId}: IProps) => {
  const [visible, toggleVisible] = useState(false)
  const actionRef: RefObject<HTMLDivElement> = useRef(null)

  const close = () => toggleVisible(false)
  const toggleView = () => toggleVisible((st) => !st)

  useOutsideClick(actionRef, close, visible)

  const {height, width} = useReactiveVar(setActivePageSizeVar)
  const topPosition = geometry.y + geometry.height + PADDING
  const isTopPosition = topPosition + PANEL_MARGIN < height || geometry.y < PANEL_MARGIN
  const leftPosition = geometry.x + geometry.width - PANEL_WIDTH

  const handleDeleteWidget = useDelete()
  const runCopyWidgets = useCopyPaste(widgetId, {height, width})
  useKeyCode(['Delete'], () => handleDeleteWidget())

  return (
    <WidgetPanel
      data-testid='widget-actions-panel'
      $elemWidth={PANEL_WIDTH}
      ref={actionRef}
      $top={isTopPosition ? topPosition : geometry.y - 37}
      $left={Math.max(leftPosition, 5)}
      onClick={(e: SyntheticEvent) => e.stopPropagation()}
      onMouseDown={(e: SyntheticEvent) => e.stopPropagation()}
    >
      <StyledButton type='button' onClick={toggleView} className={clsx({active: visible})}>
        <DotsIcon aria-hidden='true' />
        <span className='visually-hidden'>Widget actions</span>
      </StyledButton>

      {visible && (
        <ActionsList
          close={close}
          pageSize={{height, width}}
          onDeleteWidget={() => handleDeleteWidget()}
          onCopyWidget={runCopyWidgets}
        />
      )}
    </WidgetPanel>
  )
}

export default memo(WidgetActionsPanel)

const WidgetPanel = styled.div<{$elemWidth: number; $top: number; $left: number}>`
  position: absolute;
  display: flex;
  width: ${(p) => p.$elemWidth}px;
  top: ${(p) => p.$top}px;
  left: ${(p) => p.$left}px;
  z-index: ${ZOrder.WIDGET_PANEL};

  color: ${(p) => p.theme.colors.mainBackground};
  background-color: ${(p) => p.theme.colors.secondary};
  box-shadow: ${(p) => p.theme.shadow.small};
  border-radius: ${(p) => p.theme.brdr.main};

  cursor: pointer;
`

const StyledButton = styled.button`
  display: flex;
  width: 34px;
  height: 32px;
  justify-content: center;
  align-items: center;
  color: ${({theme}) => theme.colors.mainBackground};

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover,
  &.active {
    background-color: rgba(255, 255, 255, 0.16);

    svg {
      fill: ${(p) => p.theme.colors.primary};
    }
  }
`
