import {lazy, memo, MouseEvent, KeyboardEvent as ReactKeyboardEvent, Suspense} from 'react'
import styled from 'styled-components'

import {PageWidget} from 'apollo'
import {generateHatchedBackground, setCSSBorders} from '../Settings/Widget/Styles/utils'
import {WidgetStyles, WidgetType} from 'generated/graphql-operations'

import ErrorBoundaryWithToast from 'components/ErrorBoundary/ErrorBoundaryWithToast'
import {Skeleton, Tooltip} from 'components'
import {formatValue} from './WidgetTypes/DataFormats'

const ButtonWidget = lazy(() => import('./WidgetTypes/Button'))
const CellWidget = lazy(() => import('./WidgetTypes/Cell'))
const ChartWidget = lazy(() => import('./WidgetTypes/Chart'))
const DefaultWidget = lazy(() => import('./WidgetTypes/Default'))
const ImageWidget = lazy(() => import('./WidgetTypes/Image'))
const MenuWidget = lazy(() => import('./WidgetTypes/Menu'))
const TableWidget = lazy(() => import('./WidgetTypes/Table'))
const TextWidget = lazy(() => import('./WidgetTypes/Text'))

const setWidgetType = (
  widget: PageWidget,
  isEditStatus: boolean,
  isActive: boolean,
): JSX.Element => {
  switch (widget.type) {
    case 'BUTTON':
      return <ButtonWidget widget={widget} isEditStatus={isEditStatus} />
    case 'CELL':
      return <CellWidget widget={widget} isEditStatus={isEditStatus} isActive={isActive} />
    case 'IMAGE':
      return <ImageWidget widget={widget} isEditStatus={isEditStatus} />
    case 'TABLE':
      return <TableWidget widget={widget} />
    case 'TEXT':
      return <TextWidget widget={widget} isEditStatus={isEditStatus} isActive={isActive} />
    case 'CHART':
      return <ChartWidget widget={widget} />
    case 'MENU':
      return <MenuWidget widget={widget} />

    default:
      return <DefaultWidget styles={widget.styles} type={widget.type} />
  }
}

interface IProps {
  isEditStatus: boolean
  isActive: boolean
  widget: PageWidget
}

const SetWidgetType = ({isEditStatus, isActive, widget}: IProps) => {
  const {type, title, styles, format} = widget

  const mouseDownHandler = (e: MouseEvent<HTMLElement>) => {
    if (isEditStatus && type !== 'TABLE') e.stopPropagation()
  }

  const keyDownHandler = (e: ReactKeyboardEvent<HTMLInputElement>) => {
    if (isEditStatus) e.stopPropagation()
  }

  const tooltip = widget.tooltips?.value
  const showTooltip = tooltip && ['INPUT', 'IMAGE', 'BUTTON', 'CELL', 'TEXT'].includes(type)

  return (
    <ErrorBoundaryWithToast prependText={`Unexpected error in ${type.toLowerCase()} "${title}":`}>
      <Container
        onKeyDown={keyDownHandler}
        onMouseDown={mouseDownHandler}
        $styles={styles}
        $widgetType={type}
      >
        <Suspense fallback={<Skeleton $w='100%' $h='100%' />}>
          {showTooltip ? (
            <Tooltip
              content={formatValue(tooltip[0][0], format)}
              className='parentHeight'
              placement='widget'
            >
              {setWidgetType(widget, isEditStatus, isActive)}
            </Tooltip>
          ) : (
            setWidgetType(widget, isEditStatus, isActive)
          )}
        </Suspense>
      </Container>
    </ErrorBoundaryWithToast>
  )
}

const setBgColor = (styles: WidgetStyles, type: WidgetType): string | undefined => {
  if (type === 'CELL' && styles.hasBgPattern) return '#ffffff'
  return styles.hasBackground && styles.background ? styles.background : undefined
}

const Container = styled.div<{$styles: WidgetStyles; $widgetType: WidgetType}>`
  height: 100%;
  ${({$styles}) => setCSSBorders($styles)};
  overflow: ${({$widgetType}) => ($widgetType === 'TABLE' ? undefined : 'hidden')};
  border-radius: ${({$styles}) =>
    Number($styles.borderRadius) ? `${$styles.borderRadius}px` : undefined};
  background-color: ${({$styles, $widgetType}) => setBgColor($styles, $widgetType)};

  ${({$styles, $widgetType}) =>
    $widgetType === 'CELL' &&
    $styles.hasBackground &&
    $styles.background &&
    $styles.hasBgPattern &&
    $styles.bgPattern
      ? generateHatchedBackground($styles.bgPattern, {lineColor: $styles.background})
      : undefined};
`

export default memo(SetWidgetType)
