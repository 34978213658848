import {ActionType, WidgetType} from 'generated/graphql-operations'

export type ActionName =
  | 'Cell styles'
  | 'Column widths'
  | 'Chart labels'
  | 'Connection'
  | 'Content'
  | 'Editable'
  | 'MaxY'
  | 'MinY'
  | 'On click'
  | 'On close'
  | 'On input'
  | 'On open'
  | 'On switch'
  | 'Style'
  | 'Row heights'
  | 'Tooltips'
  | 'Visible';

export type WidgetName = WidgetType | 'PAGE'

export const IS_NOT_EXECUTE_ACTIONS: ActionType[] = [
  'CELL_STYLES',
  'COLUMN_WIDTHS',
  'CHART_LABELS',
  'CONNECTION',
  'CONTENT',
  'EDITABLE',
  'ROW_HEIGHTS',
  'STYLE',
  'TOOLTIPS',
  'VISIBLE',
]

export const ActionMap: Record<ActionName, ActionType> = {
  'Cell styles': 'CELL_STYLES',
  'Chart labels': 'CHART_LABELS',
  'On open': 'OPEN',
  'On close': 'CLOSE',
  'On click': 'CLICK',
  'On input': 'INPUT',
  'On switch': 'SWITCH',
  'Column widths': 'COLUMN_WIDTHS',
  Content: 'CONTENT',
  Connection: 'CONNECTION',
  Editable: 'EDITABLE',
  MaxY: 'MAX_Y',
  MinY: 'MIN_Y',
  'Row heights': 'ROW_HEIGHTS',
  Style: 'STYLE',
  Tooltips: 'TOOLTIPS',
  Visible: 'VISIBLE',
}

export const DEFAULT_ACTIONS = {
  BUTTON: 'On click',
  IMAGE: 'On click',
  PAGE: 'On open',
  DEFAULT: 'Content',
} as {[key in WidgetName | 'DEFAULT']: ActionName}

export const ACTIONS: {
  [key in WidgetName]: ActionName[]
} = {
  TEXT: ['On input', 'Content', 'Editable', 'Tooltips', 'Visible'],
  CELL: ['On click', 'On input', 'Content', 'Editable', 'Style', 'Tooltips', 'Visible'],
  TABLE: [
    'On click',
    'On input',
    'Content',
    'Cell styles',
    'Column widths',
    'Connection',
    'Editable',
    'Row heights',
    'Style',
    'Tooltips',
    'Visible',
  ],
  MENU: ['On switch', 'Content', 'Connection', 'Style', 'Tooltips', 'Visible'],
  BUTTON: ['On click', 'Content', 'Connection', 'Style', 'Tooltips', 'Visible'],
  IMAGE: ['On click', 'Content', 'Style', 'Tooltips', 'Visible'],
  CHART: [
    'On click',
    'Content',
    'Cell styles',
    'Chart labels',
    'Connection',
    'MaxY',
    'MinY',
    'Style',
    'Tooltips',
    'Visible',
  ],
  PAGE: ['On open', 'On close', 'Visible'],
}
