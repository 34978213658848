export const getCookieValue = (name: string) => {
  try {
    const cookie = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
    // j - express-session middleware for Express.js uses this convention when storing session data. that is why use slice
    if (cookie[0] === 'j') {
      const decodeString = decodeURIComponent(cookie).slice(2)
      return JSON.parse(decodeString)
    }
    return decodeURIComponent(cookie)
  } catch (e) {
    return false
  }
}

export const deleteCookie = (name: string) =>
  (document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;')
