import {MouseEvent, useCallback, useContext, useEffect} from 'react'
import {toast} from 'react-toastify'
import {usePages, useWidgets} from 'repository/graphql'
import {useReactiveVar} from '@apollo/client'

import {
  cache,
  readFragment,
  setActivePageSizeVar,
  setEditModeVar,
  setSelectedWidgetsVar,
  setWidgetsGeometryVarWithId,
  IWidgetGeometryIncludeKeyID, setActivePageBlock,
} from 'apollo'
import {stripTypenames, truncateText, verifyCopyPositions, getNonNullStyles, isFirefox} from 'utils'
import {
  Maybe,
  Widget,
  WidgetFieldsFragment,
  WidgetFieldsFragmentDoc,
} from 'generated/graphql-operations'
import {SaveUndoRedoContext} from 'repository/context'
import {TCopyPasteData} from 'hooks/undoRedo/useStyle'
import {IPageSize} from 'static/constants'
import {ContainerId} from 'components/Toast/config'

type PasteWidgetType = {
  data: string
  pageSize: IPageSize
  geometry: IWidgetGeometryIncludeKeyID
  pasteFromClipboard: (obj: {variables: {content: string}}) => void
}

const isCtrlPressed = (e: KeyboardEvent | MouseEvent) => e.ctrlKey || e.metaKey

const runPasteWidgets = async ({data, geometry, pasteFromClipboard, pageSize}: PasteWidgetType) => {
  try {
    const {widgetList, pageId} = JSON.parse(data)
    const updatedWidgets = verifyCopyPositions({
      pageSize,
      widgetsGeometry: Object.values(geometry),
      copyWidgets: widgetList,
    })

    const content = JSON.stringify({widgetList: updatedWidgets, pageId})
    await pasteFromClipboard({variables: {content}})
  } catch (e) {
    toast.error(
      `Widget paste failure: ${truncateText((e as Error)?.message || 'no details provided', 50)}`,
      {
        containerId: ContainerId.ROOT,
        toastId: 'widget-paste-failure',
      },
    )
  }
}

export const usePasteWidgets = () => {
  const pageSize: IPageSize = useReactiveVar(setActivePageSizeVar)
  const geometry = useReactiveVar(setWidgetsGeometryVarWithId)

  const {pasteFromClipboard} = useWidgets()

  return useCallback(
    async (widgetData: string) => {
      if (widgetData.indexOf('copyWidgetsData') === 0) {
        // Paste widgets- Ctrl-v
        await runPasteWidgets({
          data: widgetData.replace('copyWidgetsData', ''),
          geometry,
          pasteFromClipboard,
          pageSize,
        })
      }
    },
    [geometry, pasteFromClipboard, pageSize],
  )
}

export const useCopyPaste = (widgetId: number, pageSize: IPageSize) => {
  const isEditMode = useReactiveVar(setEditModeVar)
  const selectedWidgets = useReactiveVar(setSelectedWidgetsVar)
  const geometry = useReactiveVar(setWidgetsGeometryVarWithId)
  const activeBlock = useReactiveVar(setActivePageBlock)

  const WidgetFields: Maybe<WidgetFieldsFragment> = readFragment({
    id: `Widget:${widgetId}`,
    fragment: WidgetFieldsFragmentDoc,
    fragmentName: 'WidgetFields',
  })
  const {format, styles} = WidgetFields || {}

  const {pasteFromClipboard, copyToClipboard, copyWidgetsStyles} = useWidgets()
  const saveHistory = useContext(SaveUndoRedoContext)

  const runCopyWidget = useCallback(async () => {
    const {data} = await copyToClipboard({variables: {widgetIds: selectedWidgets}})
    const widgetData = data?.copyToClipboard

    if (widgetData)
      await runPasteWidgets({data: widgetData, geometry, pasteFromClipboard, pageSize})
  }, [selectedWidgets, geometry, pageSize, pasteFromClipboard, copyToClipboard])

  useEffect(() => {
    const handleCopyWidgets = async (e: KeyboardEvent) => {
      // Copy widgets - Ctrl-c
      if (e.code === 'KeyC' && !e.altKey && isCtrlPressed(e)) {
        const selection = document.getSelection()
        const selectedText = selection?.toString()

        if (selectedText) return

        const {data} = await copyToClipboard({variables: {widgetIds: selectedWidgets}})
        const widgetData = data?.copyToClipboard
        if (widgetData) await navigator.clipboard.writeText(`copyWidgetsData${widgetData}`)
      }
    }

    if (isEditMode && activeBlock === 'main') {
      document.addEventListener('keydown', handleCopyWidgets)
    }

    return () => {
      document.removeEventListener('keydown', handleCopyWidgets)
    }
  }, [selectedWidgets, isEditMode, copyToClipboard, activeBlock])

  useEffect(() => {
    const handleCopyStyles = (e: KeyboardEvent): void => {
      // Copy widget styles - Ctrl-Alt-c
      if (e.code === 'KeyC' && isCtrlPressed(e) && e.altKey && styles && format) {
        localStorage.setItem(
          'widgetStyles',
          JSON.stringify(getNonNullStyles(styles as {[key: string]: Widget['styles']})),
        )
        localStorage.setItem('widgetFormat', JSON.stringify(format))
      }

      // Paste widget styles - Ctrl-Alt-v
      if (e.code === 'KeyV' && isCtrlPressed(e) && e.altKey) {
        const widgetStyles = localStorage.getItem('widgetStyles')
        const widgetFormat = localStorage.getItem('widgetFormat')

        if (widgetStyles && widgetFormat) {
          const newStyles = stripTypenames(JSON.parse(widgetStyles))
          const newFormat = stripTypenames(JSON.parse(widgetFormat))
          const variables = {ids: selectedWidgets, newFormat, newStyles}

          const styleCache: Maybe<WidgetFieldsFragment> = cache.readFragment({
            id: `Widget:${selectedWidgets[selectedWidgets.length - 1]}`,
            fragment: WidgetFieldsFragmentDoc,
            fragmentName: 'WidgetFields',
          })

          if (styleCache) {
            const oldStyle = getNonNullStyles(
              styleCache.styles as {[key: string]: Widget['styles']},
            )
            const oldData = stripTypenames({
              ids: selectedWidgets,
              newFormat: styleCache.format,
              newStyles: oldStyle,
            }) as TCopyPasteData
            saveHistory('setCopyPasteStyles', {oldData, newData: variables})
          }

          copyWidgetsStyles({variables})
        }
      }
    }

    if (isEditMode && activeBlock === 'main') {
      document.addEventListener('keydown', handleCopyStyles)
    }
    return () => {
      document.removeEventListener('keydown', handleCopyStyles)
    }
  }, [styles, format, saveHistory, copyWidgetsStyles, selectedWidgets, isEditMode, activeBlock])

  return runCopyWidget
}

export const useCopyPastePages = async (pageId: number, canBeEditable: boolean) => {
  const {copyPagesToClipboard, pastePagesFromClipboard} = usePages()

  useEffect(() => {
    const action = isFirefox ? 'paste' : 'keydown'

    const handleCopyPages = async (e: KeyboardEvent) => {
      // Copy widgets - Ctrl-c
      if (e.code === 'KeyC' && !e.altKey && isCtrlPressed(e)) {
        const selection = document.getSelection()
        const selectedText = selection?.toString()

        if (selectedText) return

        const {data} = await copyPagesToClipboard({variables: {ids: [pageId]}})
        if (data?.copyPagesToClipboard)
          await navigator.clipboard.writeText(`copyPagesToClipboard${data.copyPagesToClipboard}`)
      }
    }

    const handlePastePages = async (e: KeyboardEvent | ClipboardEvent) => {
      const pastPages = async (pagesData: string) => {
        if (pagesData.indexOf('copyPagesToClipboard') === 0) {
          // Paste pages- Ctrl-v
          await pastePagesFromClipboard({
            variables: {parentId: pageId, content: pagesData.replace('copyPagesToClipboard', '')},
          })
        }
      }

      if (isFirefox) {
        const pagesData = (e as ClipboardEvent).clipboardData?.getData('text') || ''
        await pastPages(pagesData)
      } else {
        const {code, altKey, ctrlKey, metaKey} = e as KeyboardEvent
        if (
          code === 'KeyV' &&
          (ctrlKey || metaKey) &&
          !altKey &&
          !['INPUT', 'TEXTAREA'].includes((e.target as HTMLElement).tagName)
        ) {
          const pagesData = await navigator.clipboard.readText()
          await pastPages(pagesData)
        }
      }
    }

    if (canBeEditable) {
      document.addEventListener('keydown', handleCopyPages)
      document.addEventListener(action, handlePastePages)
    }

    return () => {
      document.removeEventListener('keydown', handleCopyPages)
      document.removeEventListener(action, handlePastePages)
    }
  }, [pageId, canBeEditable, copyPagesToClipboard, pastePagesFromClipboard])
}
