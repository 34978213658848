import {PureComponent, ReactNode} from 'react'
import {Copy} from '@phosphor-icons/react'

import {Button} from 'components'
import {Inner, CopyBtn, CopyText, Wrapper} from './styles'

import {ReactComponent as CrossIcon} from 'static/images/svgs/deleteicon.svg'
import {ReactComponent as Logo} from 'static/images/svgs/nedyx.svg'

interface IProps {
  renderError?: (props: {error: Error}) => ReactNode // You can render any custom fallback UI
}

interface IState {
  error: Error | null
  isCopied: boolean
}

// Use this component if you want to catch an error and render your own or default fallback UI.
// Remember: this still won't catch errors in your event handlers!
// https://reactjs.org/docs/error-boundaries.html#how-about-event-handlers
export default class ErrorBoundaryWithUI extends PureComponent<IProps, IState> {
  timeoutId: NodeJS.Timeout | null

  constructor(props: IProps) {
    super(props)
    this.state = {
      error: null,
      isCopied: false,
    }
    this.timeoutId = null
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI
    return {error}
  }

  componentWillUnmount() {
    this.timeoutId && clearTimeout(this.timeoutId)
  }

  // todo: here is a good spot to log the error to an error reporting service
  // componentDidCatch(error: Error) {}

  handleReLoad() {
    window.location.reload()
  }

  async handleCopy(val: string) {
    await navigator.clipboard.writeText(val)
    this.setState({isCopied: true})
    this.timeoutId = setTimeout(() => {
      this.setState({isCopied: false})
    }, 1000)
  }

  render() {
    const {error, isCopied} = this.state
    const {children, renderError} = this.props

    if (error) {
      return (
        renderError?.({error}) || (
          <Wrapper>
            <Inner>
              <a href='/'>
                <Logo width='136' height='30' />
                <span className='visually-hidden'>Back to the main page</span>
              </a>
              <h1>
                Oops!
                <CrossIcon aria-hidden='true' />
              </h1>
              <p>
                Something went wrong on&nbsp;our end. It&nbsp;happens. Let&apos;s give
                it&nbsp;another shot, shall we?
              </p>
              <Button $w='100px' type='button' onClick={this.handleReLoad}>
                Reload
              </Button>

              {error.message && error.message.length > 0 && (
                <details>
                  <summary>Error details (to&nbsp;make our devs happy)</summary>
                  <CopyBtn
                    type='button'
                    title='Copy'
                    onClick={() => this.handleCopy(error.message)}
                  >
                    <Copy size={14} aria-hidden='true' />
                    {isCopied && <CopyText>Copied!</CopyText>}
                  </CopyBtn>
                  <code>{error.message}</code>
                </details>
              )}
            </Inner>
          </Wrapper>
        )
      )
    }

    return children
  }
}
