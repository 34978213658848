// Append a dummy input into the page, click it and open the standard upload dialogue:
import {UploadFileDocument} from 'generated/graphql-operations'
import {setLoadingCursor} from 'templates/Settings/Widget/Styles/utils'
import {gql} from '@apollo/client'
import {client} from 'apollo'

export function createUpload(
  options: Pick<HTMLInputElement, 'accept' | 'multiple'>,
): Promise<FileList | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = options.accept
    input.multiple = options.multiple
    input.id = 'upload-file'
    input.style.opacity = '0'
    input.style.position = 'absolute'
    input.style.width = '0'

    reader.onprogress = function (event) {
      if (event.lengthComputable) {
        const percentRead = Math.round((event.loaded / event.total) * 100)
        /* eslint-disable no-console */
        console.log(`File reading progress: ${percentRead}%`)
      }
    }

    reader.onload = function () {
      input.remove()
      resolve(input.files)
      /* eslint-disable no-console */
      console.error('resolve success')
    }

    reader.onerror = () => {
      input.remove()
      reject(new Error('Upload failed'))
      /* eslint-disable no-console */
      console.error('Reading error', reader.error)
    }

    const changeHandler = function () {
      if (input.files?.length) reader.readAsDataURL(input.files[0])
    }

    input.addEventListener('change', changeHandler)

    input.addEventListener('cancel', function () {
      input.remove()
      reject(new Error('Upload cancelled'))
      console.error('cancel success')
    })

    document.body.appendChild(input)
    // Open file select dialogue
    input.click()
  })
}

const MAX_SIZE = 5e6
const AMAZON_S3_MAX_SIZE = 100e6
const VALID_TYPES = ['text/plain', 'text/csv']

const validateText = (f: File): string => {
  if (!VALID_TYPES.includes(f.type)) {
    return `Unsupported file type "${f.type}". Accepted types: ${VALID_TYPES.join(', ')}.`
  }
  if (f.size > MAX_SIZE) {
    return `Max allowed file size (${MAX_SIZE / 1e6} MB) exceeded.`
  }
  return ''
}

const validateFile = (f: File): string => {
  if (f.size > AMAZON_S3_MAX_SIZE) {
    return `Max allowed file size (${AMAZON_S3_MAX_SIZE / 1e6} MB) exceeded.`
  }
  return ''
}

export async function uploadText(
  onSuccess: (contentStr: string | string[]) => void,
  onError: (error: string) => void,
): Promise<void> {
  try {
    const files = await createUpload({accept: VALID_TYPES.join(','), multiple: false})
    if (files) {
      const [file] = files
      const error = validateText(file)
      if (error) throw new Error(error)

      const fr = new FileReader()
      const loadHandler = () => {
        if (fr.result && typeof fr.result === 'string') {
          const cleanHTML = fr.result
          onSuccess(encodeURIComponent(cleanHTML))
          fr.removeEventListener('load', loadHandler)
        }
      }

      fr.addEventListener('load', loadHandler)
      fr.readAsText(file)
    }
  } catch (err) {
    if (err instanceof Error) onError(err.message)
  }
}

const fileNameFromUrl = (urls: string[]): string[] => urls.map((url) => url.split('/').pop() || '')

export async function uploadFile(
  onSuccess: (contentStr: string | string[]) => void,
  onError: (error: string) => void,
): Promise<void> {
  try {
    const files = await createUpload({accept: '', multiple: false})
    if (files) {
      const [file] = files
      const error = validateFile(file)
      if (error) throw new Error(error)

      const workspace = localStorage.getItem('workspaceName') || ''
      setLoadingCursor(true, 'uploadFile')

      const schema = gql`
        ${UploadFileDocument}
      `
      await client
        .mutate({
          mutation: schema,
          variables: {file, workspace},
        })
        .then(
          ({data}) =>
            Array.isArray(data?.uploadFile) && onSuccess(fileNameFromUrl(data.uploadFile)),
        )
        .finally(() => {
          setLoadingCursor(false, 'uploadFile')
        })
    }
  } catch (err) {
    if (err instanceof Error) onError(err.message)
  }
}
