import styled from 'styled-components'

export default function Copyright() {
  return <CopyrightText>© {new Date().getFullYear()} nedyx software GmbH</CopyrightText>
}

const CopyrightText = styled.p`
  font-size: 14px;
  text-align: center;
  color: ${(p) => p.theme.colors.subhead};
  margin-bottom: 0.15em;
`
