import {CaretDown, IconProps} from '@phosphor-icons/react'
import {memo, MouseEvent} from 'react'
import styled from 'styled-components'

interface IProps {
  isOpen: boolean
  toggleHandler?: (e: MouseEvent) => void
  size?: number
  color?: string
  disableClick?: boolean
}

const Caret = ({
  isOpen,
  toggleHandler,
  size = 14,
  color = 'black',
  disableClick = false,
}: IProps) => {
  const props: IconProps = {
    size,
    color,
    onClick: toggleHandler,
    style: {cursor: 'pointer', pointerEvents: disableClick ? 'none' : 'auto'},
    weight: 'bold',
  }

  return <StyledCaretUp {...props} $isOpen={isOpen} />
}

const StyledCaretUp = styled(CaretDown)<{$isOpen: boolean}>`
  transform: rotate(${({$isOpen}) => ($isOpen ? '180deg' : '0')});
  transition: transform 0.2s ease-in-out;
`

export default memo(Caret)
