import {makeVar} from '@apollo/client'

import {Maybe, UserDataQuery} from 'generated/graphql-operations'

import breakpoints from 'theme/breakpoints'
import {FeatureToggle} from 'static/constants'
import {getAccessToken} from 'repository/services'
import {Permission} from 'templates/Shared/helpers/models'
import {MessageResponse} from 'templates/Subscriptions/Modal/models'

export const token: string = getAccessToken()

export interface IErrorData {
  message: string
  payload?: {
    permission: {name: string}
    applicationId: number
    title: string
  }
}

export type ErrorMessageType = {
  type: string,
  data: MessageResponse,
  runResponse: (eventData?: string) => Promise<void> | null
}

export const setEditModeVar = makeVar<boolean>(false)
export const setErrorVar = makeVar<IErrorData | null>(null)
export const setAppIdVar = makeVar<number>(0)
export const setSideBarOpenVar = makeVar<boolean>(window.innerWidth > breakpoints.xl)
export const setAutoSaveStatusVar = makeVar<boolean>(false)
export const setIsAuthenticatedVar = makeVar<boolean>(!!token)
export const setUserDataVar = makeVar<Maybe<UserDataQuery['me']>>(null)
export const setErrorMessageVar = makeVar<ErrorMessageType | null>(null)
export const setUserPermissionVar = makeVar<Permission | null>(null)
export const setShowAllAppsVar = makeVar<boolean>(false)
export const setFeaturePreview = makeVar<FeatureToggle>(null)
