import styled from 'styled-components'

// Please keep these styles as concise as possible!
export const List = styled.ul`
  list-style: none;

  li:not(:last-child) {
    margin-bottom: 0.5em;
  }

  ul {
    margin-top: 0.5em;
  }
`

export const ListItem = styled.li`
  position: relative;
  padding-left: 1.1em;
  font-size: 15px;
  line-height: 1.3;
  color: ${({theme}) => theme.colors.text};
`

export const Button = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 20px;

  & > svg {
    width: 0.55em;
    height: 0.55em;
    pointer-events: none;
    transform: rotate(-90deg);
  }

  & > svg.expanded {
    transform: rotate(0deg);
  }
`
