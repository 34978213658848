import styled from 'styled-components'

import colors from 'theme/colors'
import {ZOrder} from 'theme/constants'

const RIGHT_OFFSET = 15

// Since styled-components v6+, `ToastContainerProps` are not passed to the `ToastContainer` for some reason.
// I.e. `styled(ToastContainer)` will not get its icons and other settings.
// Hence, we need this separate wrapper.
const StyledToastContainer = styled.div`
  .Toastify__toast-container {
    width: auto;
    min-width: 320px;
    max-width: 425px;
    z-index: ${ZOrder.TOAST};
  }

  .Toastify__toast {
    padding: 8px ${RIGHT_OFFSET}px 8px 8px;
    border-radius: 6px;
  }

  .Toastify__toast-body {
    position: relative;
    padding-right: ${RIGHT_OFFSET}px;
    padding-bottom: 8px;
    font-family: Nunito, Arial, sans-serif;
    font-weight: 700;
    line-height: 1.25;
    color: ${colors.mainBackground};

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: ${RIGHT_OFFSET}px;
      width: 1px;
      height: 35px;
      margin-top: auto;
      margin-bottom: auto;
      background-color: rgba(255, 255, 255, 0.2);
    }

    & > div:last-child {
      padding-right: ${RIGHT_OFFSET}px;
    }
  }

  .Toastify__close-button {
    align-self: center;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .Toastify__toast-icon {
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${colors.mainBackground};
  }

  // BG colours for the "colored" theme
  .Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: ${colors.error};

    .Toastify__toast-icon {
      color: ${colors.error};
    }
  }

  .Toastify__toast-theme--colored.Toastify__toast--default,
  .Toastify__toast-theme--colored.Toastify__toast--info {
    background-color: ${colors.primary};

    .Toastify__toast-icon {
      color: ${colors.primary};
    }
  }

  .Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: ${colors.success};

    .Toastify__toast-icon {
      color: ${colors.success};
    }
  }

  .Toastify__toast-theme--colored.Toastify__toast--warning {
    background-color: ${colors.warning};

    .Toastify__toast-icon {
      color: ${colors.warning};
    }
  }
`

export default StyledToastContainer
