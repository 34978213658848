import {lazy, useLayoutEffect} from 'react'
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'

const ResetPassword = lazy(() => import('./ForgotPassword'))
const SignIn = lazy(() => import('./SignIn'))
const UpdatePassword = lazy(() => import('./UpdatePassword'))
const Welcome = lazy(() => import('./Welcome'))

export default function UnauthenticatedViews() {
  const location = useLocation()

  useLayoutEffect(() => {
    const status = sessionStorage.getItem('lastVisitedUrl')
    if (status === 'reload') sessionStorage.removeItem('lastVisitedUrl')
    else if (status !== 'visited') {
      sessionStorage.setItem('lastVisitedUrl', window.location.href)
    }
  }, [])

  return (
    <Routes>
      <Route path='/' element={<Welcome />} />
      <Route path='/:workspace?/login' element={<SignIn />} />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route path='/reset-password/:token' element={<UpdatePassword />} />
      {/* https://v5.reactrouter.com/web/example/auth-workflow */}
      <Route path='/*' element={<Navigate to='/' replace state={{from: location}} />} />
    </Routes>
  )
}
