import {ReactNode, memo} from 'react'
import {useDropzone, FileRejection} from 'react-dropzone'
import styled from 'styled-components'
import {toast} from 'react-toastify'

import {ContainerId} from '../Toast/config'

import Border from './Border.svg'
import {ReactComponent as CloudArrowUp} from 'static/images/svgs/cloud-arrow-up.svg'

interface IProps {
  onFilesSet: (files: File[]) => void
  accept: string[]
  maxSize: number
  children: (open: () => void) => ReactNode
  dropZoneHeader?: string
  h?: string
}

const DropZone = ({accept, maxSize, dropZoneHeader = '', onFilesSet, children, h}: IProps) => {
  const {getRootProps, getInputProps, open} = useDropzone({
    accept,
    maxSize,
    onDrop: (acceptedFiles: File[]) => onFilesSet(acceptedFiles),
    onDropRejected: (fileRejections: FileRejection[]) => {
      let errorCopy = ''
      if (fileRejections[0].errors[0].code === 'file-too-large') {
        errorCopy = 'Image is larger than 5 mb'
      }
      if (fileRejections[0].errors[0].code === 'file-invalid-type') {
        errorCopy = `Image should be ${accept.join(' ')}`
      }
      toast.error(errorCopy, {containerId: ContainerId.ROOT})
    },
  })

  return (
    <>
      {children(open)}
      <DropZoneActive
        {...getRootProps({className: 'dropzone'})}
        $height={h}
        data-testid='dropzone-active'
      >
        <input {...getInputProps()} />
        <CloudArrowUp />
        <DropZoneHeader>{dropZoneHeader}</DropZoneHeader>
        <DropZoneText>Drag and drop file here or click to upload</DropZoneText>
      </DropZoneActive>
    </>
  )
}

export default memo(DropZone)

const DropZoneActive = styled.div<{$height?: string}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${(p) => p.$height || '100%'};
  cursor: pointer;
  align-items: center;
  background-image: url(${Border});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  color: ${(p) => p.theme.colors.subhead};
`

const DropZoneHeader = styled.h2`
  min-height: 10px;
  ${(p) => p.theme.typography.body1bold};
`

const DropZoneText = styled.p`
  ${(p) => p.theme.typography.body1};
  font-size: 14px;
  color: ${(p) => p.theme.colors.subhead};
`
