import {DefaultOptions} from '@apollo/client'

const defaultOptions: DefaultOptions = {
  query: {
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
  watchQuery: {
    errorPolicy: 'all',
  },
}

export default defaultOptions
