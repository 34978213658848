import {isDateISOString, ERROR_NAMES_TO_FRONTEND} from 'utils'
import {Millisecond} from 'static/constants/time'
import {ValueType} from 'apollo'
import {WidgetFormat} from 'generated/graphql-operations'
// ------ change data value from backend to the formatted value ----------
//        Can be percent setting or grouping setting, not both

const MAX_SYMBOL = 14

export const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

export const DATE_OPTION: Intl.DateTimeFormatOptions = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
}
export const TIME_DATE_OPTION: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hourCycle: 'h23',
}
export const FULL_DATE_OPTION: Intl.DateTimeFormatOptions = {
  ...DATE_OPTION,
  ...TIME_DATE_OPTION,
}

const DEFAULT_LOCALE = navigator.language || 'en-US'

const setNumberPrecision = (val: number): number => Number(val.toPrecision(MAX_SYMBOL))

type savedFunctionsType = {formatter?: Intl.NumberFormat; dateFormatter?: Intl.DateTimeFormat}
const savedFunctions: {[key: string]: savedFunctionsType} = {}

type TFormatOptions = {
  locale?: string
  noDigit?: boolean
  timeZone?: string
}

const formatNumber = (
  num: number,
  {showNumberInPercent, showGroupedNumber, fractionDigits}: WidgetFormat,
  options?: TFormatOptions,
): string => {
  const lang = options?.locale || DEFAULT_LOCALE
  const digitsApplied =
    typeof fractionDigits === 'number' && fractionDigits >= 0 && !options?.noDigit

  const key = `${lang}${digitsApplied}${showNumberInPercent}${fractionDigits}${showGroupedNumber}`
  if (!savedFunctions[key]) savedFunctions[key] = {}

  const applyDigitsFormula = (numberValue: number): string => {
    if (!savedFunctions[key].formatter) {
      const maxDigits = MAX_SYMBOL - (showNumberInPercent ? 2 : 0)
      const formatOptions: Intl.NumberFormatOptions = {
        style: showNumberInPercent ? 'percent' : 'decimal',
        useGrouping: !!showGroupedNumber && !showNumberInPercent,
        minimumFractionDigits: digitsApplied ? fractionDigits : 0,
        maximumFractionDigits: digitsApplied ? fractionDigits : maxDigits,
      }

      savedFunctions[key].formatter = new Intl.NumberFormat(lang, formatOptions)
    }

    const fixedNumber = digitsApplied ? numberValue : setNumberPrecision(numberValue)
    return savedFunctions[key].formatter?.format(fixedNumber) || ''
  }

  return applyDigitsFormula(num)
}

const getOptions = (
  {dateType, showMilliseconds, showSeconds, showLocalTime}: WidgetFormat,
  timeZone: string,
) => {
  const seconds: Intl.DateTimeFormatOptions = showSeconds ? {second: 'numeric'} : {}
  const millS: Intl.DateTimeFormatOptions = showMilliseconds ? {fractionalSecondDigits: 3} : {}
  const localTime: Intl.DateTimeFormatOptions = {timeZone: showLocalTime ? timeZone : 'UTC'}

  if (dateType === 'TIME') return {...TIME_DATE_OPTION, ...seconds, ...millS, ...localTime}
  if (dateType === 'DATE_TIME') return {...FULL_DATE_OPTION, ...seconds, ...millS, ...localTime}
  return {...DATE_OPTION, ...localTime}
}

export const formatDate = (v: string, format: WidgetFormat, ops?: TFormatOptions): string => {
  const {locale = DEFAULT_LOCALE, timeZone = TIME_ZONE} = ops || {}
  const key = `${locale}${timeZone}${JSON.stringify(format)}`
  if (!savedFunctions[key]) savedFunctions[key] = {}

  if (!savedFunctions[key].dateFormatter) {
    const options = getOptions(format, timeZone)
    savedFunctions[key].dateFormatter = new Intl.DateTimeFormat(locale, options)
  }
  return savedFunctions[key].dateFormatter?.format(new Date(v)) || ''
}

export const stripTimestamp = (isoStr: string, {dateType, showLocalTime}: WidgetFormat) => {
  if (dateType === 'TIME') return isoStr

  const isoDateTime = new Date(isoStr)

  if (dateType === 'DATE_TIME') {
    if (showLocalTime) {
      const localDateTime = new Date(isoDateTime.getTime())

      // prettier-ignore
      return localDateTime.getFullYear().toString().padStart(4, '0') +
        '-' + String(localDateTime.getMonth() + 1).padStart(2, '0') +
        '-' + String(localDateTime.getDate()).padStart(2, '0') +
        'T' + String(localDateTime.getHours()).padStart(2, '0') +
        ':' + String(localDateTime.getMinutes()).padStart(2, '0');
    }

    return isoDateTime.toISOString().substring(0, 16)
  }

  return isoDateTime.toISOString().substring(0, 10)
}

// Format values per user's locale.
export const formatValue = (
  value: ValueType,
  format: WidgetFormat,
  opts?: TFormatOptions,
): string => {
  if (value === null || value === undefined) return ''
  if (typeof value === 'boolean') return value.toString()
  if (typeof value === 'number') return formatNumber(value, format, opts)
  if (isDateISOString(value)) return formatDate(value, format, opts)

  return ERROR_NAMES_TO_FRONTEND[value] || value
}

export const setTableDisplayFormat = (
  rows: ValueType[][],
  format: WidgetFormat,
  lang = DEFAULT_LOCALE,
): string[][] => {
  if (!Array.isArray(rows)) return [['']]
  return rows.map((row) =>
    row.length ? row.map((cell) => formatValue(cell, format, {locale: lang})) : [''],
  )
}

export const fullTime = (date: string, options?: {locale?: string}): string =>
  date && new Date(date).toLocaleDateString(options?.locale || DEFAULT_LOCALE, FULL_DATE_OPTION)

export const partTime = (date: string, options?: {locale?: string}): string => {
  const spendTime = new Date().getTime() - new Date(date).getTime()

  if (!spendTime || spendTime < 0) return ''
  if (Millisecond.HOUR > spendTime) return `${Math.trunc(spendTime / Millisecond.MINUTE)}min ago`
  if (Millisecond.DAY > spendTime) return `${Math.trunc(spendTime / Millisecond.HOUR)}h ago`
  return new Date(date).toLocaleDateString(options?.locale || DEFAULT_LOCALE, DATE_OPTION)
}
