export const setOpacity = (colorHex: string, alpha: number): string =>
  `${colorHex}${Math.floor(alpha * 255)
    .toString(16)
    .padStart(2, '0')}`

// https://stackoverflow.com/a/35970186
export function invertColor(hex: string, bw?: boolean): string | never {
  let hexValue = hex.slice(Number(hex.indexOf('#') === 0))

  // convert 3-digit hex to 6-digits.
  if (hexValue.length === 3) {
    hexValue = hexValue[0] + hexValue[0] + hexValue[1] + hexValue[1] + hexValue[2] + hexValue[2]
  }
  if (hexValue.length !== 6) {
    throw new Error(`Invalid HEX color: ${hex}`)
  }

  let r: string | number = parseInt(hexValue.slice(0, 2), 16),
    g: string | number = parseInt(hexValue.slice(2, 4), 16),
    b: string | number = parseInt(hexValue.slice(4, 6), 16)

  if (bw) {
    // https://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF'
  }

  // invert color components
  r = (255 - r).toString(16)
  g = (255 - g).toString(16)
  b = (255 - b).toString(16)

  // pad each with zeros
  return `#${[r, g, b].map((v) => v.padStart(2, '0')).join('')}`
}

// https://css-tricks.com/converting-color-spaces-in-javascript/
export function rgbToHex(rgb?: string): string {
  if (!rgb) return ''

  // Choose correct separator
  const sep = rgb.indexOf(',') > -1 ? ',' : ' '
  // Turn "rgb(r,g,b)" into [r,g,b]
  const rgbVal = rgb.slice(4).split(')')[0].split(sep)

  let r = (+rgbVal[0]).toString(16),
    g = (+rgbVal[1]).toString(16),
    b = (+rgbVal[2]).toString(16)

  if (r.length == 1) r = '0' + r
  if (g.length == 1) g = '0' + g
  if (b.length == 1) b = '0' + b

  return '#' + r + g + b
}

// https://css-tricks.com/snippets/javascript/lighten-darken-color/
// Will return '0' if invalid color is provided (even if it's a valid `CSS.supports('color', c)` value)
export function lightenDarkenColor(color: string, amount: number): string {
  let usePound = false
  let colorVal = color

  if (color[0] === '#') {
    colorVal = color.slice(1)
    usePound = true
  }

  const num = parseInt(colorVal, 16)

  let r = (num >> 16) + amount

  if (r > 255) r = 255
  else if (r < 0) r = 0

  let b = ((num >> 8) & 0x00ff) + amount

  if (b > 255) b = 255
  else if (b < 0) b = 0

  let g = (num & 0x0000ff) + amount

  if (g > 255) g = 255
  else if (g < 0) g = 0

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

export const isValidColor = (color: string): boolean => CSS.supports('color', color)
