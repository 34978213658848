export type AppMode = 'live' | 'edit';
interface AppsModeHash {
  [key: string]: AppMode;
}

type AppsPageHash = {
  [key: string]: number;
};

export const setStoredPageIds = (appId: number, pageId: number): void => {
  const storedPagesByApps = getStoredPageIds();
  sessionStorage.setItem('pagesByApps', JSON.stringify({...storedPagesByApps, [appId]: pageId}));
};

export const getStoredPageIds = (): AppsPageHash | null => {
  const storageValue = sessionStorage.getItem('pagesByApps');
  if (!storageValue?.trim().length) return null;

  const parsedPageIds: AppsPageHash = JSON.parse(storageValue);
  if (!Array.isArray(parsedPageIds)) {
    return parsedPageIds;
  }
  return null;
};

export const getStoredAppModes = (): AppsModeHash | null => {
  const storageValue = sessionStorage.getItem('modesByApps');
  if (!storageValue) return null;

  const parsedAppModes: AppsModeHash = JSON.parse(storageValue);
  if (!Array.isArray(parsedAppModes)) {
    return parsedAppModes;
  }
  return null;
};

export const setStoredAppModes = (appId: number, mode: AppMode) => {
  const storedObj = getStoredAppModes();
  sessionStorage.setItem('modesByApps', JSON.stringify({...storedObj, [appId]: mode}));
};
