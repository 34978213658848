export function stripTypenames<T>(value: T): T | T[] {
  if (Array.isArray(value)) {
    return value.map(stripTypenames);
  }
  if (value !== null && typeof value === 'object') {
    const newObject: Record<string, unknown> = {};
    for (const property in value) {
      if (property !== '__typename') {
        newObject[property] = stripTypenames(value[property]);
      }
    }
    return newObject as T;
  }
  return value;
}
