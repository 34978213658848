import {useCallback} from 'react'
import {toast} from 'react-toastify'
import {useReactiveVar} from '@apollo/client'

import {
  Widget,
  Page,
  useSetTableViewTypeMutation,
  useSetWidgetViewTypeGenericMutation,
  WidgetViewType,
} from 'generated/graphql-operations'
import {deselectAllWidgets, setSelectedWidgetsVar, setWidgetsGeometryVarWithId} from 'apollo'
import {usePages, useWidgets} from 'repository/graphql'
import {ContainerId} from 'components/Toast/config'

export type TPageTypeValue = {
  id: Widget['id']
  type: 'PAGE_AVAILABILITY_IN_LIVE_MODE'
  newData: Page['availabilityInLiveMode']
  oldData: Page['availabilityInLiveMode']
}

export const usePageTypeRollback = () => {
  const {setPageAvailabilityInLiveMode} = usePages()

  return useCallback(
    async ({id, newData, oldData, type}: TPageTypeValue): Promise<TPageTypeValue | void> => {
      try {
        const {data} = await setPageAvailabilityInLiveMode({
          variables: {id, availabilityInLiveMode: oldData as Page['availabilityInLiveMode']},
        })
        if (!data?.setPageAvailabilityInLiveMode) return
        deselectAllWidgets()
        return {id, type, newData: oldData, oldData: newData}
      } catch (e) {
        toast.warning('Lost undo-redo of page attributes', {containerId: ContainerId.ROOT})
      }
    },
    [setPageAvailabilityInLiveMode],
  )
}

export type TWidgetTypeValue = {
  id: Widget['id']
  type: 'CELL' | 'TABLE' | 'MENU'
  newData: WidgetViewType
  oldData: WidgetViewType
  oldGeo?: Widget['geometry']
  newGeo?: Widget['geometry']
}

export const useWidgetTypeRollback = () => {
  const {moveWidgets} = useWidgets()
  const [setTableType] = useSetTableViewTypeMutation()
  const [setWidgetViewType] = useSetWidgetViewTypeGenericMutation()
  const widgetsGeometryWithId = useReactiveVar(setWidgetsGeometryVarWithId)

  return useCallback(
    async (value: TWidgetTypeValue): Promise<TWidgetTypeValue | void> => {
      try {
        const {id, newData, oldData, type, oldGeo, newGeo} = value

        if (type === 'TABLE' && oldGeo) {
          const {data} = await setTableType({variables: {id, type: oldData as WidgetViewType}})
          if (!data?.setWidgetViewType) return
          setSelectedWidgetsVar([id])
          setWidgetsGeometryVarWithId({...widgetsGeometryWithId, [id]: oldGeo})

          moveWidgets({
            variables: {widgetsGeometries: {...oldGeo, id}},
            optimisticResponse: {
              moveWidgets: [
                {
                  id,
                  geometry: {...oldGeo, __typename: 'WidgetGeometry'},
                  __typename: 'Widget',
                },
              ],
            },
          })
          return {id, type, newData: oldData, oldData: newData, oldGeo: newGeo, newGeo: oldGeo}
        }

        if (type === 'CELL' || type === 'MENU') {
          const {data} = await setWidgetViewType({variables: {id, type: oldData as WidgetViewType}})
          if (!data?.setWidgetViewType) return
          setSelectedWidgetsVar([id])

          return {id, type, newData: oldData, oldData: newData}
        }
      } catch (e) {
        toast.warning('Lost undo-redo of widget type data', {containerId: ContainerId.ROOT})
      }
    },
    [moveWidgets, setTableType, setWidgetViewType, widgetsGeometryWithId],
  )
}
