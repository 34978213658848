import {CSSProperties, ReactNode} from 'react'
import styled from 'styled-components'

interface IProps {
  children?: ReactNode
  className?: string
  id?: string
  $animation?: 'pulse' | 'wave' // replicas of MUI Skeleton animations
  $bgc?: CSSProperties['backgroundColor']
  $h?: CSSProperties['height']
  $m?: CSSProperties['margin']
  $variant?: 'circle' | 'rounded' | 'text'
  $w?: CSSProperties['width']
}

export const MARGIN = 10
const DEFAULT_BG = 'rgba(0, 0, 0, .11)'

export default function Skeleton(props: IProps) {
  const {children, $variant = 'text', ...rest} = props

  return (
    <Container $variant={$variant} {...rest}>{children}</Container>
  )
}

const Container = styled.div<Omit<IProps, 'children'>>`
  @keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: .33; }
    100% { opacity: 1; }
  }
  
  @keyframes wave {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(200%); }
  }
  
  position: relative;
  overflow: hidden;
  width: ${({$w}) => $w ? $w + (typeof $w === 'number' ? 'px' : '') : undefined};
  height: ${({$h, $variant}) => {
    if (!$h && $variant === 'text') return '1em'
    return $h ? $h + (typeof $h === 'number' ? 'px' : '') : undefined
  }};
  margin: ${({$m = `0 0 ${MARGIN}px 0`}) => $m};
  border-radius: ${({ $variant, theme }) => {
    if ($variant === 'circle') return '50%';
    if ($variant === 'rounded') return theme.brdr.main;
    return undefined
  }};
  background-color: ${({ $bgc = DEFAULT_BG }) => $bgc};
  animation: ${({ $animation }) => $animation === 'pulse' && 'pulse 1.5s ease-in-out .5s infinite'};
  
  ${({ $animation = 'wave' }) => {
    if ($animation === 'wave') {
      return `
        &::before {
          content: "";
          position: absolute;
          top: 0; right: 0; left: 0; bottom: 0;
          background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.05), transparent);
          animation: wave 1.6s linear .5s infinite;
          transform: translateX(-100%);
        }
      `
    }
  }};
`;
