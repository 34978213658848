import {setErrorMessageVar} from 'apollo'
import {deleteCookie, getCookieValue} from 'utils'
import {DataSourceOptions} from 'generated/graphql-operations'
import {TConnectionFields} from '../model'
import {
  connectionOAuthInfo,
  ConnectionOAuthType,
  connectionUserInfo,
  ConnectionUserType,
} from './constants'
import {toast} from 'react-toastify'

type TimeoutType = ReturnType<typeof setInterval>

const CHECK_TOKEN_TIMEOUT = 1000
const MODAL_CLOSE_TIMOUT = 120000
type ResponseType = 'ERROR' | 'HAS_TOKEN' | 'UPDATED' | 'CANCEL'
type ConenctionFieldsType = Omit<TConnectionFields, 'password' | 'technical'>

export const getOAuthToken = async ({
  type,
  dbName,
  server,
}: ConenctionFieldsType): Promise<ResponseType> => {
  const {cookie_name, url} = connectionOAuthInfo[type as ConnectionOAuthType]
  if (!cookie_name || !url) return 'ERROR'

  const param = type === 'SSAS' ? `?server=${server}&database=${dbName}` : ''
  const windowAi = window.open(url + param, cookie_name, 'width=900,height=800')

  if (windowAi) {
    deleteCookie(cookie_name)

    const startTime = Date.now()
    let timeoutId: TimeoutType
    const checkTokenOnBE = async (): Promise<ResponseType> => {
      return new Promise((res) => {
        const currentTime = Date.now()

        timeoutId = setInterval(() => {
          const elapsedTime = currentTime - startTime
          const newToken = getCookieValue(cookie_name)

          if ((!newToken && windowAi.closed) || elapsedTime > MODAL_CLOSE_TIMOUT) {
            clearInterval(timeoutId)
            res('ERROR')
          }

          if (newToken || windowAi.closed) {
            clearInterval(timeoutId)
            windowAi.close()
            res(newToken)
          }
        }, CHECK_TOKEN_TIMEOUT)
      })
    }

    try {
      const status = await checkTokenOnBE()
      document.cookie = `${cookie_name}=clearCookie; max-age=0; path=/`
      return status
    } catch (error) {
      return 'ERROR'
    }
  }

  return 'ERROR'
}

const getUserToken = async (options: ConenctionFieldsType, id: number): Promise<ResponseType> => {
  const {cookie_name} = connectionUserInfo[options.type as ConnectionUserType]

  if (options.server && options.authentication) {
    const content = {
      id: `${id}`,
      title: '',
      type: options.type || '',
      server: options.server,
      dbName: options.dbName || '',
    }

    setErrorMessageVar({type: 'CONNECT', runResponse: () => null, data: content})

    const startTime = Date.now()
    let timeoutId: TimeoutType
    const checkTokenOnBE = async (): Promise<ResponseType> => {
      return new Promise((res) => {
        const currentTime = Date.now()
        const elapsedTime = currentTime - startTime
        if (elapsedTime > MODAL_CLOSE_TIMOUT) res('ERROR')

        timeoutId = setInterval(() => {
          const newToken = getCookieValue(cookie_name)
          if (newToken) {
            clearInterval(timeoutId)
            res(newToken)
          }
        }, CHECK_TOKEN_TIMEOUT)
      })
    }

    try {
      const status = await checkTokenOnBE()
      document.cookie = `${cookie_name}=clearCookie; max-age=0; path=/`
      return status
    } catch (error) {
      return 'ERROR'
    }
  } else {
    return 'ERROR'
  }
}

const getBasicToken = async (options: ConenctionFieldsType): Promise<ResponseType> => {
  const {url} = connectionUserInfo[options.type as ConnectionUserType]

  if (!url) return 'ERROR'

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': url,
    },
    body: JSON.stringify(options),
  })

  if (response.status === 200) {
    return 'HAS_TOKEN'
  } else {
    const error = await response.json()
    toast.error(error.message, {toastId: 'connection-confirm-error'})
    return 'ERROR'
  }
}

export type CookieForConnectionType = {
  options: ConenctionFieldsType
  widgetId: number
}

// check a connection type and select correct way for connection
export const getCookieForConnection = async (
  data: CookieForConnectionType,
): Promise<ResponseType> => {
  const {options, widgetId} = data

  const getToken = async () => {
    switch (options.authentication) {
      case 'OAuth2':
        return await getOAuthToken(options)
      case 'User':
        return await getUserToken(options, widgetId)
      case 'Basic':
        return await getBasicToken(options)
      default:
        return 'ERROR'
    }
  }

  return await getToken()
}

export const disconnectCookie = (
  type: DataSourceOptions['type'],
  authentication: DataSourceOptions['authentication'],
  server: DataSourceOptions['server'],
) => {
  if (authentication === 'OAuth2' && connectionOAuthInfo[type as ConnectionOAuthType]) {
    const oauthType = type as ConnectionOAuthType
    const {logout} = connectionOAuthInfo[oauthType]

    if (type === 'SSAS') {
      window.open(logout, 'azure_logout', 'width=900,height=800')
    }

    if (type === 'GOOGLE_SHEETS' || type === 'SMARTBOOKS_AI') {
      fetch(logout, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': logout,
        },
      })
    }
  }

  if (
    authentication &&
    ['User', 'Basic'].includes(authentication) &&
    connectionUserInfo[type as ConnectionUserType]
  ) {
    const userType = type as ConnectionUserType
    const {logout} = connectionUserInfo[userType]

    fetch(logout + `?server=${server}`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': logout,
      },
    })
  }
}
