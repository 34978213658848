import {KeyboardEvent} from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TGenericObj = {[x: string]: any}

export function isInstanceOf<T extends Record<string, unknown>>(
  object: TGenericObj,
  prop: keyof T,
): object is T {
  return prop in object
}

export function pickProps<T extends Record<string, unknown>>(
  obj: T,
  propsToPick: Array<keyof T>,
): T {
  const copy = {} as T
  propsToPick.forEach((prop) => (copy[prop] = obj[prop]))
  return copy
}

export function omitProps<T extends Record<string, unknown>>(
  obj: T,
  propsToOmit: Array<keyof T>,
): T {
  const copy = {...obj}
  propsToOmit.forEach((prop) => delete copy[prop])
  return copy
}

export const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') >= 0
export const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0

// https://hidde.blog/using-javascript-to-trap-focus-in-an-element/
export const trapFocus = (evt: KeyboardEvent, containerEl: Element | null) => {
  if (containerEl && evt.code === 'Tab') {
    const focusableEls = containerEl.querySelectorAll(
      'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled])',
    )
    if (!focusableEls.length) return

    const firstEl = focusableEls[0] as HTMLElement
    const lastEl = focusableEls[focusableEls.length - 1] as HTMLElement

    if (evt.shiftKey) {
      if (document.activeElement === firstEl) {
        lastEl.focus()
        evt.preventDefault()
      }
    } else {
      if (document.activeElement === lastEl) {
        firstEl.focus()
        evt.preventDefault()
      }
    }
  }
}

export const isApple = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)

export const xor = (a: boolean, b: boolean) => a ? !b : b

export const hasOverflow = (el: HTMLElement | null) => Boolean(el && el.scrollWidth > el.clientWidth)
