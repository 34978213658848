const colors = {
  // light & dark
  darkMain: '#000000',
  mainBackground: '#ffffff',

  // theme
  primary: '#25bee1',
  primaryFaint: '#B7EBFCFF',
  error: '#e34848',
  info: '#497dcb',
  secondary: '#202d3e',
  success: '#61b659',
  warning: '#f0ac47',

  // typography
  header: '#242424',
  subhead: '#787878',
  text: '#3d3d3d',
  infoText: '#bdbdbd',

  // shades of grey
  disabled: '#c7c7c7',
  light: '#d4d4d4',
  lighter: '#e3e3e3',
  lightest: '#f8f8f8',
  opacity: 'rgba(255, 255, 255, 0.1)',
  darkOpacity: 'rgba(0, 0, 0, 0.1)',
  outerBorder: '#e8e8e8',
}

export default colors
