import {
  ReactElement,
  ReactNode,
  useMemo,
  useState,
  MouseEvent,
  useCallback,
  useRef,
  RefObject,
} from 'react'
import clsx from 'clsx'
import DOMPurify from 'dompurify'
import {debounce} from 'throttle-debounce'
import {useReactiveVar} from '@apollo/client'
import {setEditModeVar} from 'apollo'
import {TooltipContainer, TooltipContent} from './styles'
import Portal from 'components/Portal'
import {isHtml} from 'utils'

interface IProps {
  children: ReactElement
  content: ReactNode
  className?: string
  disable?: boolean
  placement?: 'top' | 'top-left' | 'right' | 'bottom' | 'left' | 'fixed-container' | 'widget'
  rootCmp?: 'div' | 'span' // when placing tooltip inside e.g. <button>, you'll want to use <span> to preserve HTML validity
}

const [SAVE_TOOLTIP_ZONE, TOOLTIP_DEFAULT_HEIGHT] = [16, 30]
const OUTSIDE_WINDOW = -99999

export default function Tooltip({
  children,
  className,
  content,
  disable,
  placement = 'top',
  rootCmp = 'div',
}: IProps) {
  const isEditMode = useReactiveVar(setEditModeVar)
  const tooltipRef: RefObject<HTMLDivElement> = useRef(null)
  const [visible, setVisible] = useState(false)
  const [positionY, setPositionY] = useState(placement === 'widget' ? OUTSIDE_WINDOW : 0)
  const [positionX, setPositionX] = useState(placement === 'widget' ? OUTSIDE_WINDOW : 12)

  const tooltipId = useMemo(() => {
    return disable ? undefined : `tltp-${Math.random().toString(36).slice(2, 8)}`
  }, [disable])

  const changeVisibleTrue = (event: MouseEvent<HTMLElement>) => {
    setVisible(true)

    if (placement === 'fixed-container') {
      const {y, width} = event.currentTarget.getBoundingClientRect()
      const {offsetWidth, offsetHeight = TOOLTIP_DEFAULT_HEIGHT} =
        event.currentTarget.querySelector('span') as HTMLElement

      if (offsetWidth && offsetWidth < width - 10) return setVisible(false)
      setPositionY(y - SAVE_TOOLTIP_ZONE - offsetHeight)
    }
  }

  const changePosition = (event: MouseEvent<HTMLElement>) => {
    if (placement === 'widget') {
      const bottomPadding = window.innerHeight - event.clientY
      const rightPadding = window.innerWidth - event.clientX

      const {offsetWidth = 0} = tooltipRef.current || {}
      const extraY = bottomPadding > 60 ? 22 : 0
      const extraX = rightPadding < offsetWidth + (isEditMode ? 270 : 10) ? -offsetWidth : 8

      setPositionY(event.clientY + extraY)
      setPositionX(event.clientX + extraX)
    }
    setVisible(true)
  }

  const changePositionDebounceRef = useRef(debounce(300, changePosition))

  const setMove = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (placement === 'widget') {
        setVisible(false)
        changePositionDebounceRef.current(event)
      }
    },
    [placement],
  )

  const closeTooltip = () => {
    changePositionDebounceRef.current.cancel()
    changePositionDebounceRef.current = debounce(300, changePosition)
    setVisible(false)
  }

  const tooltipElement = useMemo(() => {
    const $isHtml = placement === 'widget' && typeof content === 'string' && isHtml(content)

    return (
      <TooltipContent
        ref={tooltipRef}
        className={clsx('tooltip__inner', `tooltip__inner--${placement}`, {
          'tooltip__inner--active': visible,
        })}
        $positionY={positionY}
        $positionX={positionX}
        $isHtml={$isHtml}
        id={tooltipId}
        role='tooltip'
      >
        {$isHtml ? (
          <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content)}} />
        ) : (
          content
        )}
      </TooltipContent>
    )
  }, [visible, placement, content, positionY, positionX, tooltipId])

  if (disable) return children

  return (
    <TooltipContainer
      aria-describedby={tooltipId}
      as={rootCmp}
      className={`tooltip ${className || ''}`.trim()}
      onMouseEnter={changeVisibleTrue}
      onMouseLeave={closeTooltip}
      onMouseDown={closeTooltip}
      onMouseMove={setMove}
    >
      {children}

      {visible &&
        content !== '' &&
        (placement === 'widget' ? (
          <Portal name='toolbar-tooltip-widget'>{tooltipElement}</Portal>
        ) : (
          tooltipElement
        ))}
    </TooltipContainer>
  )
}
