import {ComponentProps, CSSProperties} from 'react'
import styled from 'styled-components'

import Button from '../Button'

type ThemeT = ComponentProps<typeof Button>['theme']

type PropTypes = {
  onConfirm?: () => void
  cancelText?: string
  cancelTheme?: ThemeT
  confirmText?: string
  confirmTheme?: ThemeT
  disabled?: boolean
  justifyContent?: CSSProperties['justifyContent']
  onCancel?: () => void
}

export default function ModalStockFooter({
  cancelText = 'Cancel',
  cancelTheme = 'default',
  confirmTheme = 'primary',
  confirmText = 'OK',
  disabled,
  justifyContent = 'space-between',
  onCancel,
  onConfirm,
}: PropTypes) {
  return (
    <StockFooter $jc={justifyContent}>
      {onCancel && (
        <Button
          type='button'
          data-testid='at-modal-cancel-button'
          theme={cancelTheme}
          onClick={onCancel}
        >
          {cancelText}
        </Button>
      )}
      <Button
        type='button'
        data-testid='at-modal-confirm-button'
        disabled={disabled}
        $m='0'
        theme={confirmTheme}
        onClick={onConfirm}
        $w='fit-content'
      >
        {confirmText}
      </Button>
    </StockFooter>
  )
}

const StockFooter = styled.footer<{$jc: PropTypes['justifyContent']}>`
  display: flex;
  justify-content: ${({$jc}) => $jc};
  margin-top: 30px;
`
