import {useCallback, useEffect, useMemo, useState} from 'react'
import {debounce} from 'throttle-debounce'
import {IWidgetGeometryWidthID} from 'apollo'

import {useReposition} from './useReposition'
import {
  usePageStyleRollback,
  useWidgetStyleRollback,
  useCopyPasteStyleRollback,
  TCopyPasteStyles,
  TOldWidgetStyles,
  TPageStyles,
} from './useStyle'
import {TStatus, useStatusRollback} from './useStatus'
import {TFormula, useFormulaRollback} from './useFormula'
import {TWidgetData, useDataRollback} from './useData'
import {TRemoveData, TRestoreData, TReturnData, useLiveWidgetRollback} from './useLive'
import {checkNewData, updateOldList} from './helpers'
import {TTitleValue, useTitleRollback} from './useTitle'
import {useConnectionRollback, TConnectionData} from './useConnection'
import {
  TPageTypeValue,
  TWidgetTypeValue,
  usePageTypeRollback,
  useWidgetTypeRollback,
} from './useType'
import {TFormat, TScrollBinding, useFormatRollback, useScrollBinding} from './useFormat'

export type TUndoRedoVariables =
  | IWidgetGeometryWidthID[]
  | TConnectionData
  | TCopyPasteStyles
  | TFormat
  | TFormula
  | TOldWidgetStyles[]
  | TPageStyles
  | TPageTypeValue
  | TRemoveData
  | TRestoreData
  | TReturnData
  | TScrollBinding
  | TStatus
  | TTitleValue
  | TWidgetData
  | TWidgetTypeValue

export type TUndoRedoNames =
  | 'createWidget'
  | 'deleteWidget'
  | 'moveWidgets'
  | 'restoreWidget'
  | 'setConnection'
  | 'setCopyPasteStyles'
  | 'setData'
  | 'setFormat'
  | 'setFormula'
  | 'setPageStyles'
  | 'setPageType'
  | 'setScrollBinding'
  | 'setStatus'
  | 'setTitle'
  | 'setWidgetType'
  | 'setWidgetsStyles'
  | 'updateOldList'

export interface IUndoRedoSetData {
  type: TUndoRedoNames
  variables: TUndoRedoVariables
}

const MAX_PAGE_HISTORY = 100
const SESSION_DELAY = 600
const WAITING_RUN = 60

export const useUndoRedo = (pageId: number, isEditMode: boolean) => {
  const [isUpdating, setIsUpdating] = useState(true)
  const [undo, updateUndo] = useState<IUndoRedoSetData[]>([])
  const [redo, updateRedo] = useState<IUndoRedoSetData[]>([])

  const [canUndo, canRedo] = [isEditMode && undo.length > 0, isEditMode && redo.length > 0]

  useEffect(() => {
    if (pageId) {
      const sessionHistory = sessionStorage.getItem(`undoRedoSession-${pageId}`)
      if (sessionHistory) {
        const {UNDO, REDO} = JSON.parse(sessionHistory)
        updateUndo(UNDO)
        updateRedo(REDO)
      } else {
        updateUndo([])
        updateRedo([])
      }
    }
  }, [pageId])

  const saveStorage = useMemo(
    () =>
      debounce(SESSION_DELAY, (UNDO, REDO, ID) => {
        sessionStorage.setItem(`undoRedoSession-${ID}`, JSON.stringify({UNDO, REDO}))
      }),
    [],
  )

  useEffect(() => {
    saveStorage(undo, redo, pageId)
  }, [undo, redo, saveStorage, pageId])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsUpdating(true)
    }, WAITING_RUN)

    return () => clearTimeout(timeout)
  }, [undo, redo])

  const setHistoryUpdate = useCallback(
    (type: TUndoRedoNames, variables: TUndoRedoVariables) => {
      if (!isEditMode) return
      updateUndo((u) => u.slice(Number(u.length >= MAX_PAGE_HISTORY)).concat({type, variables}))
      updateRedo([])
    },
    [isEditMode],
  )

  const runUpdateGeo = useReposition()
  const runUpdateWidgetStyle = useWidgetStyleRollback()
  const runUpdateCopyPasteStyle = useCopyPasteStyleRollback()
  const runUpdatePageStyle = usePageStyleRollback()
  const runUpdateStatus = useStatusRollback()
  const runUpdateFormat = useFormatRollback()
  const runScrollBinding = useScrollBinding()
  const runUpdateConnection = useConnectionRollback()
  const runUpdateFormula = useFormulaRollback()
  const runUpdateData = useDataRollback()
  const runUpdateTitle = useTitleRollback()
  const runUpdatePageType = usePageTypeRollback()
  const runUpdateWidgetType = useWidgetTypeRollback()
  const {runReturnWidget, runRemoveWidget} = useLiveWidgetRollback()

  const updateData = useCallback(
    async ({type, variables}: IUndoRedoSetData): Promise<IUndoRedoSetData | void> => {
      switch (type) {
        case 'moveWidgets': {
          const oldGeo = runUpdateGeo(variables as IWidgetGeometryWidthID[])
          return oldGeo && {type, variables: oldGeo}
        }
        case 'setWidgetsStyles': {
          const oldStyles = runUpdateWidgetStyle(variables as TOldWidgetStyles[])
          return oldStyles && {type, variables: oldStyles}
        }
        case 'setCopyPasteStyles': {
          const oldStyles = await runUpdateCopyPasteStyle(variables as TCopyPasteStyles)
          return oldStyles && {type, variables: oldStyles}
        }
        case 'setPageStyles': {
          const oldStyles = await runUpdatePageStyle(variables as TPageStyles)
          return oldStyles && {type, variables: oldStyles}
        }
        case 'setStatus': {
          const oldStatus = await runUpdateStatus(variables as TStatus)
          return oldStatus && {type, variables: oldStatus}
        }
        case 'setFormat': {
          const oldFormat = await runUpdateFormat(variables as TFormat)
          return oldFormat && {type, variables: oldFormat}
        }
        case 'setScrollBinding': {
          const oldScrollData = await runScrollBinding(variables as TScrollBinding)
          return oldScrollData && {type, variables: oldScrollData}
        }
        case 'setConnection': {
          const oldConnection = await runUpdateConnection(variables as TConnectionData)
          return oldConnection && {type, variables: oldConnection}
        }
        case 'setFormula': {
          const oldFormula = await runUpdateFormula(variables as TFormula)
          return oldFormula && {type, variables: oldFormula}
        }
        case 'setData': {
          const oldData = await runUpdateData(variables as TWidgetData)
          return oldData && {type, variables: oldData}
        }
        case 'setTitle': {
          const oldTitle = await runUpdateTitle(variables as TTitleValue)
          return oldTitle && {type, variables: oldTitle}
        }
        case 'setPageType': {
          const oldType = await runUpdatePageType(variables as TPageTypeValue)
          return oldType && {type, variables: oldType}
        }
        case 'setWidgetType': {
          const oldType = await runUpdateWidgetType(variables as TWidgetTypeValue)
          return oldType && {type, variables: oldType}
        }
        case 'deleteWidget': {
          const ids = await runReturnWidget(variables as TReturnData)
          return ids && {type: 'restoreWidget', variables: ids}
        }
        case 'createWidget': {
          const oldWidget = await runRemoveWidget(variables as number[])
          return oldWidget && {type: 'deleteWidget', variables: oldWidget}
        }
        default:
          return undefined
      }
    },
    [
      runUpdateFormula,
      runUpdateConnection,
      runUpdateFormat,
      runScrollBinding,
      runUpdateData,
      runUpdateTitle,
      runUpdatePageType,
      runUpdateWidgetType,
      runUpdateGeo,
      runReturnWidget,
      runRemoveWidget,
      runUpdateWidgetStyle,
      runUpdateCopyPasteStyle,
      runUpdatePageStyle,
      runUpdateStatus,
    ],
  )

  // back ctrl z
  const runUndo = useCallback(async () => {
    if (canUndo && isUpdating) {
      const currData = undo[undo.length - 1]

      if (!currData?.variables) return

      setIsUpdating(false)
      const newRedo: IUndoRedoSetData | void = await updateData(currData)

      if (newRedo) updateRedo((r) => [...r, checkNewData(newRedo)])
      updateUndo((u) => updateOldList(u.slice(0, -1), newRedo))
    }
  }, [canUndo, isUpdating, undo, updateData])

  // forward ctrl y -
  const runRedo = useCallback(async () => {
    if (canRedo && isUpdating) {
      const currData = redo[redo.length - 1]
      if (!currData?.variables) return

      setIsUpdating(false)
      const newUndo = await updateData(currData)

      if (newUndo) updateUndo((u) => [...u, checkNewData(newUndo)])
      updateRedo((r) => updateOldList(r.slice(0, -1), newUndo))
    }
  }, [canRedo, isUpdating, redo, updateData])

  const clearHistory = () => {
    updateUndo([])
    updateRedo([])
  }

  return {set: setHistoryUpdate, clearHistory, undo: runUndo, redo: runRedo, canUndo, canRedo}
}
