import {WidgetBgPatternType, WidgetStyles} from 'generated/graphql-operations'
import themes from 'theme'

export const FONT_FAMILIES = [
  'Arial',
  'Georgia',
  'Jura',
  'Montserrat',
  'Nunito',
  'Open Sans',
  'Roboto',
  'Tahoma',
  'Times New Roman',
] as const
export type TFont = typeof FONT_FAMILIES[number]

export const SERIF_FONTS: TFont[] = ['Georgia', 'Times New Roman']
export const SANS_SERIF_FONTS: TFont[] = ['Nunito', 'Roboto', 'Arial', 'Tahoma', 'Jura', 'Open Sans', 'Montserrat']

// Custom web fonts that we use in the app; loaded on demand only.
// If something is wrong with the url(s), just go to Google Fonts and re-generate them.
export const GOOGLE_FONTS = [
  'https://fonts.googleapis.com/css2?family=Jura:wght@400;700&display=swap',
  'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap',
  'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap',
  'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap',
]

export const BORDER_SIZES = ['1', '2', '4', '6']

export type TBorderPosition = 'top' | 'right' | 'bottom' | 'left'
// When `widget.styles.borderPosition` is `null`, we assume ALL borders are enabled (backward compatibility)
export const defaultBorderPositions: TBorderPosition[] = ['top', 'bottom', 'left', 'right']

export const FONT_SIZES = ['8', '10', '11', '14', '15', '16', '20', '24', '28', '36', '48', '72']
export const [FONT_MIN, FONT_MAX] = ['0', '1000']

export const BORDER_RADIUS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']

export const BG_PATTERNS: WidgetBgPatternType[] = ['HORIZONTAL', 'VERTICAL', 'DIAGONAL_FORWARD', 'DIAGONAL_BACKWARD']

export const GRID_SIZES = ['1', '2']

export const CHART = {
  chartAlignment: ['start', 'center', 'end', 'left', 'right', 'top', 'bottom'],
  defaultChartBarPercentage: 80,
  defaultChartColumnSize: 80,
  defaultChartConnectionLineColor: '#888888',
  defaultchartConnectionLineWidth: 1,
  defaultChartLegendPosition: 'top',
  defaultChartOffset: 4,
  defaultLineThickness: 2,
  chartBarPercentage: ['20', '40', '60', '80', '100', '200', '300', '400'],
  chartColumnSize: ['20', '40', '60', '80', '100'],
  chartLegendPosition: ['top', 'bottom', 'left', 'right'],
  chartOffset: ['0', '2', '4', '6', '8', '10'],
  chartPosition: ['start', 'center', 'end'],
  chartZeroLineWidth: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  defaultTransparency: 0.7,
  defaultZeroLineWidth: 2,
  labelRotationOpts: ['auto', ...Array.from({length: 7}, (_, i) => i * 15 + '')],
  lineType: ['smooth', 'straight'],
  lineStyle: ['solid', 'dashed', 'dotted'],
  lineThickness: ['0', '1', '2', '3', '4'],
  pointShape: ['circle', 'disc', 'square'],
  pointSize: ['3', '4', '5', '6', '8', '10'],
  types: ['Line', 'Area', 'Bar', 'Column', 'polarArea', 'radar'],
  transparencyOpts: Array.from({length: 6}, (_, i) => i * 20 + ''),
}

export const CELL_PADDING = 2

export const MAX_Y_AXIS_CHARACTERS = 14

export const chartStyleProps: (keyof WidgetStyles)[] = [
  'chartAlignment',
  'chartBarPercentage',
  'chartColumnSize',
  'chartConnectionLine',
  'chartConnectionLineColor',
  'chartConnectionLineWidth',
  'chartHasZeroLine',
  'chartLegend',
  'chartLegendPosition',
  'chartLineStyle',
  'chartLineThickness',
  'chartLineType',
  'chartMaxY',
  'chartMinY',
  'chartOffset',
  'chartPointShape',
  'chartPointSize',
  'chartPosition',
  'chartStacked',
  'chartSuggested',
  'chartTransparency',
  'chartType',
  'chartZeroLineColor',
  'chartZeroLineWidth',
  'displayLabels',
  'hideXAxis',
  'hideYAxis',
  'isCenteredDataset',
  'labelRotation',
  'showDataInRows',
  'showDataNameInFirstRow',
]

const PRESET_COLOURS_MAX = 13
export const presetColors = Object.entries(themes.main.colors)
  .slice(0, PRESET_COLOURS_MAX)
  .map(([title, color]) => ({title, color}))

export const SCROLLBAR_WIDTH = 6
