export const WIDGET_ERRORS = [
  '!CALCULATION',
  '!DIV0',
  '!RECURSION',
  '!REFERENCE',
  '!SYNTAX',
  '!VALUE',
  '!VALUETYPE',
] as const

export const ERROR_NAMES_TO_FRONTEND: {[key: string]: typeof WIDGET_ERRORS[number]} = {
  '[object Object]': '!CALCULATION',
  NaN: '!VALUE',
}

export const ERROR_NAMES_TO_BACKEND: {[key in typeof WIDGET_ERRORS[number] | string]?: string} = {
  '!CALCULATION': '[object Object]',
  '!VALUE': 'NaN',
}
