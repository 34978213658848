import {makeVar} from '@apollo/client'
import {IPageSize, IPopupPage} from 'static/constants'

export type ActivePageBlockType =
  | 'codeEditor'
  | 'main'
  | 'manageUsers'
  | 'modal'
  | 'navigation'
  | 'shareApp'
  | 'styles'

export const setActivePageIdVar = makeVar<number>(0)
export const setActivePageSizeVar = makeVar<IPageSize>({width: 0, height: 0})
export const setActivePageBlock = makeVar<ActivePageBlockType>('main')
export const setPopupPageDetails = makeVar<IPopupPage | null>(null)

export const resetActivePage = () => {
  setActivePageIdVar(0)
  setActivePageBlock('main')
}
