// Like in Bootstrap, let's favour the mobile-first approach.
const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
}

export default breakpoints
