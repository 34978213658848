import {ForwardedRef, forwardRef, memo, ReactNode, useState} from 'react'
import clsx from 'clsx'

import {omitProps} from 'utils'
import {RenderProps, TreeItem} from './config'

import * as S from './styles'
import {ReactComponent as ChevronIcon} from 'static/images/svgs/arrowdownicon.svg'

type PropTypes = {
  items: TreeItem[]
  children?: (props: RenderProps) => ReactNode
  initialOpen?: boolean | Array<TreeItem['id']>
  nestLevel?: number
}

const TreeInner = (
  {items, nestLevel, children, initialOpen}: PropTypes,
  ref?: ForwardedRef<HTMLUListElement>,
) => {
  const renderItems =
    items[0] && 'sortIndex' in items[0]
      ? items.sort((a, b) => (a?.sortIndex ?? 0) - (b?.sortIndex ?? 0))
      : items

  return (
    <S.List ref={ref}>
      {renderItems.map((item, i) => (
        <TreeNode
          key={`tree with ${i} in ${item.id} from ${item.parentId || ''}`}
          item={item}
          nestLevel={nestLevel ?? 0}
          defaultExpanded={initialOpen}
        >
          {(renderProps) => children?.(renderProps)}
        </TreeNode>
      ))}
    </S.List>
  )
}

const Tree = forwardRef<HTMLUListElement, PropTypes>(TreeInner)

export default memo(Tree)

type NodeProps = {
  item: TreeItem
  nestLevel: number
  children: (props: RenderProps) => ReactNode
  defaultExpanded?: PropTypes['initialOpen']
}

const TreeNode = ({item, nestLevel, children, defaultExpanded}: NodeProps) => {
  const hasDescendants = item.children.length > 0
  const [expanded, setExpanded] = useState(
    hasDescendants &&
      (Array.isArray(defaultExpanded)
        ? defaultExpanded.includes(item.id)
        : defaultExpanded ?? false),
  )

  const onToggle = (status?: boolean) => setExpanded((v) => status ?? !v)

  const passedProps: RenderProps = {
    expanded,
    hasChildren: hasDescendants,
    onToggle,
    nestLevel,
    ...omitProps(item, ['children', 'sortIndex']),
  }

  return (
    <S.ListItem>
      {hasDescendants && (
        <S.Button type='button' onClick={() => onToggle()}>
          <ChevronIcon aria-hidden='true' className={clsx({expanded})} />
          <span className='visually-hidden'>{expanded ? 'Collapse' : 'Expand'}</span>
        </S.Button>
      )}
      {children(passedProps)}
      {hasDescendants && expanded && (
        <Tree items={item.children} nestLevel={nestLevel + 1} initialOpen={defaultExpanded}>
          {(nestedRenderProps) => children(nestedRenderProps)}
        </Tree>
      )}
    </S.ListItem>
  )
}
