import {useCallback} from 'react'
import {toast} from 'react-toastify'

import {
  PageWidget,
  setActiveCellTableVar,
  setMultiSelectCellsVar,
  setSelectedWidgetsVar,
  TActiveCell,
  TCellCoordinate,
} from 'apollo'
import {useWidgets} from 'repository/graphql'
import {
  useSetWidgetDataMutation,
  useSetWidgetsStylesMutation,
  Widget,
  WidgetStyles,
} from 'generated/graphql-operations'
import {ContainerId} from 'components/Toast/config'
import {setLoadingCursor} from 'templates/Settings/Widget/Styles/utils'

export type TWidgetData = {
  id: Widget['id']
  type?: Widget['type']
  oldValue: Widget['data']['value'] | PageWidget['data']['selected']
  newValue: Widget['data']['value'] | PageWidget['data']['selected']
  cellSizes?: Pick<WidgetStyles, 'columnWidth' | 'rowHeight'>
  activeCell?: TActiveCell
  multiSelectCells?: TCellCoordinate[]
}

export const useDataRollback = () => {
  const {setMenuWidgetSelectedData} = useWidgets()
  const [setWidgetData] = useSetWidgetDataMutation()
  const [setWidgetsStyles] = useSetWidgetsStylesMutation()

  return useCallback(
    async (value: TWidgetData): Promise<TWidgetData | void> => {
      try {
        const {id, oldValue, newValue, cellSizes, type} = value

        if (type === 'MENU') {
          setLoadingCursor(true, 'setMenuWidgetSelectedData')
          const {data} = await setMenuWidgetSelectedData({
            variables: {id, selected: oldValue as number[]},
          }).finally(()=>{
            setLoadingCursor(false, 'setMenuWidgetSelectedData')
          })

          if (!data?.setMenuWidgetSelectedData) return
        } else {
          setLoadingCursor(true, 'setWidgetData')
          const {data} = await setWidgetData({
            variables: {id, data: oldValue},
          }).finally(()=> {
            setLoadingCursor(false, 'setWidgetData')
          })

          if (cellSizes) {
            await setWidgetsStyles({variables: {ids: [id], newStyles: cellSizes}})

            const {activeCell, multiSelectCells} = value
            if (activeCell) setActiveCellTableVar(activeCell)
            if (multiSelectCells) setMultiSelectCellsVar(multiSelectCells)
          }
          if (!data?.setWidgetData) return
        }

        setSelectedWidgetsVar([id])
        return {id, type, newValue: oldValue, oldValue: newValue, cellSizes}
      } catch (e) {
        toast.warning('Lost undo-redo of data value', {containerId: ContainerId.ROOT})
      }
    },
    [setWidgetData, setMenuWidgetSelectedData, setWidgetsStyles],
  )
}
