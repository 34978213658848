import {useContext} from 'react'
import {toast} from 'react-toastify'
import {useReactiveVar} from '@apollo/client'

import {ContainerId} from 'components/Toast/config'
import {isInstanceOf} from 'utils'
import {SaveUndoRedoContext} from 'repository/context'
import {
  cache,
  deselectAllWidgets,
  setActivePageIdVar,
  setAppIdVar,
  setAutoSaveStatusVar,
  setPopupPageDetails,
  setSelectedWidgetsVar,
} from 'apollo'

import {
  ActionType,
  CloseCommandResult,
  CommandResultFragment,
  PostMessageCommandResult,
  Maybe,
  NewWidgetFieldsFragment,
  OpenCommandResult,
  OpenWebPageCommandResult,
  Page,
  PageInfoFragmentDoc,
  PagesDocument,
  Widget,
  WidgetFieldsFragment,
  WidgetFieldsFragmentDoc,
  WidgetsByPageIdDocument,
  useCopyToClipboardMutation,
  useCopyWidgetsMutation,
  useCopyWidgetsStylesMutation,
  useCreateWidgetMutation,
  useDeleteWidgetsMutation,
  useExecuteActionMutation,
  useMoveWidgetsMutation,
  usePasteFromClipboardMutation,
  useSaveActionMutation,
  useSetMenuWidgetSelectedDataMutation,
} from 'generated/graphql-operations'

export const writeWidgetsToCache = (pageId: Maybe<number>, newWidgets: WidgetFieldsFragment[]) => {
  if (pageId) {
    cache.updateQuery(
      {
        query: WidgetsByPageIdDocument,
        variables: {pageId, isFirstOpening: false},
      },
      (cachedValue) => {
        return {
          getWidgetsByPageId: {
            ...cachedValue.getWidgetsByPageId,
            affectedWidgets: [...cachedValue.getWidgetsByPageId.affectedWidgets, ...newWidgets],
          },
        }
      },
    )
  }
}

const errorsAreIgnored = ['SET_VALID_DATASOURCE_CREDENTIALS_ERROR', 'REQUEST_FE_ACTION_ANSWER']

export const handleAffectedContent = (commands: CommandResultFragment[]) => {
  for (const {command, error, ...rest} of commands) {
    if (error) {
      toast.error(error.detailed, {containerId: ContainerId.ROOT, toastId: error.label})
      break
    }

    if (command === 'OPENWEBPAGE' && isInstanceOf<OpenWebPageCommandResult>(rest, 'url')) {
      rest.url && window.open(rest.url, '_blank')
      continue
    }

    if (command === 'POSTMESSAGE' && isInstanceOf<PostMessageCommandResult>(rest, 'message')) {
      window.parent.postMessage(rest.message, '*')
      continue
    }

    if (command === 'OPEN' && isInstanceOf<OpenCommandResult>(rest, 'pageId') && rest.pageId) {
      const {pageId, isCell, isPopup, widgetId: ID} = rest

      if (isPopup) {
        setPopupPageDetails({pageId, widgetId: ID, isCell: !!isCell, status: 'openPopup'})
      } else {
        setPopupPageDetails({pageId, status: 'openPage'})
      }
    }

    if (command === 'CLOSE' && isInstanceOf<CloseCommandResult>(rest, 'pageId') && rest.pageId) {
      setPopupPageDetails({status: 'close', pageId: rest.pageId})
    }
  }
}

// TODO: please don't add any global state here, except for page or application data.
// Use returned data or `.then()` in your component.
// Ultimately, we must get rid of this mega hook, since its consumers will usually need
// only 1-2 functions, whereas we're polluting the memory with 10+ functions EACH TIME we call it.
export const useWidgets = () => {
  const appId = useReactiveVar(setAppIdVar)
  const activePageId = useReactiveVar(setActivePageIdVar)

  const saveHistory = useContext(SaveUndoRedoContext)

  const _addNewWidgets = (affectedWidgets: NewWidgetFieldsFragment[]) => {
    if (affectedWidgets.length) {
      const newWidgets = affectedWidgets.filter((w) => w.isNew && w.id)
      const selectedIds = newWidgets.map((w) => w.id)

      writeWidgetsToCache(activePageId, newWidgets)
      setSelectedWidgetsVar(selectedIds)
      saveHistory('createWidget', selectedIds)
    }
  }

  const [createWidget] = useCreateWidgetMutation({
    refetchQueries: [{query: PagesDocument, variables: {appId}}],
    onCompleted: (r) => _addNewWidgets(r.createWidget.affectedWidgets),
    onError: () => null,
  })

  const [copyWidgets] = useCopyWidgetsMutation({
    onCompleted: (r) => _addNewWidgets(r.copyWidgets.affectedWidgets),
    onError: () => null,
  })

  const [copyToClipboard] = useCopyToClipboardMutation({
    onError: () => null,
  })

  const [pasteFromClipboard] = usePasteFromClipboardMutation({
    onCompleted: (r) => _addNewWidgets(r.pasteFromClipboard.affectedWidgets),
    onError: () => null,
  })

  const [moveWidgets] = useMoveWidgetsMutation({
    onCompleted: () => {
      setTimeout(() => {
        setAutoSaveStatusVar(false)
      }, 500)
    },
    onError: () => {
      setAutoSaveStatusVar(false)
    },
  })

  const [deleteWidgets] = useDeleteWidgetsMutation({
    onCompleted(r) {
      deselectAllWidgets()
      r.deleteWidgets.deletedWidgetIds.forEach((id) => cache.evict({id: `Widget:${id}`}))
      cache.gc()
    },
    onError: () =>
      toast.error('Error deleting widget(s)', {
        containerId: ContainerId.ROOT,
        toastId: 'delete-widget-error',
      }),
  })

  const [addWidgetAction] = useSaveActionMutation({
    onCompleted({saveAction}) {
      handleAffectedContent(saveAction.commandResults)
    },
    onError: () => null,
  })

  const [runExecAction] = useExecuteActionMutation({
    onCompleted(data) {
      const {executeAction} = data || {}
      if (executeAction) handleAffectedContent(executeAction.commandResults)
    },
  })

  const [copyWidgetsStyles] = useCopyWidgetsStylesMutation({
    onError: () =>
      toast.error('This style/format change could not be applied', {
        containerId: ContainerId.ROOT,
        toastId: 'copy-widgets-styles-error',
      }),
  })

  const [setMenuWidgetOption] = useSetMenuWidgetSelectedDataMutation({
    onCompleted: ({setMenuWidgetSelectedData}) => {
      handleAffectedContent(setMenuWidgetSelectedData.commandResults)
    },
    onError: (error) => {
      if (errorsAreIgnored.includes(error.message)) return error

      toast.error('Failed to apply item selection', {
        containerId: ContainerId.ROOT,
        toastId: 'set-menu-widget-selected-error',
      })
    },
  })

  return {
    addWidgetAction,
    affectedContentHandler: handleAffectedContent,
    copyToClipboard,
    copyWidgets,
    copyWidgetsStyles,
    createWidget,
    deleteWidgets,
    executeAction: runExecAction,
    moveWidgets,
    pasteFromClipboard,
    setMenuWidgetSelectedData: setMenuWidgetOption,
  }
}

// Check if the widget/page has anything assigned to user's action (click, input, etc.).
// In other words, if the formula editor has any code linked to the action, or it's totally empty.
export const hasAction = (
  type: NonNullable<Page['__typename']> | NonNullable<Widget['__typename']>,
  id: number,
  actionType: ActionType,
): boolean => {
  // if (isTestEnv) return true // todo: uncomment when ready to fix the unit tests
  const frag = type === 'Widget' ? WidgetFieldsFragmentDoc : PageInfoFragmentDoc
  const cacheEntry: Maybe<Pick<Widget, 'actions'> | Pick<Page, 'actions'>> = cache.readFragment({
    id: `${type}:${id}`,
    fragment: frag,
    fragmentName: type === 'Widget' ? 'WidgetFields' : 'PageInfo',
  })

  return cacheEntry?.actions.some((a) => a.type === actionType) ?? false
}
