import {InMemoryCache} from '@apollo/client'

import {widgetCache} from './widget.cache'

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        ...widgetCache,
        // https://www.apollographql.com/docs/react/caching/advanced-topics#cache-redirects
        getWidgetById: {
          read(_, { args, toReference }) {
            if (args?.widgetId) {
              return toReference({
                __typename: 'Widget',
                id: args.widgetId,
              })
            }
          },
        },
      },
    },
  },
  possibleTypes: {
    WidgetDataUnionType: ['WidgetData', 'ButtonWidgetData', 'MenuWidgetData', 'TableWidgetData'],
  },
})
