import {useMemo} from 'react'
import {useReactiveVar} from '@apollo/client'

import {IWidgetGeometryIncludeKeyID, setWidgetsGeometryVarWithId} from 'apollo'
import {WidgetGeometry} from 'generated/graphql-operations'

export interface IGeometry {
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface IPositionData {
  selectY: number;
  selectX: number;
  target: EventTarget;
}

export const useSelectingWidgets = () => {
  const widgetsGeometry: IWidgetGeometryIncludeKeyID = useReactiveVar(setWidgetsGeometryVarWithId);
  return useMemo(() => setSelectedArray(widgetsGeometry), [widgetsGeometry]);
};

export const useSelectAllWidgets = () => {
  const widgetsGeometry: IWidgetGeometryIncludeKeyID = useReactiveVar(setWidgetsGeometryVarWithId);
  return useMemo(() => {
    const allWidgetIds = Object.keys(widgetsGeometry).map((v) => +v);
    return sortByDirection(allWidgetIds, widgetsGeometry, false, false);
  }, [widgetsGeometry]);
};

// function that check - widget is inside frame or not
const getCoveredWidget =
  (newGeometry: IGeometry) =>
  (geo: WidgetGeometry): boolean =>
    newGeometry.top <= geo.y + geo.height &&
    newGeometry.top <= geo.y &&
    newGeometry.top + newGeometry.height >= geo.y + geo.height &&
    newGeometry.left <= geo.x + geo.width &&
    newGeometry.left <= geo.x &&
    newGeometry.left + newGeometry.width >= geo.x + geo.width;

// function for checking position of widget in selection list
// have top left right bottom direction
const setSelectedArray =
  (widgetsGeometry: IWidgetGeometryIncludeKeyID) =>
  (newGeometry: IGeometry, positionData: IPositionData) => {
    const selectedIds: number[] = [];

    for (const key in widgetsGeometry) {
      // verify position of widgets and frame, if widget is inside frame return true
      // and create array of selected widgets
      if (getCoveredWidget(newGeometry)(widgetsGeometry[key])) selectedIds.push(+key);
    }

    if (selectedIds.length > 1) {
      const {selectX, selectY} = positionData;
      const {top, height, width, left} = newGeometry;

      const isTop = top + height === selectY;
      const isLeft = left + width === selectX;

      sortByDirection(selectedIds, widgetsGeometry, isTop, isLeft);
    }
    return selectedIds;
  };

export const sortByDirection = (
  selectedIds: number[],
  widgetsGeometry: IWidgetGeometryIncludeKeyID,
  isTop: boolean,
  isLeft: boolean,
) =>
  selectedIds.sort((idA: number, idB: number) => {
    const geoA = widgetsGeometry[idA];
    const geoB = widgetsGeometry[idB];

    if (isTop && isLeft) {
      const posA = geoA.x + geoA.y;
      const posB = geoB.x + geoB.y;
      return posA < posB ? 1 : -1;
    }
    if (!isTop && !isLeft) {
      const posA = geoA.width + geoA.x + geoA.height + geoA.y;
      const posB = geoB.width + geoB.x + geoB.height + geoB.y;
      return posA > posB ? 1 : -1;
    }
    if (isTop && !isLeft) {
      const posA = geoA.width + geoA.x - geoA.y;
      const posB = geoB.width + geoB.x - geoB.y;
      return posA > posB ? 1 : -1;
    }
    if (!isTop && isLeft) {
      const posA = geoA.height + geoA.y - geoA.x;
      const posB = geoB.height + geoB.y - geoB.x;
      return posA > posB ? 1 : -1;
    }

    return 0;
  });
