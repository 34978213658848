import {useCallback} from 'react'
import {toast} from 'react-toastify'

import {usePages} from 'repository/graphql'
import {Widget, Page, useRenameWidgetMutation} from 'generated/graphql-operations'
import {deselectAllWidgets, setSelectedWidgetsVar} from 'apollo'
import {ContainerId} from 'components/Toast/config'

type TData = {title: Widget['title'] | Page['title']; displayName?: Page['displayName']}

export type TTitleValue = {
  id: Widget['id'] | Page['id']
  type: 'PAGE' | 'WIDGET'
  newData: TData
  oldData: TData
}

export const useTitleRollback = () => {
  const [renameWidget] = useRenameWidgetMutation()
  const {renamePage} = usePages()

  return useCallback(
    async (value: TTitleValue): Promise<TTitleValue | void> => {
      try {
        const {id, newData, oldData, type} = value

        if (type === 'PAGE') {
          await renamePage({variables: {id, ...oldData}})
          deselectAllWidgets()
        } else {
          const {data} = await renameWidget({variables: {id, newTitle: oldData.title}})
          if (!data?.renameWidget) return
          setSelectedWidgetsVar([id])
        }

        return {id, type, newData: oldData, oldData: newData}
      } catch (e) {
        toast.warning('Lost undo-redo of page or widget name', {containerId: ContainerId.ROOT})
      }
    },
    [renamePage, renameWidget],
  )
}
