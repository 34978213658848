import {ReportHandler} from 'web-vitals'

export default async function reportWebVitals(cb?: ReportHandler) {
  if (cb) {
    const {onCLS, onFID, onFCP, onLCP, onTTFB} = await import('web-vitals')
    onCLS(cb)
    onFID(cb)
    onFCP(cb)
    onLCP(cb)
    onTTFB(cb)
  }
}
