import {Suspense} from 'react'
import {ApolloProvider, useReactiveVar} from '@apollo/client'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import {loadDevMessages, loadErrorMessages} from '@apollo/client/dev'
import {StyleSheetManager, ThemeProvider} from 'styled-components'
import {ToastContainer} from 'react-toastify'

import 'react-toastify/dist/ReactToastify.min.css'
import './styles/global.css'

import {client, setIsAuthenticatedVar} from 'apollo'
import {ContainerId, defaultToastProps} from './components/Toast/config'
import {isDevEnv} from './utils'
import themes from './theme'

import AuthenticatedViews from './scenes/AuthenticatedViews'
import {Loader} from 'components'
import Maintenance from './scenes/Maintenance'
import StyledToastContainer from './components/Toast'
import TopmostErrorBoundary from 'components/ErrorBoundary/ErrorBoundaryWithUI'
import UnauthenticatedViews from './scenes/UnauthenticatedViews'
import {registerTabDuplicationEvent} from 'utils/registerTabDuplicationEvent'

// This will show verbose Apollo error messages in dev build only.
// https://www.apollographql.com/docs/react/errors/
if (isDevEnv) {
  loadDevMessages()
  loadErrorMessages()
}

registerTabDuplicationEvent(() => {
  sessionStorage.setItem('sessionId', Math.random().toString())
})

export default function App() {
  const isAuthenticated = useReactiveVar(setIsAuthenticatedVar)

  const routes = Number(process.env.REACT_APP_IS_UPDATING) ? (
    <Route element={<Maintenance />} />
  ) : isAuthenticated ? (
    <AuthenticatedViews />
  ) : (
    <UnauthenticatedViews />
  )

  return (
    <ApolloProvider client={client}>
      <StyleSheetManager>
        <ThemeProvider theme={themes.main}>
          <StyledToastContainer>
            <TopmostErrorBoundary>
              <Router>
                <Suspense fallback={<Loader $isInside />}>{routes}</Suspense>
              </Router>
            </TopmostErrorBoundary>
            <ToastContainer {...defaultToastProps} containerId={ContainerId.ROOT} />
          </StyledToastContainer>
        </ThemeProvider>
      </StyleSheetManager>
    </ApolloProvider>
  )
}
