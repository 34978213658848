import {CSSProperties, ReactNode} from 'react'
import styled from 'styled-components'
import {useDragLayer, XYCoord} from 'react-dnd'

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
): CSSProperties {
  if (!initialOffset || !currentOffset) {
    return { display: 'none' }
  }

  const {x, y} = currentOffset
  return {
    transform: `translate(${x}px, ${y}px)`,
  }
}

type PropTypes = {
  children: (draggedItem: Record<string, unknown>) => ReactNode
  dragItemType: string
}

// This is a wrapper around a component that you wish to use instead of the default browser drag preview.
// You will also need to explicitly disable it: `src/templates/PageList/DraggablePageItem.tsx`.
// This component will be rendered only when your item is being dragged.
// https://codesandbox.io/s/github/react-dnd/react-dnd/tree/gh-pages/examples_ts/02-drag-around/custom-drag-layer
export default function CustomDragPreview({children, dragItemType}: PropTypes) {
  const {item, itemType, isDragging, initialOffset, currentOffset} = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  if (itemType === dragItemType && isDragging) {
    return (
      <OuterContainer>
        <div style={getItemStyles(initialOffset, currentOffset)}>
          {children(item)}
        </div>
      </OuterContainer>
    )
  }

  return null
}

const OuterContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  pointer-events: none;
`
