export const connectionOAuthInfo = {
  SMARTBOOKS_AI: {
    cookie_name: 'smartbooks_OAuth_expire',
    url: `${process.env.REACT_APP_URL}/smartbooks-oauth/auth`,
    logout: `${process.env.REACT_APP_URL}/smartbooks-oauth/logout`,
  },
  GOOGLE_SHEETS: {
    cookie_name: 'google_OAuth_expire',
    url: `${process.env.REACT_APP_URL}/google-oauth/auth`,
    logout: `${process.env.REACT_APP_URL}/google-oauth/logout`,
  },
  SSAS: {
    cookie_name: 'azure_expire_date',
    url: `${process.env.REACT_APP_URL}/azure-oauth/auth`,
    logout: `${process.env.REACT_APP_URL}/azure-oauth/logout`,
  },
}

export type ConnectionOAuthType = keyof typeof connectionOAuthInfo

export const connectionUserInfo = {
  ODATA: {
    cookie_name: 'odata_user_expire_date',
    url: `${process.env.REACT_APP_URL}/odata-user/auth`,
    logout: `${process.env.REACT_APP_URL}/odata-user/logout`,
  },
  SSAS: {
    cookie_name: 'azure_user_expire_date',
    url: `${process.env.REACT_APP_URL}/azure-user/auth`,
    logout: `${process.env.REACT_APP_URL}/azure-user/logout`,
  },
  ODBC: {
    cookie_name: 'odbc_user_expire_date',
    url: `${process.env.REACT_APP_URL}/odbc/auth`,
    logout: `${process.env.REACT_APP_URL}/odbc/logout`,
  },
  SAP_MDX: {
    cookie_name: 'sap_user_expire_date',
    url: `${process.env.REACT_APP_URL}/sap/auth`,
    logout: `${process.env.REACT_APP_URL}/sap/logout`,
  },
}

export type ConnectionUserType = keyof typeof connectionUserInfo
