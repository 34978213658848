import {ValueType} from '../apollo'
import {WidgetFormat} from 'generated/graphql-operations'

// Returns the date format type for provided locale, e.g.
// DD/MM/YYYY for en-GB, MM/DD/YYYY for en-US, DD.MM.YYYY for ru-RU or de-DE etc.
// See also: https://www.ibm.com/docs/en/db2/11.5?topic=considerations-date-time-formats-by-territory-code
function getLongDateFormat(lang = 'default'): string {
  const formatParts = new Intl.DateTimeFormat(lang).formatToParts(new Date())
  return formatParts
    .map((obj) => {
      switch (obj.type) {
        case 'day':
          return 'DD'
        case 'month':
          return 'MM'
        case 'year':
          return 'YYYY'
        default:
          return obj.value
      }
    })
    .join('')
}

function userDateToISOString(dateStr: string, locale = navigator.language): string {
  const hasTimestamp = dateStr.includes('T')
  const [date, time] = hasTimestamp ? dateStr.split('T') : [dateStr]
  const dateParts = date.split(/\D/)

  if (dateParts[0].length === 4 && dateParts[1].length === 2 && dateParts[2].length === 2) {
    return dateParts.join('-') + (hasTimestamp ? `T${time}` : '')
  }

  const userFormat = getLongDateFormat(locale)
  const isDayFirst = userFormat.indexOf('DD') < userFormat.indexOf('MM')
  const day = (isDayFirst ? dateParts[0] : dateParts[1]).padStart(2, '0')
  const month = (isDayFirst ? dateParts[1] : dateParts[0]).padStart(2, '0')
  const year = dateParts[2]

  return `${year}-${month}-${day}`
}

const [MAX_TIME, MIN_TIME] = [253400565600000, -2209082524000]
// const DATE_RE = /^(\d{1,4})[-./_](\d{1,2})[-./_](\d{2,4})$/ // allows more lenient date formatting
const ISO_TS_RE = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z$/
// Will match an ISO-like string (YYYY-MM-DD), with or without a time stamp:
const ISO_DATE_RE = /^(\d{4})-(\d{2})-(\d{2})(T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z)?$/

// NOT 100% ACCURATE, but good enough for our purposes
export const isLikelyDate = (string: string): boolean => {
  if (ISO_DATE_RE.test(string)) {
    const dateISO = userDateToISOString(string)
    const [, m, d] = dateISO.split('-')
    if (+m > 12 || +d > 31) return false
    const time = Date.parse(dateISO)
    return !Number.isNaN(time) && time < MAX_TIME && time > MIN_TIME
  }

  return false
}

export const isDateISOString = (v: ValueType) => typeof v === 'string' && ISO_TS_RE.test(v)

function isoDateWithoutTimeZone(date: Date): string {
  const timestamp = date.getTime() - date.getTimezoneOffset() * 60000
  return new Date(timestamp).toISOString()
}

// Attempt to convert a loosely formatted date string to a UTC datetime
export function userDateToUTC(
  dateStr: string,
  format: WidgetFormat,
  locale = navigator.language,
): string {
  try {
    const date = new Date(userDateToISOString(dateStr, locale))
    if (format.showLocalTime) return date.toISOString()
    return isoDateWithoutTimeZone(date)
  } catch (e) {
    return dateStr
  }
}

export function userTimeToUTC(
  dateStr: string,
  prevISO: ValueType,
  format: WidgetFormat,
): string {
  try {
    if (typeof prevISO === 'string') {
      const dateParts = prevISO.split('T')[0].split('-')
      const year = parseInt(dateParts[0], 10)
      const month = parseInt(dateParts[1], 10) - 1
      const day = parseInt(dateParts[2], 10)
      const newDate = new Date(year, month, day, +dateStr.slice(0, 2), +dateStr.slice(3, 5))

      if (format.showLocalTime) return newDate.toISOString()
      return isoDateWithoutTimeZone(newDate)
    }

    return dateStr
  } catch (e) {
    return dateStr
  }
}
