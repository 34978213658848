import {ChangeEvent} from 'react'
import {Check, X} from '@phosphor-icons/react'
import styled from 'styled-components'

interface IProps {
  name: string
  isChecked: boolean
  isDisabled?: boolean
  onChange: (arg0: boolean) => void
}

const SwitchBox = ({name, isChecked, isDisabled = false, onChange}: IProps) => (
  <>
    <Input
      type='checkbox'
      className='visually-hidden'
      name={name}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
      id={name}
      checked={isChecked}
      disabled={isDisabled}
    />
    <Label htmlFor={name}>
      <span>
        {isChecked ? (
          <Check color='white' size={10} weight='bold' aria-hidden='true' />
        ) : (
          <X size={10} weight='bold' aria-hidden='true' />
        )}
      </span>
    </Label>
  </>
)

const Input = styled.input`
  &:focus-visible + label {
    box-shadow: 0 0 0 1px ${({theme}) => theme.colors.primary};
  }

  &:checked + label {
    background-color: rgba(37, 190, 225, 0.4);

    span {
      background-color: ${({theme}) => theme.colors.primary};
      transform: translateX(12px);
    }
  }
`

const Label = styled.label`
  width: 30px;
  height: 18px;
  border-radius: 2em;
  background-color: ${({theme}) => theme.colors.light};
  cursor: pointer;
  transition: background-color 0.2s;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    color: ${({theme}) => theme.colors.subhead};
    background-color: ${({theme}) => theme.colors.mainBackground};
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
    transition: 0.2s;
  }
`

export default SwitchBox
