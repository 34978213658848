import {WidgetType} from 'generated/graphql-operations'

import {ReactComponent as WButtonIcon} from 'static/images/svgs/wbuttonicon.svg'
import {ReactComponent as WCellIcon} from 'static/images/svgs/wcellicon.svg'
import {ReactComponent as WChartBarIcon} from 'static/images/svgs/wchartbaricon.svg'
import {ReactComponent as WImageSquareIcon} from 'static/images/svgs/wimagesquareicon.svg'
import {ReactComponent as WListIcon} from 'static/images/svgs/wlisticon.svg'
import {ReactComponent as WTableIcon} from 'static/images/svgs/wtableicon.svg'
import {ReactComponent as WTextIcon} from 'static/images/svgs/wtexticon.svg'

export type WidgetKeyType = Lowercase<WidgetType>

interface ISizes {
  height: number
  width: number
}

type WidgetSizes = {
  [x in WidgetKeyType]: ISizes
}

export interface IWidgetList {
  icon: JSX.Element
  title: Capitalize<WidgetKeyType>
}

export const widgetList: IWidgetList[] = [
  {icon: <WTableIcon />, title: 'Table'},
  {icon: <WCellIcon />, title: 'Cell'},
  {icon: <WTextIcon />, title: 'Text'},
  {icon: <WButtonIcon />, title: 'Button'},
  {icon: <WListIcon />, title: 'Menu'},
  {icon: <WChartBarIcon />, title: 'Chart'},
  {icon: <WImageSquareIcon />, title: 'Image'},
]

export const widgetSizes: WidgetSizes = {
  button: { width: 90, height: 35 },
  cell: { width: 90, height: 35 },
  chart: { width: 300, height: 150 },
  image: { width: 150, height: 100 },
  menu: { width: 120, height: 35 },
  table: { width: 150, height: 70 },
  text: { width: 570, height: 180 },
}

export const minWidgetSizes: ISizes = {
  width: 1,
  height: 1,
}

export const stopDistance: {right: number; bottom: number} = {
  right: 4,
  bottom: 8,
}
