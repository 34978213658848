export const functionNames = [
  'ABS',
  'ACTIONROW',
  'ACTIONCOLUMN',
  'ACTIONCONTENT',
  'ADDUSER',
  'AGGREGATE',
  'AND',
  'APPLIST',
  'APPSHARELIST',
  'CALLURL',
  'CELL',
  'CELLREF',
  'CHAR',
  'CLEAR',
  'CLICK',
  'CLOSE',
  'CODE',
  'COLUMN',
  'COLUMNS',
  'COMBINE',
  'CONVERT',
  'COUNT',
  'CREATEDATE',
  'CREATEMEMBER',
  'CSVTORANGE',
  'CUMULATE',
  'DATE',
  'DATETOTEXT',
  'DAY',
  'DELETEFILE',
  'DIFF',
  'DOWNLOAD',
  'DOWNLOADFILE',
  'EMPTY',
  'EXECUTE',
  'EXCELCREATE',
  'EXP',
  'UPLOADFILE',
  'FILTER',
  'FIND',
  'GETDATA',
  'GETDBCOLUMNNAMES',
  'GETFILELIST',
  'GETSELECTION',
  'GETSTATEMENT',
  'GETTIMEZONEOFFSET',
  'GETTYPE',
  'GETURLPARAM',
  'HASH',
  'HIDDENTEXT',
  'HOUR',
  'HTMLTOJSONTEXT',
  'IF',
  'INSERTRANGE',
  'INTERSECTION',
  'ISEMPTY',
  'ISUSERACTION',
  'LEFT',
  'LEN',
  'LEVEL',
  'LN',
  'LOADWIDGETCONTENT',
  'LOG',
  'LOOKUP',
  'LOOP',
  'LOWER',
  'MAX',
  'MESSAGE',
  'MID',
  'MILLISECOND',
  'MIN',
  'MINUTE',
  'MOD',
  'MONTH',
  'MROUND',
  'NAME',
  'NOT',
  'NOW',
  'NUMBER',
  'OPEN',
  'OPENWEBPAGE',
  'OR',
  'QUESTION',
  'PAGE',
  'PI',
  'PDFCREATE',
  'POSTMESSAGE',
  'RAND',
  'RANGE',
  'RANGETOCSV',
  'RANGETOHIERARCHY',
  'REMOVEUSER',
  'RIGHT',
  'ROUND',
  'ROW',
  'ROWS',
  'SCANCODE',
  'SECOND',
  'SENDEMAIL',
  'SEQUENCE',
  'SETSELECTION',
  'SHAREAPP',
  'SORT',
  'SQRT',
  'STARTTIMER',
  'STOPTIMER',
  'STOREWIDGETCONTENT',
  'SUBSTITUTE',
  'SUM',
  'TEXT',
  'TODAY',
  'TRANSPOSE',
  'TRIM',
  'UNIQUE',
  'UPDATE',
  'UPDATEUSER',
  'UPLOAD',
  'UPPER',
  'USERLIST',
  'USERNAME',
  'VISIBLEHIERARCHY',
  'WEEKDAY',
  'YEAR',
]

export const previewFunctionNames = functionNames.concat([])

// Also, for lang parser need to add value to
// src/templates/PageHeader/CodeEditor/lang/Lezer_creator/src/syntax.grammar
// and update code editor value
export const constantName = [
  'after',
  'allcells',
  'before',
  'boolean',
  'oncell',
  'constant',
  'date',
  'down',
  'empty',
  'escaping',
  'error',
  'horizontal',
  'image',
  'last',
  'noescaping',
  'nonemptycells',
  'number',
  'numericcells',
  'onpage',
  'position',
  'text',
  'up',
  'url',
  'value',
  'valuepath',
  'vertical',
  'onwidget',
]
