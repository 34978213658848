import {ReactNode} from 'react'
import {createPortal} from 'react-dom'

import {isTestEnv} from 'utils'

interface IProps {
  name: string
  children: ReactNode
}

export default function Portal({children, name}: IProps) {
  const domNode = isTestEnv ? document.body : document.getElementById(name)
  if (!domNode) throw Error(`Element by id ${name} does not exist in the DOM tree.`)
  return createPortal(children, domNode)
}
