import {cache, IWidgetGeometryIncludeKeyID, IWidgetGeometryWidthID} from 'apollo'
import {EntityType} from '../WidgetTypes/Table/tableTypes'
import {
  AffectedWidgetFragment,
  AffectedWidgetFragmentDoc,
  Maybe,
  SetWidgetsStylesMutationHookResult,
  TableWidgetData,
  WidgetStyles,
} from 'generated/graphql-operations'
import {stopDistance} from 'static/constants'

import {IList} from 'components'

type TParam = NonNullable<WidgetStyles['columnWidth']> | NonNullable<WidgetStyles['rowHeight']>

function equalizeDimensions(source: TParam, target: TParam): TParam {
  const [fromLength, toLength] = [source.length, target.length]
  if (fromLength > toLength) {
    return source.slice(0, toLength)
  } else if (toLength > fromLength) {
    return [...source, ...target.slice(fromLength)]
  }
  return source
}

interface IProps {
  pageSize: {width: number; height: number}
  widgetsGeometry: IWidgetGeometryIncludeKeyID
  selectedWidgets: number[]
  setGeometry: (arg: IWidgetGeometryWidthID[]) => void
  onStylesUpdate: SetWidgetsStylesMutationHookResult[0]
}

export const getAlignActions = ({
  pageSize,
  widgetsGeometry,
  selectedWidgets,
  setGeometry,
  onStylesUpdate,
}: IProps): IList[] => {
  const newPosition = (pos: 'x' | 'y', size: 'width' | 'height', borderSpace: number) => {
    const selectedWidgetsCopy: number[] = [...selectedWidgets]
    const activeWidgetId = selectedWidgetsCopy.pop()

    if (!selectedWidgetsCopy.length || !activeWidgetId) return
    const activePos = widgetsGeometry[activeWidgetId][pos]

    const checkPosition = (curSize: number) => {
      const hasBiggerSize = activePos + curSize + borderSpace - pageSize[size]
      return hasBiggerSize > 0 ? activePos - hasBiggerSize : activePos
    }

    const newGeometry: IWidgetGeometryWidthID[] = selectedWidgetsCopy.map((id: number) => {
      const geo = widgetsGeometry[id]
      return {
        ...geo,
        id,
        [pos]: checkPosition(geo[size]),
      }
    })

    setGeometry(newGeometry)
  }

  const newPosWithSize = (pos: 'x' | 'y', size: 'width' | 'height') => {
    const selectedWidgetsCopy: number[] = [...selectedWidgets]
    const activeWidgetId = selectedWidgetsCopy.pop()
    if (!selectedWidgetsCopy.length || !activeWidgetId) return

    const widgetGeometry = widgetsGeometry[activeWidgetId]
    const rightPoint = widgetGeometry[pos] + widgetGeometry[size]

    const newGeometry: IWidgetGeometryWidthID[] = selectedWidgetsCopy.map((id: number) => {
      const newPos = rightPoint - widgetsGeometry[id][size]
      return {
        ...widgetsGeometry[id],
        id,
        [pos]: Math.max(newPos, 0),
      }
    })
    setGeometry(newGeometry)
  }

  const alignDimensions = (tables: AffectedWidgetFragment[], dimension: EntityType) => {
    const total = tables.length
    const refWidget = tables[total - 1] // currently selected widget
    const prop: keyof WidgetStyles = dimension === 'row' ? 'rowHeight' : 'columnWidth'

    if (total > 1 && refWidget.type === 'TABLE') {
      const targetWidgets = tables.slice(0, total - 1)
      const [ids, newDimensions, newGeometries]: [
        number[],
        {[x: string]: TParam}[],
        IWidgetGeometryWidthID[],
      ] = [[], [], []]

      for (const w of targetWidgets) {
        const newSizes = equalizeDimensions(
          refWidget.styles[prop] as number[],
          w.styles[prop] as number[],
        )
        ids.push(w.id)
        newDimensions.push({ [prop]: newSizes })

        const geoProp =
          (w.data as TableWidgetData).viewType === 'ROW' && dimension === 'row' ? 'height' : 'width'
        if (
          (w.data as TableWidgetData).viewType?.toLowerCase() === dimension &&
          newSizes[0] !== widgetsGeometry[w.id][geoProp]
        ) {
          newGeometries.push({
            ...widgetsGeometry[w.id],
            id: w.id,
            [geoProp]: newSizes[0],
          })
        }
      }

      onStylesUpdate({
        variables: {ids, newStyles: newDimensions},
      })
      // If table is of type ROW or COLUMN, we need to additionally sync the wrapper height/width
      if (newGeometries.length) setGeometry(newGeometries)
    }
  }

  const widgetWStyles = selectedWidgets.reduce<AffectedWidgetFragment[]>((acc, id) => {
    const frag: Maybe<AffectedWidgetFragment> = cache.readFragment({
      id: `Widget:${id}`,
      fragment: AffectedWidgetFragmentDoc,
      fragmentName: 'AffectedWidget',
    })
    if (frag && frag.type === 'TABLE') acc.push(frag)
    return acc
  }, [])

  return [
    {
      name: 'Align to top',
      action: () => newPosition('y', 'height', stopDistance.bottom),
    },
    {
      name: 'Align to left',
      action: () => newPosition('x', 'width', stopDistance.right),
    },
    {
      name: 'Align to bottom',
      action: () => newPosWithSize('y', 'height'),
    },
    {
      name: 'Align to right',
      action: () => newPosWithSize('x', 'width'),
    },
    ...(widgetWStyles.length > 1
      ? [
          {
            name: 'Align column widths',
            action: () => alignDimensions(widgetWStyles, 'column'),
          },
          {
            name: 'Align row heights',
            action: () => alignDimensions(widgetWStyles, 'row'),
          },
        ]
      : []),
  ]
}
