import {useCallback, useMemo} from 'react'
import {debounce} from 'throttle-debounce'
import {toast} from 'react-toastify'

import {
  cache,
  deselectAllWidgets,
  setActiveCellTableVar,
  setMultiSelectCellsVar,
  setSelectedStyleTabVar,
  setSelectedWidgetsVar,
  TActiveCell,
  TCellCoordinate,
  ValueType,
} from 'apollo'
import {chartStyleProps} from 'templates/Settings/Widget/Styles/constants'
import {
  Maybe,
  Page,
  PageStyles,
  useSetWidgetsStylesWithListMutation,
  Widget,
  WidgetFieldsFragment,
  WidgetFieldsFragmentDoc,
  WidgetStyles,
  WidgetStylesInput,
} from 'generated/graphql-operations'
import {usePages, useWidgets} from 'repository/graphql'
import {stripTypenames} from 'utils'
import {ContainerId} from 'components/Toast/config'

const SAVE_DELAY = 800

type TStyle = ValueType | number[] | string[] | undefined

type TNewWidgetStyles = {id: number; styles: WidgetStyles}

export type TOldWidgetStyles = {
  id: Widget['id']
  key: keyof WidgetStyles
  style: TStyle
  activeCell?: TActiveCell
  multiSelectCells?: TCellCoordinate[]
}

export const useWidgetStyleRollback = () => {
  const [setWidgetsStylesWithList] = useSetWidgetsStylesWithListMutation()

  const debounceDataSave = useMemo(
    () =>
      debounce(SAVE_DELAY, (newStyles: TNewWidgetStyles[]) => {
        setWidgetsStylesWithList({
          variables: {
            values: newStyles.map(({id, styles}) => ({
              id,
              styles: stripTypenames(styles) as WidgetStylesInput,
            })),
          },
        })
      }),
    [setWidgetsStylesWithList],
  )

  return useCallback(
    (styles: TOldWidgetStyles[]): void | TOldWidgetStyles[] => {
      try {
        const newStyles: TNewWidgetStyles[] = []
        const oldStyles: TOldWidgetStyles[] = []
        const ids: number[] = []

        for (const {id, style, key, activeCell, multiSelectCells} of styles) {
          ids.push(id)

          const styleCache: Maybe<WidgetFieldsFragment> = cache.readFragment({
            id: `Widget:${id}`,
            fragment: WidgetFieldsFragmentDoc,
            fragmentName: 'WidgetFields',
          })

          if (!styleCache?.styles) return debounceDataSave.cancel()
          const newStyle = {id, styles: {...styleCache.styles, [key]: style}}

          cache.writeFragment({
            id: `Widget:${id}`,
            fragment: WidgetFieldsFragmentDoc,
            fragmentName: 'WidgetFields',
            data: {
              ...styleCache,
              styles: newStyle.styles,
            },
          })

          let newActiveCell
          if (activeCell) {
            const {x, y} = activeCell
            if (Array.isArray(style) && typeof x === 'number' && typeof y === 'number') {
              const name = key === 'columnWidth' ? 'width' : 'height'
              const value = key === 'columnWidth' ? style[x] : style[y]
              newActiveCell = {...activeCell, [name]: value}
            }
          }

          newStyles.push(newStyle)
          oldStyles.push({
            id,
            style: styleCache.styles[key],
            key,
            activeCell: newActiveCell,
            multiSelectCells,
          })
        }

        if (newStyles.length) {
          const isChartUpdate = styles.some(({key}) => chartStyleProps.includes(key))
          setSelectedWidgetsVar(ids)
          setSelectedStyleTabVar(isChartUpdate ? 'Chart Style' : 'Style')
          const {activeCell, multiSelectCells} = oldStyles[0]
          if (activeCell) setActiveCellTableVar(activeCell)
          if (multiSelectCells) setMultiSelectCellsVar(multiSelectCells)

          debounceDataSave(newStyles)
        }

        return oldStyles
      } catch (e) {
        toast.warning('Lost undo-redo of widget styles', {containerId: ContainerId.ROOT})
      }
    },
    [debounceDataSave],
  )
}

export type TCopyPasteData = {
  ids: Widget['id'][]
  newFormat: Widget['format']
  newStyles: Widget['styles']
}

export type TCopyPasteStyles = {oldData: TCopyPasteData; newData: TCopyPasteData}

export const useCopyPasteStyleRollback = () => {
  const {copyWidgetsStyles} = useWidgets()

  return useCallback(
    async ({oldData, newData}: TCopyPasteStyles): Promise<void | TCopyPasteStyles> => {
      try {
        const resp = await copyWidgetsStyles({variables: oldData})
        if (!resp.data?.setWidgetsStyles) return
        setSelectedWidgetsVar(oldData.ids)

        return {oldData: newData, newData: oldData}
      } catch (e) {
        toast.warning('Lost undo-redo of widget copy-paste styles', {containerId: ContainerId.ROOT})
      }
    },
    [copyWidgetsStyles],
  )
}

export type TPageStyles = {
  id: Page['id']
  newStyles: {key: string; val: string | boolean | number}
  oldStyles: PageStyles & {[key: string]: string | boolean | number}
}

export const usePageStyleRollback = () => {
  const {setPageStyles} = usePages()

  return useCallback(
    async ({id, oldStyles, newStyles: {key, val}}: TPageStyles): Promise<void | TPageStyles> => {
      try {
        const {data} = await setPageStyles({
          variables: {id, newStyles: {[key]: oldStyles[key]}},
          optimisticResponse: {
            setPageStyles: {id, styles: oldStyles, __typename: 'Page'},
          },
        })
        if (!data?.setPageStyles) return

        deselectAllWidgets()

        return {id, oldStyles: {...oldStyles, [key]: val}, newStyles: {key, val: oldStyles[key]}}
      } catch (e) {
        toast.warning('Lost undo-redo of page styles', {containerId: ContainerId.ROOT})
      }
    },
    [setPageStyles],
  )
}
