import {IWidgetGeometryWidthID} from 'apollo'
import {IUndoRedoSetData} from './useUndoRedo'
import {TCopyPasteStyles, TOldWidgetStyles} from './useStyle'
import {TRestoreData} from './useLive'
import {TPageTypeValue} from './useType'

const PAGE_ACTIONS = ['PAGE', 'PAGE_AVAILABILITY_IN_LIVE_MODE']

export const checkNewData = (newRedo: IUndoRedoSetData): IUndoRedoSetData =>
  newRedo.type === 'restoreWidget'
    ? {type: 'createWidget', variables: (newRedo.variables as TRestoreData).newIds}
    : newRedo

export const updateOldList = (
  undoList: IUndoRedoSetData[],
  newRedo: IUndoRedoSetData | void,
): IUndoRedoSetData[] => {
  if (newRedo?.type === 'restoreWidget') {
    const {newIds, oldIds} = newRedo.variables as TRestoreData

    return undoList.map(({type, variables}) => {
      if (PAGE_ACTIONS.includes((variables as TPageTypeValue).type)) return {type, variables}

      switch (type) {
        case 'moveWidgets':
        case 'setWidgetsStyles': {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const updatedVar = (variables as any).map(
            (data: TOldWidgetStyles | IWidgetGeometryWidthID) => {
              const idIndex = oldIds.indexOf(data.id)
              return idIndex >= 0 ? {...data, id: newIds[idIndex]} : data
            },
          )
          return {type, variables: updatedVar}
        }
        case 'setFormula': {
          if ('selectedWidgets' in variables) {
            for (let index = 0; index < oldIds.length; index++) {
              const id = oldIds[index]
              const idIndex = variables.selectedWidgets.indexOf(id)

              if (!variables.actionsCode[idIndex] || !newIds[index]) continue

              const newId = newIds[index]
              variables.actionsCode[idIndex].widgetId = newId
              variables.prevActionsCode[idIndex].widgetId = newId
              variables.selectedWidgets[idIndex] = newId
            }
          }
          return {type, variables}
        }
        case 'setFormat':
        case 'setScrollBinding':
        case 'setStatus': {
          return {type, variables: {...variables, ids: newIds}}
        }
        case 'setCopyPasteStyles': {
          const {oldData, newData} = variables as TCopyPasteStyles
          return {
            type,
            variables: {oldData: {...oldData, ids: newIds}, newData: {...newData, ids: newIds}},
          }
        }
        case 'setPageType':
        case 'setWidgetType':
        case 'setTitle':
        case 'setConnection':
        case 'setData': {
          return {type, variables: {...variables, id: newIds[0]}}
        }
        case 'createWidget': {
          return {type, variables: newIds}
        }
        default:
          return {type, variables}
      }
    })
  }

  return undoList
}
