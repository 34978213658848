import {ERROR_NAMES_TO_BACKEND, isLikelyDate, userDateToUTC} from 'utils'
import {ValueType} from 'apollo'
import {WidgetFormat} from 'generated/graphql-operations'

interface ISeparator {
  lang: string[]
  regexp: string
  stringSign: string
  decimalRGXP: string
}

const locale = navigator.language || 'en-US'
const loc = locale.slice(0, 2)

// Thousand separators
const separatorConfigs: ISeparator[] = [
  {
    lang: ['en', 'he', 'hi', 'ja', 'ko', 'ta', 'th', 'zh'],
    regexp: ',',
    stringSign: ',',
    decimalRGXP: '\\.',
  },
  {
    lang: ['da', 'de', 'el', 'es', 'id', 'it', 'nl', 'pt', 'ro', 'tr'],
    regexp: '\\.',
    stringSign: '.',
    decimalRGXP: ',',
  },
  {
    lang: ['fi', 'fr', 'hu', 'no', 'pl', 'ru', 'sk', 'sv', 'uk'],
    regexp: '\\s',
    stringSign: ' ',
    decimalRGXP: ',',
  },
]

export const {
  regexp = separatorConfigs[0].regexp,
  stringSign = separatorConfigs[0].stringSign,
  decimalRGXP = separatorConfigs[0].decimalRGXP,
} = separatorConfigs.find((sep) => sep.lang.includes(loc)) || {}

const regExpProbableNumber = new RegExp(`^(-?\\d+(${regexp}\\d+)*(${decimalRGXP}\\d+)?(\\s*%+)?)$`)
const isLikelyNumber = (value: string) => regExpProbableNumber.test(value)

const regExpProbableDateString = new RegExp(
`^(0[1-9]|1[0-2])[-./](19|20)\\d{2}$|^(0[1-9]|[12][0-9]|3[01])[-./](0[1-9]|1[0-2])$|^(0[1-9]|[12][0-9]|3[01])[-./](0[1-9]|1[0-2])[-./](19|20)\\d{2}$|^(0[1-9]|1[0-2])[-./](0[1-9]|[12][0-9])$|^(0[1-9]|[12][0-9])[-./](0[1-9]|1[0-2])[-./](19|20|21)\\d{2}$
`)

export const isLikeDateString = (value: string) => regExpProbableDateString.test(value)

const regExpGroupingNumber = new RegExp(`^(-?\\d{1,3}(${regexp}\\d{3})+(${decimalRGXP}\\d+)?)$`)
const isLikelyGrouping = (value: string) => regExpGroupingNumber.test(value)

const toBackendNumber = (value: string, separator: string) =>
  Number(separator === ',' ? value.replace(/,/, '.') : value)

// Convert string-like values to their original types before syncing with BE
export const toBackendValue = (value: ValueType, format: WidgetFormat = {}): ValueType => {
  const strValue = String(value || '').trim()

  if (strValue === 'true') return true
  if (strValue === 'false') return false
  if (strValue.length === 0) return value
  // isLikelyDate doesn`t see the normal number only ISO
  if (isLikelyDate(strValue)) return userDateToUTC(strValue, format)

  if (isLikelyNumber(strValue) && !isLikeDateString(strValue)) {
    let numberValue
    if (format.showNumberInPercent || strValue.endsWith('%')) {
      const replacedValue = strValue.replace(/\s|%/g, '')
      numberValue = +(toBackendNumber(replacedValue, decimalRGXP) / 100).toPrecision(
        replacedValue.length,
      )
    } else if (format.showGroupedNumber || isLikelyGrouping(strValue)) {
      numberValue = toBackendNumber(strValue.split(stringSign).join(''), decimalRGXP)
    } else {
      // Strip all non-numeric characters except for the last decimal point
      numberValue = Number(strValue.replace(/[^\d-]/g, '.').replace(/[.](?!\d*$)/g, ''))
    }

    return Number.isNaN(numberValue) ? value : numberValue
  }

  return ERROR_NAMES_TO_BACKEND[strValue] || value
}

export const tableToBackendValue = (rows: string[][] | null, format: WidgetFormat) =>
  rows ? rows.map((row) => row.map((col) => toBackendValue(col, format))) : [['']]
