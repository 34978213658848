import {PageWidget, setActivePageBlock, setActiveWidgetVar} from 'apollo'
import {WidgetGeometry} from 'generated/graphql-operations'

import {PAGE_WRAPPER} from 'static/constants'

const PADDING_FROM_BORDER = 200
const setScrollPosition = (geo: WidgetGeometry) => {
  const pageWrapper: HTMLDivElement | null = document.querySelector(`.${PAGE_WRAPPER}`)
  if (!pageWrapper) return
  const {scrollTop, scrollLeft} = pageWrapper
  const {height: pageHeight, width: pageWidth} = pageWrapper.getBoundingClientRect()

  const outY = geo.y + geo.height < scrollTop || geo.y > scrollTop + pageHeight
  const outX = geo.x + geo.width < scrollLeft || geo.x > scrollLeft + pageWidth

  if (outY || outX) {
    pageWrapper.scroll({
      top: geo.y - PADDING_FROM_BORDER,
      left: geo.x - PADDING_FROM_BORDER,
      behavior: 'smooth',
    })
  }
}

export default function setWidgetDataVar({
  actions,
  editableEvaluated,
  enabled,
  geometry,
  id,
  title,
  type,
  visible,
}: PageWidget) {
  setActivePageBlock('main')
  setScrollPosition(geometry)
  setActiveWidgetVar({
    actions,
    editableEvaluated,
    enabled,
    id,
    title,
    type,
    visible,
  })
}
