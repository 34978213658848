import {lazy, useEffect} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'

import {FeatureToggle} from 'static/constants'
import {setFeaturePreview, setUserDataVar} from 'apollo'
import {useUserDataQuery} from 'generated/graphql-operations'

import {Loader} from 'components'

const AppList = lazy(() => import('./AppList'))
const Layout = lazy(() => import('./Layout'))
const NotFound = lazy(() => import('./Errors/NotFound'))
const NotFoundWorkspace = lazy(() => import('./Errors/NotFoundWorkspace'))
const SignIn = lazy(() => import('./SignIn'))

const setNewWorkspace = (newWorkspace: string) => {
  const currentWorkspace = localStorage.getItem('workspaceName')

  if (currentWorkspace !== newWorkspace) {
    localStorage.setItem('workspaceName', newWorkspace)
  }
}

export default function AuthenticatedViews() {
  useEffect(() => {
    const featurePreview = localStorage.getItem('featuresPreview')
    if (featurePreview) {
      const userFeaturePreview: FeatureToggle = JSON.parse(featurePreview)
      setFeaturePreview(userFeaturePreview)
    }
  }, [])

  const {data: userData, loading} = useUserDataQuery({
    onCompleted: ({me}) => setUserDataVar(me),
  })

  if (loading || !userData) return <Loader $position='fixed' $isInside />
  const newWorkspace = userData.me.workspace.name
  setNewWorkspace(newWorkspace)

  return (
    <Routes>
      <Route path='/not-found' element={<NotFound />} />
      <Route path='/not-found-workspace' element={<NotFoundWorkspace />} />
      <Route path='/:workspace/apps' element={<AppList />} />
      <Route path='/:workspace/app/:appId/page/:pageId' element={<Layout />} />
      <Route path='/:workspace?/login' element={<SignIn />} />
      <Route path='/*' element={<Navigate to={`/${newWorkspace}/apps`} replace />} />
    </Routes>
  )
}
