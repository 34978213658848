import styled from 'styled-components'

export const Line = styled.span<{
  $color?: string
  $margin?: string
  $height?: string
  $topBarLine?: boolean
}>`
  display: inline-block;
  background: ${(p) => (p.$color ? p.theme[p.$color] : 'rgba(255,255,255, 0.2)')};
  min-height: ${(p) => (p.$topBarLine ? '30px' : 'inherit')};
  height: ${(p) => p.$height || '100%'};
  width: 1px;
  margin: ${(p) => p.$margin || '0 10px'};
  user-select: none;
`

Line.displayName = 'LineContainer'
