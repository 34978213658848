
import {IList} from 'components';
import {IWidgetGeometryIncludeKeyID, IWidgetGeometryWidthID} from '../../../apollo'

interface IProps {
  widgetsGeometry: IWidgetGeometryIncludeKeyID;
  selectedWidgets: number[];
  setGeometry: (arg: IWidgetGeometryWidthID[]) => void;
}

export const distributeAction = ({
  widgetsGeometry,
  selectedWidgets,
  setGeometry,
}: IProps) => {
  const setDistribute = (axis: 'y' | 'x') => {
    const size = axis === 'x' ? 'width' : 'height';

    const sortedIdByGeo = [...selectedWidgets].sort((idA, idB) => {
      const posA = widgetsGeometry[idA][axis];
      const sizeA = widgetsGeometry[idA][size];
      const geoA = sizeA / 2 + posA;
      const posB = widgetsGeometry[idB][axis];
      const sizeB = widgetsGeometry[idB][size];
      const geoB = sizeB / 2 + posB;

      if (geoA > geoB) return 1;
      if (geoA < geoB) return -1;
      return 0;
    });

    const firstId = sortedIdByGeo.shift();
    const lastId = sortedIdByGeo.pop();
    if (!firstId || !lastId) return;

    const firstWidget = widgetsGeometry[firstId];
    const lastWidget = widgetsGeometry[lastId];
    const widgetsSize = sortedIdByGeo.reduce(
      (sum: number, curId: number) => sum + widgetsGeometry[curId][size],
      0,
    );

    const firstPos = firstWidget[axis] + firstWidget[size];
    const distance: number = lastWidget[axis] - firstPos;
    const freePlaceBetweenWidgets = Math.round(
      (distance - widgetsSize) / (sortedIdByGeo.length + 1),
    );

    const widgetGeometrySelected: IWidgetGeometryWidthID[] = [];
    let prevPos = firstPos + freePlaceBetweenWidgets;
    for (const id of sortedIdByGeo) {
      const prevSize = widgetsGeometry[id][size];
      widgetGeometrySelected.push({
        ...widgetsGeometry[id],
        [axis]: prevPos,
        id,
      });
      prevPos += prevSize + freePlaceBetweenWidgets;
    }

    setGeometry(widgetGeometrySelected);
  };

  return [
    {
      name: 'Distribute horizontally',
      action: () => setDistribute('x'),
    },
    {
      name: 'Distribute vertically',
      action: () => setDistribute('y'),
    },
  ] as IList[];
};
