import {useCallback} from 'react'
import {generatePath, useNavigate} from 'react-router-dom'
import {useReactiveVar} from '@apollo/client'

import {AppMode, getStoredAppModes, getStoredPageIds} from 'utils/pageStore'
import {
  AppsDocument,
  AppsQuery,
  Maybe,
  PagesDocument,
  PagesQuery,
  SessionMode,
} from 'generated/graphql-operations'
import {cache, setActivePageIdVar, setUserDataVar} from 'apollo'
import {getDifference} from 'utils'
import {IPopupPage} from '../static/constants'

export enum SearchParam {
  HIDE_HEADER = 'hideHeader',
  HIDE_NAV = 'hideNav',
  MODE = 'mode',
  TRACING = 'tracing',
  VIEW_TOKEN = 'viewToken',
}

interface IQuery {
  appId: number
  mode: AppMode
  pageId?: number | null
  workspace?: string
}

export type AppQueryParams = {
  appId?: string
  pageId?: string
} & Record<string, string | undefined>

export const getFullPath = ({appId, mode, pageId, workspace = ''}: IQuery): string => {
  if (!pageId) return ''

  const path = generatePath('/:workspace/app/:appId/page/:pageId', {
    appId: appId.toString(),
    pageId: pageId.toString(),
    workspace,
  })
  const query = new URLSearchParams(location.search)
  const tracing = sessionStorage.getItem(SearchParam.TRACING)

  const ignoredParams = Array.from(
    new Set([
      SearchParam.MODE,
      SearchParam.TRACING,
      ...getDifference([...query.keys()], Object.values(SearchParam)),
    ]),
  )
  ignoredParams.forEach((param) => query.delete(param))

  const traceParam = tracing ? '&tracing=on' : ''
  const rest = query.toString()
  const pathStr = `${path}?mode=${mode}${traceParam}${rest.length ? `&${rest}` : ''}`

  return pathStr.endsWith('=') ? pathStr.slice(0, -1) : pathStr
}

// As long as our url respects the 'param/:paramId' convention
export const getUrlParam = (path: string, key: string): string | null => {
  const vals = path.split('/')
  const index = vals.indexOf(key)
  if (index >= 0) {
    return vals[index + 1]
  }

  return null
}

export const getWorkspace = (): string => {
  const workspace = location.pathname.split('/')?.[1]
  return (
    (!['login', 'not-found-workspace'].includes(workspace) && workspace) ||
    localStorage.getItem('workspaceName') ||
    ''
  )
}

export const usePageNavigation = (appId: number) => {
  const navigate = useNavigate()
  const userData = useReactiveVar(setUserDataVar)

  return useCallback(
    (pageId: number) => {
      setActivePageIdVar(pageId)
      const mode: AppMode | null = sessionStorage.getItem('mode') as AppMode
      const path = getFullPath({
        appId,
        mode: mode || 'live',
        pageId,
        workspace: userData?.workspace.name,
      })
      navigate(path)
    },
    [navigate, appId, userData?.workspace.name],
  )
}

export const getPageIdMode = (appId: number, modeParam?: string, urlPageId?: string, status?: IPopupPage['status']) => {
  let appMode: SessionMode | undefined
  let pageId

  const storedAppMode = getStoredAppModes()
  const storedPages = getStoredPageIds()

  const cachedPages: Maybe<PagesQuery> = cache.readQuery({
    query: PagesDocument,
    variables: {appId},
  })

  const cachedApps: Maybe<AppsQuery> = cache.readQuery({
    query: AppsDocument,
  })

  const app = cachedApps?.getApps.find((item) => item.application.id === appId)

  const pages = cachedPages?.pages || app?.application.pages || []
  let availabilityInLiveMode = true

  if (app && appId) {
    const appPermission = app.permission.name
    const storedPageIdByApp = storedPages && storedPages[appId]
    const initialPage = pages?.find((item) => item.availabilityInLiveMode)

    // no page id no app mode
    if (!modeParam || !urlPageId) {
      if (appPermission === 'Editor') {
        pageId = initialPage?.id || pages[0]?.id
        appMode = initialPage ? 'live' : 'edit'
      } else {
        pageId = initialPage?.id
        appMode = initialPage ? 'live' : undefined
      }
    }

    // stored values in session and local storage
    if (storedPageIdByApp && storedAppMode) {
      pageId = storedPageIdByApp || initialPage?.id
      appMode = storedAppMode[appId]
    }

    // check mode param and page id
    if (modeParam && urlPageId) {
      const isModeCorrect = ['live', 'edit'].includes(modeParam)
      const foundPage = pages.find((page) => page.id === +urlPageId)

      if (isModeCorrect && foundPage) {
        if (appPermission === 'Editor') {
          pageId = foundPage.id
          appMode = modeParam as SessionMode
        }
        if (appPermission === 'Viewer') {
          if (foundPage.availabilityInLiveMode || status === 'openPage') pageId = foundPage.id
          else pageId = initialPage?.id || 0
          availabilityInLiveMode = foundPage.availabilityInLiveMode
          appMode = 'live'
        }
      }
    }
  }

  return {pageId, appMode, availabilityInLiveMode}
}
