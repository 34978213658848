import {memo, useContext} from 'react'
import styled from 'styled-components'
import {CaretRight} from '@phosphor-icons/react'
import {useReactiveVar} from '@apollo/client'

import {setWidgetsGeometryVarWithId, setSelectedWidgetsVar, setActiveWidgetVar} from 'apollo'
import {WidgetGeometryContext, WidgetEditStatusContext} from 'repository/context'

import {orderAction} from './order'
import {getAlignActions} from './align'
import {sizeAction} from './size'
import {distributeAction} from './distribute'
import {showStylesError} from 'templates/Settings/Widget/Styles/utils'
import {useSetWidgetsStylesMutation} from '../../../generated/graphql-operations'

interface IProps {
  pageSize: {width: number; height: number}
  close: () => void
  onDeleteWidget: () => void
  onCopyWidget: () => void
}

type ActionsUnionT = 'Order' | 'Align' | 'Distribute' | 'Size'

export interface IList {
  name: ActionsUnionT
  action: () => void
}

const BLOCKS_HEIGHT = 240
const BLOCKS_WIDTH = 160

const ActionsList = ({pageSize, close, onDeleteWidget, onCopyWidget}: IProps) => {
  const positions = {isTop: false, isLeft: false}

  const {id: widgetId, type} = useReactiveVar(setActiveWidgetVar) || {id: 0, type: 'CELL'}
  const selectedWidgets = useReactiveVar(setSelectedWidgetsVar)
  const widgetsGeometry = useReactiveVar(setWidgetsGeometryVarWithId)

  const {height, width, y, x} = widgetsGeometry[widgetId]
  positions.isTop = pageSize.height < height + y + BLOCKS_HEIGHT && height + y > BLOCKS_HEIGHT
  positions.isLeft = pageSize.width < width + x + BLOCKS_WIDTH && width + x > BLOCKS_WIDTH

  const setGeometry = useContext(WidgetGeometryContext)
  const toggleSelectStatus = useContext(WidgetEditStatusContext)
  const [setWidgetsStyles] = useSetWidgetsStylesMutation({
    onError: showStylesError,
  })

  const props = {widgetsGeometry, selectedWidgets, setGeometry, pageSize}
  const ORDER_OPTIONS = orderAction(props)
  const ALIGN_OPTIONS = getAlignActions({
    ...props,
    onStylesUpdate: setWidgetsStyles,
  })
  const SIZE_OPTIONS = sizeAction({...props, onStylesUpdate: setWidgetsStyles})
  const DISTRIBUTE_OPTIONS = distributeAction(props)

  const actionsList = {
    Order: ORDER_OPTIONS,
    ...(selectedWidgets.length > 1 && {Align: ALIGN_OPTIONS, Size: SIZE_OPTIONS}),
    ...(selectedWidgets.length > 2 && {Distribute: DISTRIBUTE_OPTIONS}),
  } as {[key in ActionsUnionT]: IList[]}

  const currActionList = Object.keys(actionsList) as ActionsUnionT[]
  return (
    <OptionsList $isTop={positions.isTop} $isLeft={positions.isLeft}>
      {(type === 'BUTTON' || type === 'IMAGE') && (
        <MenuItem
          key={`ActionList ${type}`}
          onClick={() => {
            close()
            toggleSelectStatus((st) => !st)
          }}
        >
          {type === 'IMAGE' ? 'Change image' : 'Change content'}
        </MenuItem>
      )}

      {currActionList.map(
        (listName): JSX.Element => (
          <MenuBlock key={`ActionsList ${listName}`}>
            {listName}
            <CaretRight size={13} weight='bold' />

            <MenuList $isLeftPosition={positions.isLeft}>
              {actionsList[listName].map(
                (list: IList): JSX.Element => (
                  <MenuItem
                    key={`ActionList ${listName} - ${list.name}`}
                    onClick={(): void => {
                      close()
                      list.action()
                    }}
                  >
                    {list.name}
                  </MenuItem>
                ),
              )}
            </MenuList>
          </MenuBlock>
        ),
      )}

      <MenuItem onClick={onCopyWidget}>Duplicate</MenuItem>
      <MenuItem onClick={onDeleteWidget}>Delete</MenuItem>
    </OptionsList>
  )
}

export default memo(ActionsList)

const OptionsList = styled.ul<{$isTop: boolean; $isLeft: boolean}>`
  ${(p) => p.theme.menu};
  display: flex;
  max-width: 140px;
  max-height: 270px;
  overflow: visible;

  ${(p) => (p.$isTop ? 'bottom' : 'top')}: 28px;
  ${(p) => (p.$isLeft ? 'right' : 'left')}: -10px;
  flex-direction: column;
`

const MenuBlock = styled.li`
  ${(p) => p.theme.menuItem};
  position: relative;
  justify-content: space-between;
  align-items: center;
  white-space: pre-wrap;

  &:hover ul {
    display: block;
  }
`

const MenuItem = styled.li`
  ${(p) => p.theme.menuItem};

  &:first-child {
    border-bottom: 1px solid ${(p) => p.theme.colors.lightest};
  }
`

const MenuList = styled.ul<{$isLeftPosition: boolean}>`
  ${(p) => p.theme.menu};
  display: none;
  top: 0;
  ${(p) => (p.$isLeftPosition ? 'right: 94%' : 'left: 90%')};
`
