import {toast} from 'react-toastify'

import {accessTokenName} from 'static/constants/app'
import {ContainerId} from 'components/Toast/config'

interface IUserAuth {
  accessToken: string
}

const getAccessToken = () => {
  try {
    const query = new URLSearchParams(location.search)
    const viewToken = query.get('viewToken')
    if (viewToken) return viewToken

    const userData = localStorage.getItem(accessTokenName)
    return userData ? JSON.parse(userData)?.accessToken : ''
  } catch (err) {
    toast.error('Access token is missing or expired', {
      containerId: ContainerId.ROOT,
      toastId: 'invalid-access-token',
    })
    return ''
  }
}

const setUserToken = (user: IUserAuth): void => {
  localStorage.setItem(accessTokenName, JSON.stringify(user))
}

const removeUser = () => localStorage.removeItem(accessTokenName)

export {getAccessToken, setUserToken, removeUser}
