import {useCallback} from 'react'

import {cache, IWidgetGeometryIncludeKeyID, setSelectedWidgetsVar} from 'apollo'
import {
  AffectedWidgetFragment,
  AffectedWidgetFragmentDoc,
  Maybe,
  SetWidgetsStylesMutation,
  SetWidgetsStylesMutationHookResult,
  TableWidgetData,
  useSetWidgetsStylesMutation,
  Widget,
  WidgetStyles,
} from 'generated/graphql-operations'

// When table is in ROW mode, and only the width changes (and vice versa), we don't want to sync the dimensions.
const isRightWidget = (
  w: AffectedWidgetFragment,
  geometryMap: IWidgetGeometryIncludeKeyID,
): boolean => {
  const viewType = (w.data as TableWidgetData).viewType
  const newSize = geometryMap[w.id]?.[viewType === 'ROW' ? 'height' : 'width']
  return (
    (w.type === 'TABLE' && viewType === 'ROW' && newSize !== w.styles.rowHeight?.[0]) ||
    (viewType === 'COLUMN' && newSize !== w.styles.columnWidth?.[0])
  )
}

export const handleStylesSync = (
  selectedIds: ReturnType<typeof setSelectedWidgetsVar>,
  geometryMap: IWidgetGeometryIncludeKeyID,
  onWidgetStylesSet: SetWidgetsStylesMutationHookResult[0],
) => {
  const tableWidgetsFromCache = selectedIds.reduce<AffectedWidgetFragment[]>((acc, curr) => {
    const widgetFromCache: Maybe<AffectedWidgetFragment> = cache.readFragment({
      id: `Widget:${curr}`,
      fragment: AffectedWidgetFragmentDoc,
      fragmentName: 'AffectedWidget',
    })
    if (widgetFromCache && isRightWidget(widgetFromCache, geometryMap)) {
      acc.push(widgetFromCache)
    }
    return acc
  }, [])

  if (tableWidgetsFromCache.length) {
    const [ids, updatedDimensions, affectedWidgets] = [[], [], []] as [
      Widget['id'][],
      Pick<WidgetStyles, 'rowHeight' | 'columnWidth'>[],
      SetWidgetsStylesMutation['setWidgetsStyles']['affectedWidgets'],
    ]

    for (const w of tableWidgetsFromCache) {
      ids.push(w.id)

      const tableType = (w.data as TableWidgetData).viewType
      const newStyle = {
        [tableType === 'ROW' ? 'rowHeight' : 'columnWidth']: [
          geometryMap[w.id][tableType === 'ROW' ? 'height' : 'width'],
        ],
      }
      updatedDimensions.push(newStyle)

      affectedWidgets.push({
        id: w.id,
        cellStyles: {
          ...w.cellStyles,
          __typename: 'WidgetCellStyles',
        },
        styles: {
          ...w.styles,
          ...newStyle,
          __typename: 'WidgetStyles',
        },
        __typename: 'Widget',
      })
    }

    onWidgetStylesSet({
      variables: {
        ids,
        newStyles: updatedDimensions,
      },
      optimisticResponse: {
        setWidgetsStyles: {affectedWidgets},
      },
    })
  }
}

type HookResult = {
  onSyncWithWidgetDimensions: (geometryMap: IWidgetGeometryIncludeKeyID) => void
}

// When table is in ROW or COLUMN mode, sync the grid dimensions (width/height)
// with those of a wrapping widget.
export const useSyncDimensions = (
  widgetIds: ReturnType<typeof setSelectedWidgetsVar>,
): HookResult => {
  const [setWidgetsStyles] = useSetWidgetsStylesMutation()

  const handleSync = useCallback<HookResult['onSyncWithWidgetDimensions']>(
    (geoMap) => handleStylesSync(widgetIds, geoMap, setWidgetsStyles),
    [setWidgetsStyles, widgetIds],
  )

  return {onSyncWithWidgetDimensions: handleSync}
}
