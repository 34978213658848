import colors from './colors';

const button = {
  default: `
    background-color: ${colors.lightest};
    color: ${colors.text};;
  `,
  primary: `
    background-color: ${colors.primary};
    color: ${colors.mainBackground};
  `,
  secondary: `
    background-color: ${colors.secondary};
    color: ${colors.mainBackground};
  `,
  opacity: `
    background-color: ${colors.opacity};
    color: ${colors.mainBackground};
  `,
  error: `
    background-color: ${colors.error};
    color: ${colors.mainBackground};
  `,
};

export default button;
