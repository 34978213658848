import styled from 'styled-components'
import LoginWrapper from 'templates/Auth/LoginWrapper'
import decorativeImage from 'static/images/svgs/service.svg'

export default function Maintenance() {
  return (
    <LoginWrapper
      styles={{
        width: '600px',
        padding: '50px 0 40px 0',
      }}
    >
      <TextContainer>
        <StyledHeader>System updating&hellip;</StyledHeader>
        <BodyContainer>
          We are undergoing a bit of scheduled maintenance. Please try again later or{' '}
          <ContactLink href='mailto:support@nedyx.com'>contact us</ContactLink>
        </BodyContainer>
      </TextContainer>
      <ImageContainer />
    </LoginWrapper>
  )
}

const ImageContainer = styled.div`
  margin: 0 auto;
  width: 380px;
  min-height: 260px;
  max-width: 90%;
  background-image: url(${decorativeImage});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`

const StyledHeader = styled.h1`
  ${(p) => p.theme.typography.h1}
  margin: 0 0 20px 0;
`
const BodyContainer = styled.span`
  font-size: 15px;
  color: ${(p) => p.theme.colors.subhead};
`
const ContactLink = styled.a`
  color: ${(p) => p.theme.colors.primary};
  font-size: 15px;
  font-weight: 700;
`

const TextContainer = styled.div`
  max-width: 360px;
  margin: 50px auto;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    max-width: 80%;
  }
`
