import {useReactiveVar} from '@apollo/client'

import {setAppIdVar} from 'apollo'
import {onError} from 'utils/serverErrorToast'
import {
  UsersWithSharedAppDocument,
  useChangePasswordMutation,
  useCreateUserMutation,
  useAddUserIntoCurrentWorkspaceMutation,
  useDeleteSharedApplicationMutation,
  useDeleteUserMutation,
  useModifyPermissionMutation,
  useShareApplicationMutation,
  useUpdateUserMutation,
  UsersDocument,
} from 'generated/graphql-operations'

export const useUsers = () => {
  const appId = useReactiveVar(setAppIdVar)
  const [createUser] = useCreateUserMutation({
    refetchQueries: [{query: UsersDocument}],
    onError,
  })

  const [addUserIntoCurrentWorkspace] = useAddUserIntoCurrentWorkspaceMutation({
    refetchQueries: [{query: UsersDocument}],
    onError,
  })

  const [updateUser] = useUpdateUserMutation({
    refetchQueries: [{query: UsersDocument}],
    onError,
  })

  const [changePassword] = useChangePasswordMutation({
    onError,
  })

  const [deleteUser] = useDeleteUserMutation({
    onError,
  })

  const [runDeleteSharedApp] = useDeleteSharedApplicationMutation({
    onCompleted: ({deleteSharedApplication}): void => {
      if (!deleteSharedApplication) throw Error()
    },
    onError,
  })

  const [shareApplication] = useShareApplicationMutation({
    refetchQueries: [{query: UsersWithSharedAppDocument, variables: {appId}}],
    onError,
  })

  const [modifyPermission] = useModifyPermissionMutation({
    refetchQueries: [{query: UsersWithSharedAppDocument, variables: {appId}}],
    onError,
  })

  return {
    createUser,
    addUserIntoCurrentWorkspace,
    updateUser,
    deleteUser,
    shareApplication,
    deleteSharedApplication: runDeleteSharedApp,
    modifyPermission,
    changePassword,
  }
}
