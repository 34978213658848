import {
  ChangeEvent,
  KeyboardEventHandler,
  Suspense,
  lazy,
  memo,
  useRef,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'

import {isValidColor} from 'utils'
import {presetColors} from 'templates/Settings/Widget/Styles/constants'
import {useOutsideClick} from 'hooks'

import {StyledInput} from 'templates/Settings/styles'

const SketchPicker = lazy(() => import('react-color/lib/components/sketch/Sketch'))

interface IProps {
  color: string
  name: string
  isDisabled?: boolean
  onChange: (color: string, name: string) => void
}

const ColorPicker = ({color, name, isDisabled = false, onChange}: IProps) => {
  const [showPicker, setShowPicker] = useState(false)
  const [displayColor, setDisplayColor] = useState(color.toLowerCase())

  useEffect(() => {
    color && setDisplayColor(color.toLowerCase())
  }, [color])

  const handleToggle = () => setShowPicker((open) => !open)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setDisplayColor(e.target.value.toLowerCase())

  const handleSave = () => {
    if (!isValidColor(displayColor)) return setDisplayColor(color)

    if (displayColor !== color && !isDisabled) {
      onChange(displayColor, name)
      setShowPicker(false)
    }
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.code === 'Escape') setDisplayColor(color)
    if (e.code === 'Enter' || e.code === 'NumpadEnter') handleSave()
  }

  const containerRef = useRef<HTMLDivElement>(null)
  useOutsideClick(containerRef, () => setShowPicker(false), showPicker)

  return (
    <Container ref={containerRef} data-testid='color-picker'>
      <ColorPreview
        type='button'
        disabled={isDisabled}
        $bgColor={isValidColor(displayColor) ? displayColor : undefined}
        onClick={handleToggle}
      />
      <StyledInput
        $w={70}
        $h={22}
        readOnly={isDisabled}
        onChange={handleChange}
        onBlur={handleSave}
        onKeyDown={handleKeyDown}
        value={displayColor}
      />

      {showPicker && !isDisabled && (
        <SliderWrapper>
          <Suspense fallback={null}>
            <SketchPicker
              color={displayColor}
              disableAlpha
              onChangeComplete={(colorAttr) => {
                onChange(colorAttr.hex, name)
                setDisplayColor(colorAttr.hex)
              }}
              presetColors={presetColors}
            />
          </Suspense>
        </SliderWrapper>
      )}
    </Container>
  )
}

export default memo(ColorPicker)

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 0;
  
  input {
    padding: 0;
    
    :read-only {
      border-bottom: none;
    }
  }
`

const SliderWrapper = styled.div`
  position: absolute;
  top: 100%;
  z-index: 1;
`

const ColorPreview = styled.button<{$bgColor?: string}>`
  background-color: ${({$bgColor}) => $bgColor};
  border-radius: 50%;
  border: 1px solid ${({theme}) => theme.colors.subhead};
  width: 18px;
  height: 18px;
  margin-right: 7px;
  
  &:disabled {
    cursor: default;
  }
`
