export const [DEFAULT_ROW_HEIGHT, DEFAULT_COL_WIDTH] = [30, 70]
export const [EXEC_DELAY, DRAG_DELAY] = [500, 100]
export const [CELL_MIN_WIDTH, CELL_MAX_WIDTH, CELL_MIN_HEIGHT, CELL_MAX_HEIGHT] = [1, 3000, 1, 3000]

export enum BtnConfig {
  MARGIN = 3,
  WIDTH = 12,
  HEIGHT = 12,
  INDENT = 10,
}

export const [activeClass, selectedClass, rightResizeHandle, bottomResizeHandle] = [
  'cell-active',
  'cell-selected',
  't-cell-resize-right',
  't-cell-resize-bottom',
]

export enum SelectionClass {
  SINGLE = 'ants-single',
  TOP = 'ants-t',
  RIGHT = 'ants-r',
  BOTTOM = 'ants-b', // ::after
  BOTTOM_BEFORE = 'ants-b-b', // ::before
  BOTTOM_REGULAR = 'ants-b-reg', // cell itself, the bottommost one in a single column
  LEFT = 'ants-l',
}
