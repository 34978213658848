import {TCellPosition} from 'apollo'
import {HEADER_HEIGHT, PAGE_WRAPPER} from 'static/constants'
import {pageGeometryType} from './PagePopupQuery'
import {WidgetGeometry} from 'generated/graphql-operations'

const SCROLL_HALF_WIDTH = 2

const setCenterPosition = (pageSize: number, popupSize: number, margin: number): number => {
  return Math.max(Math.trunc((pageSize - popupSize) / 2) + margin, 0)
}

export const getPosition = (
  pageSize: {[key in 'width' | 'height' | 'border']: number},
  popupSize: {width: number; height: number},
  widgetGeometry?: WidgetGeometry,
  cellPosition?: TCellPosition,
  index = 0,
): pageGeometryType => {
  const pageVisiblePart: Element = document.querySelectorAll(`.${PAGE_WRAPPER}`)[index]
  const {height: PVPHeight, top, left, width: PVPWidth} = pageVisiblePart.getBoundingClientRect()

  const topMargin = index || top - HEADER_HEIGHT
  const minPageWidth = Math.min(PVPWidth, pageSize.width)
  const minPageHeight = Math.min(PVPHeight, pageSize.height)

  const pageGeometry = {top: 0, left: 0, pageHeight: PVPHeight, pageWidth: PVPWidth}

  if (popupSize.height > PVPHeight) pageGeometry.top = topMargin
  else if (cellPosition) {
    const setCellPositionY = () => {
      const cellPos = cellPosition.y - (index ? top : HEADER_HEIGHT) + cellPosition.height

      if (cellPos + popupSize.height < PVPHeight + top - topMargin) return cellPos
      if (cellPos > popupSize.height + cellPosition.height) {
        return cellPos - popupSize.height - cellPosition.height
      }
      return setCenterPosition(minPageHeight, popupSize.height, topMargin)
    }

    pageGeometry.top = setCellPositionY()
  } else if (widgetGeometry) {
    const popupStart = widgetGeometry.y + widgetGeometry.height + pageSize.border + topMargin
    const paddingWidget = popupStart - (index || pageVisiblePart.scrollTop)

    const setWidgetPositionY = () => {
      if (PVPHeight > paddingWidget + popupSize.height - topMargin) return paddingWidget
      if (paddingWidget - widgetGeometry.height > popupSize.height) {
        return paddingWidget - popupSize.height - widgetGeometry.height
      }
      return setCenterPosition(minPageHeight, popupSize.height, topMargin)
    }

    pageGeometry.top = setWidgetPositionY()
  } else {
    const centerPositionY = setCenterPosition(minPageHeight, popupSize.height, topMargin)
    pageGeometry.top = centerPositionY > HEADER_HEIGHT ? centerPositionY : 0
  }

  if (popupSize.width > PVPWidth) pageGeometry.left = 0
  else if (cellPosition) {
    const setCellPositionX = () => {
      const cellPos = cellPosition.x - left
      if (cellPos + popupSize.width < PVPWidth) return cellPos
      if (cellPos > popupSize.width - cellPosition.width) {
        return cellPos - popupSize.width + cellPosition.width
      }
      return setCenterPosition(minPageWidth, popupSize.width, pageSize.border)
    }

    pageGeometry.left = setCellPositionX()
  } else if (widgetGeometry) {
    const popupStart = widgetGeometry.x - SCROLL_HALF_WIDTH
    const paddingWidget = popupStart - (index || pageVisiblePart.scrollLeft)

    const setWidgetPositionX = () => {
      if (PVPWidth > paddingWidget + popupSize.width) return paddingWidget
      if (paddingWidget + widgetGeometry.width > popupSize.width) {
        return paddingWidget - popupSize.width + widgetGeometry.width
      }
      return setCenterPosition(minPageWidth, popupSize.width, pageSize.border)
    }

    pageGeometry.left = setWidgetPositionX()
  } else {
    pageGeometry.left = setCenterPosition(minPageWidth, popupSize.width, pageSize.border)
  }

  return pageGeometry
}
