import {useCallback, useContext} from 'react'
import {useReactiveVar} from '@apollo/client'

import { deselectAllWidgets, setSelectedWidgetsVar } from "apollo";
import {useWidgets} from 'repository/graphql'
import {SaveUndoRedoContext} from 'repository/context'

export const useDelete = () => {
  const selectedWidgets = useReactiveVar(setSelectedWidgetsVar)

  const {deleteWidgets, copyToClipboard} = useWidgets()
  const saveHistory = useContext(SaveUndoRedoContext)

  return useCallback(
    async (ids?: number[]) => {
      if (!ids) {
        const {data} = await copyToClipboard({variables: {widgetIds: selectedWidgets}})
        data?.copyToClipboard &&
          saveHistory('deleteWidget', {content: data.copyToClipboard, ids: selectedWidgets})
      }

      const widgetIds = ids?.length ? ids : selectedWidgets
      deselectAllWidgets()
      await deleteWidgets({variables: {ids: widgetIds}})
    },
    [selectedWidgets, copyToClipboard, saveHistory, deleteWidgets],
  )
}
