import {RefObject, useEffect} from 'react'

export const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  callback: (event: MouseEvent) => void,
  isOpen = true,
) => {
  useEffect(() => {
    const handler: EventListenerOrEventListenerObject = (e) => {
      e.stopPropagation()

      if (isOpen && ref.current && !ref.current.contains(e.target as Node)) {
        callback(e as MouseEvent)
      }
    }

    document[isOpen ? 'addEventListener' : 'removeEventListener']('click', handler)

    return () => {
      document.removeEventListener('click', handler)
    }
  }, [callback, isOpen, ref])
}
