/* eslint-disable max-lines */
import {MouseEvent as ReactMouseEvent} from 'react'
import {ResizableProps} from 're-resizable'

import {bottomResizeHandle, rightResizeHandle} from './config'
import {getUrlParam} from 'hooks'
import {IActiveCellPosition, TableDataT} from './tableTypes'
import {
  cache,
  inactiveCell,
  IWidgetEditableEvaluated,
  setActiveCellTableVar,
  setMultiSelectCellsVar,
  setTableWidgetStateVar,
  setWidgetScrollPositions,
  TableWidgetState,
  TActiveCell,
  TCellCoordinate,
  TScrollProps,
  TScrollValue,
  ValueType,
} from 'apollo'
import {
  Maybe,
  WidgetViewType,
  Widget,
  WidgetsByPageIdDocument,
  WidgetsByPageIdQuery,
} from 'generated/graphql-operations'

export const isCellReadOnly = (
  activeCell: TActiveCell,
  editable: IWidgetEditableEvaluated,
): boolean => {
  const {x, y} = activeCell
  const cellSelected = typeof x === 'number' && typeof y === 'number'
  return cellSelected && !editable.value?.[y]?.[x]
}

type TParams = {
  isLiveMode: boolean
  tableEnabled: Widget['enabled']
  tableEditable: Widget['editableEvaluated']
  y: number
  x: number
}
export const isCellDisabledInLiveMode = ({
  isLiveMode,
  tableEditable,
  tableEnabled,
  x,
  y,
}: TParams): boolean => {
  return isLiveMode && !tableEnabled && !tableEditable.value[y]?.[x]
}

// DEFINE CELLS COORDINATES TO HIGHLIGHT THEM
export const defineSelectedCells = (
  dataset: DOMStringMap | TCellCoordinate,
  activeCellTable: {x: TActiveCell['x']; y: TActiveCell['y']},
): TCellCoordinate[] => {
  const cells: TCellCoordinate[] = []
  const [datasetX, datasetY] = [Number(dataset.x), Number(dataset.y)]
  const {x, y} = activeCellTable

  if (x === datasetX && y === datasetY) return [{x, y}]

  if (typeof y === 'number' && typeof x === 'number') {
    const [maxX, minX] = [Math.max(x, datasetX), Math.min(x, datasetX)]
    const [maxY, minY] = [Math.max(y, datasetY), Math.min(y, datasetY)]

    for (let i = minY; i <= maxY; i++) {
      for (let j = minX; j <= maxX; j++) {
        cells.push({x: j, y: i})
      }
    }
  }

  return cells
}

export const getCellState = (
  rowIndex: number,
  columnIndex: number,
  selectedCells: ReturnType<typeof setMultiSelectCellsVar>,
  activeCell: {x: TActiveCell['x']; y: TActiveCell['y']},
): {isSelected: boolean; isActive: boolean} => ({
  isSelected: selectedCells?.some((cell) => cell.y === rowIndex && cell.x === columnIndex) ?? false,
  isActive: rowIndex === activeCell.y && columnIndex === activeCell.x,
})

type TCell = {
  pos: IActiveCellPosition
  newValue: ValueType
}
export const insertData = (
  currTableData: TableDataT,
  {pos: {x, y}, newValue}: TCell,
): TableDataT => {
  return currTableData.map((row, rowIndex) => {
    if (rowIndex === y) {
      return row.map((col, colIndex) => (colIndex === x ? newValue : col))
    }
    return row
  })
}

export const removeValue = (tData: TableDataT, pos: IActiveCellPosition): TableDataT =>
  insertData(tData, {pos, newValue: ''})

export const removeValues = (tData: TableDataT, positions: IActiveCellPosition[]): TableDataT => {
  return tData.map((row, rowIndex) => {
    if (positions.some(({y}) => y === rowIndex)) {
      return row.map((col, colIndex) => (positions.some(({x}) => x === colIndex) ? '' : col))
    }
    return row
  })
}

// "Row below" mode, when cursor is in the last row:
// [['a'], ['b']] ==> [['a'], ['b'], ['']]
// Default mode, when cursor is in the last row:
// [['a'], ['b']] ==> [['a'], [''], ['b']]
export const addRow = (
  currTableData: TableDataT,
  rowIndex: number,
  insertRowBelow?: boolean,
): TableDataT => {
  const columnCount = currTableData[0].length
  const isLastRow = rowIndex === currTableData.length - 1
  return [
    ...currTableData.slice(0, rowIndex + Number(isLastRow && !!insertRowBelow)),
    Array(columnCount).fill(''),
    ...(isLastRow && insertRowBelow ? [] : currTableData.slice(rowIndex)),
  ]
}

// "Col right" mode, when cursor is in the last col:
// [['a', 'b', 'c']] ==> [['a', 'b', 'c', '']]
// Default mode, when cursor is in the last col:
// [['a', 'b', 'c']] ==> [['a', 'b', '', 'c']]
export const addColumn = (
  currTableData: TableDataT,
  colIndex: number,
  insertColRight?: boolean,
): TableDataT => {
  const newData: TableDataT = []
  const isLastCol = colIndex === currTableData[0].length - 1
  for (const row of currTableData) {
    newData.push([
      ...row.slice(0, colIndex + Number(isLastCol && !!insertColRight)),
      '',
      ...(isLastCol && insertColRight ? [] : row.slice(colIndex)),
    ])
  }
  return newData
}

// Table rows are delimited by new lines, but the cells themselves
// (those in table as well as individual ones) can contain new lines too.
// To distinguish between the two, we use the following convention:
export const [SINGLE_CELL_NEWLINE, SINGLE_CELL_NEWLINE_RE] = ['\\n', /\\n/g]
export const [MULTI_CELL_NEWLINE, MULTI_CELL_NEWLINE_RE] = ['\\_n', /\\_n/g]

const removeCRLF = (text: string) => {
  const regex = /\r\n$/;
  return text.replace(regex, '');
}

const tsvToTableData = (tsv: string): TableDataT => {
  // Single cell, but with specially encoded new lines.
  if (tsv.includes(SINGLE_CELL_NEWLINE) && !tsv.includes('\t')) {
    return [[tsv.replace(SINGLE_CELL_NEWLINE_RE, '\n')]]
  }

  // Regular table content, cells may or may not have new lines.
  if (/[\n\t]/.test(tsv)) {
    return tsv
      .split('\n')
      .map((row) => row
        .replace(MULTI_CELL_NEWLINE_RE, '\n')
        .replace(/\r/g, '')
        .split('\t')
      )
  }

  return [[tsv]] // Only a single cell was pasted, for sure.
}

export const tableDataToTSV = (multiSelectCells: TCellCoordinate[], data: string[][]): string => {
  const transformIndexesToContent: string[][] = []
  let row = 0

  for (let i = 0, total = multiSelectCells.length; i < total; i++) {
    if (row !== multiSelectCells[i].y) {
      transformIndexesToContent.push([])
      row = multiSelectCells[i].y
    }

    if (!transformIndexesToContent.length) {
      transformIndexesToContent.push([])
    }

    transformIndexesToContent[transformIndexesToContent.length - 1].push(
      data[multiSelectCells[i].y][multiSelectCells[i].x],
    )
  }

  return (
    transformIndexesToContent
      // Line breaks in table cell will be replaced with MULTI_CELL_NEWLINE
      // to correctly transform the resulting string back to the 2-dimensional array
      // (and still keep the cell's line breaks).
      .reduce((acc, curr) => acc.concat([curr.join('\t').replace(/\n/g, MULTI_CELL_NEWLINE)]), [])
      .join('\n')
  )
}

type TCopyPayload = {
  updatedTableData: TableDataT
  copiedValues: TableDataT
}

export const pasteTableData = (
  clipboardData: string,
  tableData: TableDataT,
  {x, y}: IActiveCellPosition,
  onMultiSelectUpdate: (newPositions: TCellCoordinate[]) => void,
): TCopyPayload => {
  const copiedValues = tsvToTableData(removeCRLF(clipboardData))
  let [columnPos, rowPos] = [x, y]
  let updatedTableData = tableData.slice()
  const [totalRows, totalCols] = [updatedTableData.length, updatedTableData[0].length]
  const [totalClipboardRows, totalClipboardCols] = [copiedValues.length, copiedValues[0].length]

  // ADDITIONAL COLUMNS AFTER COPY
  if (x !== null && totalClipboardCols + x > totalCols) {
    const deltaCols = totalClipboardCols + x - totalCols
    for (let i = 0; i < deltaCols; i++) {
      updatedTableData = updatedTableData.map((r) => [...r, ''])
    }
  }

  // ADDITIONAL ROWS AFTER COPY
  if (y !== null && totalClipboardRows + y > totalRows) {
    const deltaRows = totalClipboardRows + y - totalRows
    for (let i = 0; i < deltaRows; i++) {
      updatedTableData = [...updatedTableData, Array(updatedTableData[0].length).fill('')]
    }
  }

  const multiSelectCellsCopy: TCellCoordinate[] = []

  copiedValues.forEach((value) => {
    value.forEach((nestedValue) => {
      updatedTableData = updatedTableData.map((row, rowIndex) => {
        if (rowPos === rowIndex) {
          return row.map((col, colIndex) => (columnPos === colIndex ? nestedValue : col))
        }
        return row
      })

      if (columnPos !== null) {
        columnPos += 1
        if (rowPos !== null) {
          multiSelectCellsCopy.push({x: columnPos - 1, y: rowPos})
        }
      }
    })

    columnPos = x
    if (rowPos !== null) rowPos += 1
  })

  onMultiSelectUpdate(multiSelectCellsCopy) // side effect, would be good to extract it from here...
  return {updatedTableData, copiedValues}
}

export const deselectCell = () => {
  setActiveCellTableVar(inactiveCell)
  setTableWidgetStateVar(TableWidgetState.SELECTED)
  setMultiSelectCellsVar(null)
}

export const handleTableReset = () => {
  setActiveCellTableVar(inactiveCell)
  setTableWidgetStateVar(TableWidgetState.UNSELECTED)
  setMultiSelectCellsVar(null)
}

export const enableResizing = (
  isEditMode: boolean,
  colIndex: number,
  tableType: WidgetViewType,
): ResizableProps['enable'] => {
  return {
    bottom: isEditMode && (tableType === 'HIERARCHY' ? colIndex > 0 : tableType !== 'ROW'),
    right: isEditMode && (tableType === 'HIERARCHY' ? colIndex > 0 : tableType !== 'COLUMN'),
  }
}

export const displayToggleBtn = (currValue: number, nextValue: number): boolean => {
  if (currValue && nextValue) {
    return currValue < 0 || Math.abs(nextValue) - Math.abs(currValue) === 1
  }
  return false
}

export const isTableCell = (elem?: HTMLElement | null): boolean =>
  !!elem && 'x' in elem.dataset && 'y' in elem.dataset

export const isTableElem = (e: ReactMouseEvent): boolean => {
  const target = e.target as Node as HTMLElement

  // input/textarea is wrapped in div, the parent of which is another div, i.e. our cell:)
  if (target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement)
    return isTableCell(target?.parentElement?.parentElement)

  return (
    target.classList.contains(bottomResizeHandle) ||
    target.classList.contains(rightResizeHandle) ||
    isTableCell(target) ||
    target.dataset.testid === 'at-table-wrapper' ||
    target.dataset.testid === 'at-cell-actions-btn' ||
    !!target.dataset.testid?.includes('at-cell-menu-action-item')
  )
}

// Scroll recording is based off of a concept of a single common relative:
// if Bob and Pete are brothers of John, that means Bob and Pete are also brothers to each other.
// E.g., if table A and table B are linked to the table C, then:
// * scrolling of table C will scroll tables A and B;
// * scrolling of table A will scroll tables B and C;
// * scrolling of table B will scroll tables A and C.
// This function returns a dictionary of { scrollRefId: [id1, id2, ...] } records.
// The array contains widgets that have a common scrollRefId.
// If any of those values is scrolled, the dependent widgets will be scrolled as well.
type ScrollRefMap = {[scrollRefId: string]: number[]}
export const getScrollRefIdMap = (pageId?: number): ScrollRefMap | null => {
  const query: Maybe<WidgetsByPageIdQuery> = cache.readQuery({
    query: WidgetsByPageIdDocument,
    variables: {pageId: pageId || Number(getUrlParam(location.pathname, 'page')), isFirstOpening: false},
  })

  const widgetsOnPage = query?.getWidgetsByPageId.affectedWidgets
  if (widgetsOnPage && widgetsOnPage?.length) {
    const scrollRefIds = widgetsOnPage.reduce<number[]>((acc, curr) => {
      if (curr.type === 'TABLE' && curr.scrollRefId) acc.push(curr.scrollRefId)
      return acc
    }, [])

    if (!scrollRefIds.length) return null

    // Look up for the widgets that have a common scrollRefId
    return scrollRefIds.reduce<ScrollRefMap>((acc, curr) => {
      const dependants = widgetsOnPage.reduce<number[]>((deps, w) => {
        if (w.scrollRefId === curr) deps.push(w.id)
        return deps
      }, [])

      return {
        ...acc,
        ...dependants.length && {[curr]: dependants},
      }
    }, {})
  }

  return null
}

export const updateScrollProps = (
  id: Widget['id'],
  currProps: TScrollProps,
  newProps: TScrollValue,
) => {
  const updatedPositions = currProps
    ? Object.entries(currProps).reduce(
        (acc, [wid, values]) => ({
          ...acc,
          ...{[wid]: {...values, scrolledByUser: false}},
        }),
        {},
      )
    : null

  setWidgetScrollPositions({
    ...updatedPositions,
    [id]: {
      top: newProps.top,
      left: newProps.left,
      scrolledByUser: newProps.scrolledByUser,
    },
  })
}
