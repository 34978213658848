export const clamp = (num: number, min: number, max: number): number =>
  Math.min(Math.max(num, min), max)

export function limitNum(num: number, max: number): number {
  if (isNaN(num)) return 0
  const val = Math.abs(num)
  const limit = Number.isInteger(num) ? max : max + 1
  const strNum = val.toString()
  const trimmedVal = strNum.length > limit ? +strNum.slice(0, limit) : +strNum
  return trimmedVal * (num < 0 ? -1 : 1)
}

// The max and min will be inclusive
export function getRandomIntInclusive(min: number, max: number): number {
  const minV = Math.ceil(min),
        maxV = Math.floor(max)
  return Math.floor(Math.random() * (maxV - minV + 1) + minV)
}
