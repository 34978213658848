import {PageWidget} from 'apollo'

export const PAGE_WRAPPER = 'pageWrapper'
export const PAGE_SCROLL = 'pageScroll'
export const SELECTABLE_PAGE_BLOCK = 'SelectablePageBlock'

export const PAGE_BORDER_SIZES = ['1', '2', '4', '6']

export interface IPageSize {
  height: number
  width: number
}

export interface IPopupPage {
  pageId: number
  widgetId?: number | null
  isCell?: boolean
  status: 'closeAll' | 'close' | 'openPopup' | 'openPage' | 'inProcess'
}

export const PAGE_SIZE = {
  height: 768,
  width: 1024,
  minHeight: 20,
  maxHeight: 5000,
  minWidth: 20,
  maxWidth: 5000,
} as const

// Calculate the minimum allowed pageWidth/pageHeight based on the edges
// of its rightmost and bottommost widgets.
export const getMinPageSize = (widgets: PageWidget[]) => {
  const rightEdges = [], bottomEdges = []
  for (const {geometry} of widgets) {
    rightEdges.push(geometry.x + geometry.width)
    bottomEdges.push(geometry.y + geometry.height)
  }

  const [rightmost, bottommost] = [Math.max(...rightEdges), Math.max(...bottomEdges)]

  return {
    minWidth: Math.max(rightmost, PAGE_SIZE.minWidth),
    minHeight: Math.max(bottommost, PAGE_SIZE.minHeight),
  }
}
