export const isProductionNedyxEnv =
  process.env.REACT_APP_ENV &&
  ['production', 'preview', 'preview-k8s'].includes(process.env.REACT_APP_ENV)

export const isTestEnv = process.env.NODE_ENV === 'test'
export const isDevEnv = process.env.NODE_ENV === 'development'

const RESTRICTED_ERRORS = [
  /Internal server error/i,
  /Failed/,
  /No auth token/,
  /This operation is not yet supported/,
  /Unauthorized/,
  /Page with id/,
  /Widget with id/,
  /Unknown argument/,
  /Variable/i,
  /invalid input syntax /i,
]

export const isRestrictedError = (text: string) =>
  RESTRICTED_ERRORS.filter((item) => text.match(item)).length > 0

export const shouldSuppressError = (err: string) => isProductionNedyxEnv && isRestrictedError(err)
