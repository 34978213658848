import {WidgetGeometry} from 'generated/graphql-operations'
import {PageWidget} from 'apollo'
import {IPageSize} from 'static/constants'

export const OFFSET_VALUE = 10

type TConfig = {
  pageSize: IPageSize
  widgetsGeometry: WidgetGeometry[]
  copyWidgets: PageWidget[]
}

export const verifyCopyPositions = ({pageSize, widgetsGeometry, copyWidgets}: TConfig) => {
  const geometries = [...widgetsGeometry]
  const updatedWidgets = []
  let widgetOrderBiggest = 0

  for (const {order} of geometries) {
    if (order > widgetOrderBiggest) widgetOrderBiggest = order
  }

  for (const widget of copyWidgets) {
    const {x: currX, y: currY, width: currWidth, height: currHeight, order} = widget.geometry

    const verifyWidgetGeo = (currentOffset: number): number => {
      const hasSamePos = geometries.some((geometry) => {
        const {x, y, width, height} = geometry
        return (
          currX + currentOffset === x &&
          currY + currentOffset === y &&
          currHeight === height &&
          currWidth === width
        )
      })
      return hasSamePos ? verifyWidgetGeo(currentOffset + OFFSET_VALUE) : currentOffset
    }

    const positionOffset = verifyWidgetGeo(0)
    const xPos =
      pageSize.width > currX + currWidth + positionOffset
        ? currX + positionOffset
        : pageSize.width - currWidth

    const yPos =
      pageSize.height > currY + currHeight + positionOffset
        ? currY + positionOffset
        : pageSize.height - currHeight

    const extraOrder = copyWidgets.reduce((sum, {geometry}) => {
      return geometry.order <= order ? sum + 1 : sum
    }, 0)

    const geometry = {
      height: currHeight,
      width: currWidth,
      x: Math.max(xPos, 0),
      y: Math.max(yPos, 0),
      order: widgetOrderBiggest + extraOrder,
    }

    geometries.push(geometry)
    updatedWidgets.push({...widget, geometry})
  }

  return updatedWidgets
}
