import {CSSProperties} from 'react'
import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 16px;
`

export const Wrapper = styled.section`
  padding: 12px 8px 2px 16px;
  border-radius: ${(p) => p.theme.brdr.main};
  margin-bottom: 4px;
  background-color: ${({ theme }) => theme.colors.lightest};
`

export const Title = styled.h5<{$isChecked?: boolean}>`
  color: ${({$isChecked = true, theme: {colors}}) => $isChecked ? colors.text : colors.subhead};
  font-size: inherit;
  font-weight: 400;
`

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 20px;
  }
`

export const StyledLabel = styled.label`
  width: 20px;
  color: ${({theme}) => theme.colors.subhead};
`

export const StyledInput = styled.input<{
  $h?: CSSProperties['height']
  $w?: CSSProperties['width']
}>`
  width: ${({$w = 80}) => typeof $w === 'number' ? `${$w}px` : $w};
  height: ${({$h = 29}) => typeof $h === 'number' ? `${$h}px` : $h};
  padding: 4px 8px;
  font-weight: 700;
  color: ${({theme}) => theme.colors.text};
  border-bottom: 1px solid ${({theme}) => theme.colors.light};

  &:hover:not(:disabled) {
    border-bottom-color: currentColor;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
