import {ButtonHTMLAttributes} from 'react'
import styled from 'styled-components'

import {ReactComponent as XIcon} from 'static/images/svgs/close.svg'

// It's intended to be used with modals, but you may find other applications for it.
export const CloseButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <StyledButton {...props}>
    <XIcon aria-hidden='true' />
    <span className='visually-hidden'>Close</span>
  </StyledButton>
)

const StyledButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: ${({theme}) => theme.colors.subhead};
  background-color: transparent;
  transform: translateX(5px); // pixel perfect concern; if it gets in the way, just remove it

  &:hover,
  &:focus {
    color: ${({theme}) => theme.colors.text};
  }

  svg {
    pointer-events: none;
  }
`
