import {useCallback} from 'react'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'

import {ContainerId} from 'components/Toast/config'
import {getWorkspace} from './usePageNavigation'
import {setIsAuthenticatedVar} from 'apollo'
import {setUserToken, removeUser} from 'repository/services'
import {useLogoutMutation} from 'generated/graphql-operations'

export type LoginUserData = {email: string; password: string}
export type LoginType = 'keycloak' | 'login' | 'ldap'

export const loginOption: LoginType = Number(process.env.REACT_APP_KEYCLOAK)
  ? 'keycloak'
  : Number(process.env.REACT_APP_LDAP)
  ? 'ldap'
  : 'login'

const GENERIC_ERROR = 'Something went wrong, please try again'

export const useLogin = () => {
  return useCallback(async (type: LoginType, {email, password}: LoginUserData) => {
    const workspaceName = getWorkspace()

    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/${type}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          workspacename: workspaceName,
        },
        body: JSON.stringify({
          [type === 'ldap' ? 'username' : 'email']: email,
          password,
        }),
      })

      const user = await response.json()

      if (response.status >= 400) {
        return toast.error(user.message || GENERIC_ERROR, {
          toastId: 'login-network-error',
          containerId: ContainerId.ROOT,
        })
      }

      if (user.userDetails.warning) {
        toast.error(user.userDetails.warning, {
          toastId: 'login-user-details-warning',
          containerId: ContainerId.ROOT,
        })
      }

      const resolvedWorkspace = user.userDetails.workspaceName
      const lastVisitedUrl = sessionStorage.getItem('lastVisitedUrl')

      const showLastVisitedUrl = lastVisitedUrl && lastVisitedUrl !== 'visited'
      const url = showLastVisitedUrl ? lastVisitedUrl : `/${resolvedWorkspace}/apps`

      setIsAuthenticatedVar(true)
      sessionStorage.setItem('lastVisitedUrl', 'visited')
      localStorage.setItem('workspaceName', resolvedWorkspace)
      setUserToken({accessToken: user.accessToken})

      window.location.replace(url)
    } catch (error) {
      toast.error('We could not sign you in. Please try again later.', {
        toastId: 'login-error',
        containerId: ContainerId.ROOT,
      })
    }
  }, [])
}

export const useLogout = () => {
  const [requestLogout] = useLogoutMutation()

  return useCallback(async () => {
    try {
      await requestLogout()
    } catch (e) {
      toast.error(GENERIC_ERROR, {toastId: 'logout-error', containerId: ContainerId.ROOT})
    }

    removeUser()
    ;['userData', 'pagesByApps'].forEach((value) => localStorage.removeItem(value))

    const lastVisitedUrl = sessionStorage.getItem('lastVisitedUrl');
    sessionStorage.clear()
    if (lastVisitedUrl === 'visited') {
      sessionStorage.setItem('lastVisitedUrl', 'reload');
    }

    // Cache and reactive variables will be cleared on page refresh.
    // https://www.apollographql.com/docs/react/networking/authentication/#reset-store-on-logout
    window.location.reload()
  }, [requestLogout])
}

export const useResetPassword = () => {
  return useCallback(async (email: string, link: string) => {
    try {
      await fetch(`${process.env.REACT_APP_URL}/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email, link}),
      })
      return true
    } catch (error) {
      toast.error(GENERIC_ERROR, {
        toastId: 'restore-password-fetch-error',
        containerId: ContainerId.ROOT,
      })
      return false
    }
  }, [])
}

export const useUpdatePassword = () => {
  const navigate = useNavigate()

  return useCallback(
    async (email: string, newPassword: string, resetToken: string) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_URL}/change-password-without-auth`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            newPassword,
            resetToken,
          }),
        })
        const responseJson = await response.json()
        if (responseJson.status === 401) {
          return false
        }
        toast.success('Password was updated successfully', {containerId: ContainerId.ROOT})
        navigate('/login', {replace: true})
        return true
      } catch (error) {
        toast.error(`Error updating password: ${(error as Error).message}`, {
          toastId: 'update-password-error',
          containerId: ContainerId.ROOT,
        })
      }
    },
    [navigate],
  )
}
