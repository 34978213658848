import {useCallback} from 'react'
import {toast} from 'react-toastify'
import {useReactiveVar} from '@apollo/client'

import {useWidgets} from 'repository/graphql'
import {cache, setActivePageIdVar} from 'apollo'
import {
  Maybe,
  PageBaseFragment,
  PageBaseFragmentDoc,
  WidgetFieldsFragmentDoc,
  WidgetFieldsFragment,
  Widget,
} from 'generated/graphql-operations'
import {useDelete} from 'templates/Widget/WidgetActionsPanel/hooks/useDelete'
import {ContainerId} from 'components/Toast/config'

export type TReturnData = {
  content: string
  ids: number[]
}

export type TRemoveData = Widget['id'][]
export type TRestoreData = {newIds: TRemoveData; oldIds: TRemoveData}

export const useLiveWidgetRollback = () => {
  const {pasteFromClipboard} = useWidgets()
  const handleDeleteWidget = useDelete()
  const activePageId = useReactiveVar(setActivePageIdVar)

  const runReturnWidget = useCallback(
    async ({content, ids}: TReturnData): Promise<void | TRestoreData> => {
      try {
        const {data} = await pasteFromClipboard({variables: {content}})
        const newIds = data?.pasteFromClipboard.affectedWidgets.map(({id}) => id)
        if (newIds?.length) return {newIds, oldIds: ids}
      } catch (e) {
        toast.warning('Lost undo-redo of widget rollback', {containerId: ContainerId.ROOT})
      }
    },
    [pasteFromClipboard],
  )

  const runRemoveWidget = useCallback(
    async (widgetIds: TRemoveData): Promise<TReturnData | void> => {
      try {
        const content = []

        const activePage: Maybe<PageBaseFragment> = cache.readFragment({
          id: `Page:${activePageId}`,
          fragment: PageBaseFragmentDoc,
        })

        for (const id of widgetIds) {
          const data: Maybe<WidgetFieldsFragment> = cache.readFragment({
            id: `Widget:${id}`,
            fragment: WidgetFieldsFragmentDoc,
            fragmentName: 'WidgetFields',
          })

          if (!data) return

          content.push({
            format: data.format,
            geometry: data.geometry,
            enabled: data.enabled,
            editable: data.editableEvaluated,
            title: data.title,
            cellStyles: data.cellStyles,
            styles: data.styles,
            type: data.type,
            rawData: data.data,
            visible: data.visible,
            titleInfo: {pageTitle: activePage?.title},
            actions: data.actions.map(({code, type}) => ({code, type})),
          })
        }

        await handleDeleteWidget(widgetIds)

        return {
          content: JSON.stringify({widgetList: content, pageId: activePageId}),
          ids: widgetIds,
        }
      } catch (e) {
        toast.warning('Lost undo-redo of widget removal', {containerId: ContainerId.ROOT})
      }
    },
    [activePageId, handleDeleteWidget],
  )

  return {runRemoveWidget, runReturnWidget}
}
