import styled from 'styled-components';

export const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${({theme}) => theme.colors.lightest};
  overflow: hidden;
`

export const Inner = styled.div`
  width: 410px;
  padding: 40px 30px 30px;
  text-align: center;
  border-radius: ${({theme}) => theme.brdr.main};
  color: ${({theme}) => theme.colors.subhead};
  background-color: ${({theme}) => theme.colors.mainBackground};
  box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.15);
  overflow: auto;

  a #logo-text path {
    fill: ${({theme}) => theme.colors.darkMain};
  }

  h1 {
    position: relative;
    padding-top: 60px;
    margin-top: 45px;
    font-size: 32px;
    font-weight: 300;
    color: ${({theme}) => theme.colors.header};

    &::before,
    svg {
      position: absolute;
      right: 0; left: 0;
      margin-right: auto;
      margin-left: auto;
    }

    &::before {
      content: "";
      top: 0;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background-color: ${({theme}) => theme.colors.error};
    }

    svg {
      top: 14px;
      width: 16px;
      height: 16px;
      pointer-events: none;

      path {
        stroke: ${({theme}) => theme.colors.mainBackground};;
      }
    }
  }

  p {
    padding-right: 7%;
    padding-left: 7%;
    margin-top: 1em;
    margin-bottom: 2em;
    font-size: 16px;
    line-height: 1.5;
  }

  summary {
    cursor: pointer;
  }

  details {
    position: relative;
    margin-top: 50px;
    text-align: left;
  }

  code {
    display: block;
    max-height: 200px;
    overflow: auto;
    padding: .5em;
    margin-top: 1em;
    white-space: pre-wrap;
    word-break: break-word;
    font-size: 12px;
    color: ${({theme}) => theme.colors.error};
    border-radius: ${({theme}) => theme.brdr.main};
    background-color: ${({theme}) => theme.colors.outerBorder};
  }

  button:active {
    transform: translateY(1px);
  }
`;

export const CopyBtn = styled.button`
  position: absolute;
  top: 35px;
  right: 10px;
  width: 20px;
  height: 20px;
  line-height: 25px;
  border-radius: ${({theme}) => theme.brdr.main};
  background-color: rgba(232, 232, 232, .55);
  
  &:hover {
    opacity: 1;
    background-color: ${({theme}) => theme.colors.outerBorder};
  }
  
  svg {
    pointer-events: none;
  }
`
export const CopyText = styled.span`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: ${({theme}) => theme.colors.mainBackground};
  box-shadow: ${({theme}) => theme.shadow.medium};
`
