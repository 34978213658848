import {IList} from 'components'
import {handleStylesSync} from '../WidgetTypes/Table/hooks/useSyncWidgetDimensions'
import {stopDistance} from 'static/constants'
import {SetWidgetsStylesMutationHookResult, WidgetGeometry} from 'generated/graphql-operations'
import {IWidgetGeometryIncludeKeyID, IWidgetGeometryWidthID} from '../../../apollo'

interface IProps {
  onStylesUpdate: SetWidgetsStylesMutationHookResult[0]
  pageSize: {width: number; height: number}
  selectedWidgets: number[]
  setGeometry: (arg: IWidgetGeometryWidthID[]) => void
  widgetsGeometry: IWidgetGeometryIncludeKeyID
}

export const sizeAction = ({
  onStylesUpdate,
  pageSize,
  selectedWidgets,
  setGeometry,
  widgetsGeometry,
}: IProps) => {
  const setSize = (axis: 'height' | 'width') => {
    const axisX = axis === 'width'
    const borderSize = stopDistance[axisX ? 'right' : 'bottom']
    const selectedWidgetsCopy = [...selectedWidgets]
    const activeWidgetId = selectedWidgetsCopy.pop()
    if (!selectedWidgetsCopy.length || !activeWidgetId) return

    const activeWidgetGeo = widgetsGeometry[activeWidgetId]

    const checkSize = (geo: WidgetGeometry, size: number) => {
      const pos = geo[axisX ? 'x' : 'y']
      const hasBiggerSize = pos + size + borderSize - pageSize[axis]
      return hasBiggerSize > 0 ? size - hasBiggerSize : size
    }

    const [newGeometriesWithId, newGeometriesById] = [[], {}] as [
      IWidgetGeometryWidthID[],
      IWidgetGeometryIncludeKeyID,
    ]
    for (const id of selectedWidgetsCopy) {
      const newGeo = {
        ...widgetsGeometry[id],
        [axis]: checkSize(widgetsGeometry[id], activeWidgetGeo[axis]),
      }
      newGeometriesById[id] = newGeo
      newGeometriesWithId.push({...newGeo, id})
    }

    setGeometry(newGeometriesWithId)
    handleStylesSync(selectedWidgetsCopy, newGeometriesById, onStylesUpdate)
  }

  return [
    {
      name: 'Apply same width',
      action: () => setSize('width'),
    },
    {
      name: 'Apply same height',
      action: () => setSize('height'),
    },
  ] as IList[]
}
