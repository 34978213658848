import {useEffect} from 'react'

// This hook provides syntactic sugar for registering keyboard events,
// but the tradeoff will be that the `Event Listeners` tab will give you no info about
// which key is being listened to, which component dispatched it, etc.
// So, use it wisely. In most cases, it will be better to recreate
// this boilerplate inside your component function. Clarity over brevity.
export const useKeyCode = (
  keys: string[],
  callback: (event?: KeyboardEvent) => void,
  startSubscription = true,
) => {
  useEffect(() => {
    const handler: EventListenerOrEventListenerObject = (e) => {
      const evt = e as KeyboardEvent
      if (keys.includes(evt.code)) callback(evt)
    }

    window[startSubscription ? 'addEventListener' : 'removeEventListener']('keydown', handler)
    return () => {
      window.removeEventListener('keydown', handler)
    }
  }, [callback, keys, startSubscription])
}
