import {memo, useEffect} from 'react'
import {useReactiveVar} from '@apollo/client'

import {IGeoActions} from '../Page/hooks/useGeometryFunctions'
import {isMovedDetectionVar, PageWidget, setOpenedMenuId} from 'apollo'
import setWidgetDataVar from './hooks/setWidgetDataVar'
import {WidgetGeometry as SchemaWGeometry} from 'generated/graphql-operations'

import WidgetGeometry from './WidgetGeometry'

interface IProps extends IGeoActions {
  widget: PageWidget
  geometry: SchemaWGeometry
  isActive?: boolean
  isEditMode?: boolean
  isSelected?: boolean
}

const WidgetView = ({
  widget,
  geometry,
  isActive = false,
  isEditMode = false,
  isSelected = false,
  setGeometry,
  setPositionKeyThrottle,
  changeGeometry,
}: IProps) => {
  const openedMenuId = useReactiveVar(setOpenedMenuId)
  const isStatic = !useReactiveVar(isMovedDetectionVar)

  useEffect(() => {
    isActive && setWidgetDataVar(widget)
  }, [isActive, widget])

  return (
    <WidgetGeometry
      data-testid='widget-block'
      widget={widget}
      geometry={geometry}
      isActive={isActive}
      isEditMode={isEditMode}
      isMenuOpen={widget.type === 'MENU' && openedMenuId === widget.id}
      isSelected={isSelected}
      isStatic={isStatic}
      setGeometry={setGeometry}
      setPositionKeyThrottle={setPositionKeyThrottle}
      changeGeometry={changeGeometry}
    />
  )
}

export default memo(WidgetView)
