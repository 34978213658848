import styled from 'styled-components'

import colors from 'theme/colors'
import {ZOrder} from 'theme/constants'

export const TooltipContainer = styled.div`
  display: block;
  position: relative;
  line-height: 0;
  cursor: pointer;

  &.parentLineHeight {
    line-height: inherit;
  }

  &.parentMenu {
    width: 100%;
    height: inherit;
    line-height: inherit;

    & > div {
      width: 100%;
    }
  }

  &.parentHeight {
    line-height: inherit;
    height: inherit;
  }
`

export const TooltipContent = styled.div<{$positionY: number; $positionX: number; $isHtml: boolean}>`
  visibility: hidden;
  opacity: 0;
  position: ${(p) => (p.$positionY ? 'fixed' : 'absolute')};
  z-index: ${ZOrder.TOOLTIP};
  display: block;
  padding: ${(p) => (p.$isHtml ? 0 : '4px 8px')};
  border-radius: 4px;
  color: ${({theme}) => theme.colors.mainBackground};
  background-color: ${({theme}) => theme.colors.text};
  font-size: 13px;
  white-space: nowrap;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
  line-height: 1.54;
  transition: opacity 0.25s ease-out, transform 0.2s ease-out;

  &::before {
    content: '';
    position: absolute;
    border-width: 5px;
    border-style: solid;
  }

  &.tooltip__inner--widget {
    top: ${(p) => p.$positionY}px;
    left: ${(p) => p.$positionX}px;
    background: white;
    color: black;

    &::before {
      display: none;
    }
  }

  &.tooltip__inner--fixed-container {
    top: ${(p) => p.$positionY}px;
    right: 12px;

    &::before {
      left: 50%;
      bottom: 0;
      transform: translateX(-50%) translateY(80%);
      border-color: ${colors.text} transparent transparent transparent;
    }
  }

  &.tooltip__inner--active {
    visibility: visible;
    opacity: 1;
  }

  &.tooltip__inner--top,
  &.tooltip__inner--bottom {
    left: 50%;

    &::before {
      left: 50%;
    }
  }

  &.tooltip__inner--top {
    bottom: 100%;
    transform: translate(-50%, 10px);

    &::before {
      bottom: 0;
      transform: translateX(-50%) translateY(80%);
      border-color: ${colors.text} transparent transparent transparent;
    }

    &.tooltip__inner--active {
      transform: translate(-50%, -8px);
    }
  }

  &.tooltip__inner--bottom {
    top: 100%;
    transform: translate(-50%, -10px);

    &::before {
      top: 0;
      transform: translateX(-50%) translateY(-80%);
      border-color: transparent transparent ${colors.text} transparent;
    }

    &.tooltip__inner--active {
      transform: translate(-50%, 8px);
    }
  }

  &.tooltip__inner--right,
  &.tooltip__inner--left {
    top: 50%;

    &::before {
      top: 50%;
    }
  }

  &.tooltip__inner--right {
    left: 100%;
    transform: translate(-10px, -50%);

    &::before {
      left: 0;
      transform: translateX(-80%) translateY(-50%);
      border-color: transparent ${colors.text} transparent transparent;
    }

    &.tooltip__inner--active {
      transform: translate(8px, -50%);
    }
  }

  &.tooltip__inner--left {
    right: 100%;
    transform: translate(10px, -50%);

    &::before {
      right: 0;
      transform: translateX(80%) translateY(-50%);
      border-color: transparent transparent transparent ${colors.text};
    }

    &.tooltip__inner--active {
      transform: translate(-8px, -50%);
    }
  }
`
