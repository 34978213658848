import {AnchorHTMLAttributes, ButtonHTMLAttributes, CSSProperties, memo, ReactNode} from 'react'
import {Link, LinkProps} from 'react-router-dom'
import styled, {css} from 'styled-components'

import button from 'theme/button'
import colors from 'theme/colors'
import {useRipple} from './useRipple'

interface IButtonStyles {
  $br?: CSSProperties['borderRadius']
  $d?: CSSProperties['display']
  $fw?: CSSProperties['fontWeight']
  $fz?: CSSProperties['fontSize']
  $h?: CSSProperties['height']
  $lh?: CSSProperties['lineHeight']
  $m?: CSSProperties['margin']
  $p?: CSSProperties['padding']
  $ta?: CSSProperties['textAlign']
  $w?: CSSProperties['width']
}

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement>, IButtonStyles {
  children: ReactNode
  linkProps?: LinkProps
  theme?: keyof typeof colors | keyof typeof button
  variant?: 'text' | 'outlined' | 'filled'
}

const Button = ({children, linkProps, theme = 'primary', variant, ...rest}: IButton) => {
  const {ripple} = useRipple()

  if (linkProps) {
    return (
      <StyledLink
        $color={theme}
        $variant={variant || 'filled'}
        {...rest as AnchorHTMLAttributes<HTMLAnchorElement>}
        {...linkProps}
      >
        {children}
      </StyledLink>
    )
  }

  switch (variant) {
    case 'text':
      return (
        <ButtonText $color={theme} {...rest}>
          {ripple}
          {children}
        </ButtonText>
      )
    default:
      return (
        <ButtonFilled $color={theme} {...rest}>
          {ripple}
          {children}
        </ButtonFilled>
      )
  }
}

export default memo(Button)

export const buttonStyles = css<IButtonStyles>`
  position: relative;
  overflow: hidden;
  user-select: none;
  display: ${({$d}) => $d};
  width: ${({$w = '80px'}) => $w};
  height: ${({$h = '40px'}) => $h};
  padding: ${({$p = '8px 16px'}) => $p};
  margin: ${({$m = '0 10px 0 0'}) => $m};
  font-weight: ${({$fw = 700}) => $fw};
  font-size: ${({$fz = '15px'}) => $fz};
  line-height: ${({$lh = 1.5}) => $lh};
  border-radius: ${({$br = '4px'}) => $br};
  text-align: ${({$ta}) => $ta};
  outline: none;

  &:disabled,
  &.disabled {
    border-color: transparent;
    background-color: ${({theme}) => theme.colors.lightest};
    color: ${({theme}) => theme.colors.disabled};
  }

  &:hover:not(:disabled):not(.disabled) {
    opacity: 0.9;
  }
`

export const focusVisible = (colorName: string) => css`
  &:focus-visible {
    box-shadow: 0 0 0 2px ${(p) => p.theme.colors[colorName === 'primary' ? 'warning' : 'primary']};
  }
`

const ButtonText = styled.button<{$color: string} & IButtonStyles>`
  ${buttonStyles};
  background: none;
  color: ${(p) => p.theme.colors[p.$color]};
  ${({$color}) => focusVisible($color)};
`

const ButtonFilled = styled.button<{$color: string} & IButtonStyles>`
  ${buttonStyles};
  ${(p) => p.theme.button[p.$color]};
  ${({$color}) => focusVisible($color)};
`

type LinkStyleProps = IButtonStyles & {
  $color: string
  $variant: IButton['variant']
}

const StyledLink = styled(Link)<LinkStyleProps>`
  ${buttonStyles};
  ${({$color}) => focusVisible($color)};
  ${({$color, theme, $variant}) =>
    $variant === 'filled' ? theme.button[$color] : `color: ${theme.colors[$color]};`};
`
