import {useCallback} from 'react'
import {toast} from 'react-toastify'

import {useWidgets} from 'repository/graphql'
import {ActionName} from 'static/constants'
import {
  ActionType,
  DataSourceOptionsDocument, SaveActionInputArgs,
  useSavePageActionMutation,
} from 'generated/graphql-operations'
import {deselectAllWidgets, setActiveActionTypeVar, setSelectedWidgetsVar} from 'apollo'
import {ContainerId} from 'components/Toast/config'
import {setLoadingCursor} from 'templates/Settings/Widget/Styles/utils'

export type TFormulaPage = {
  isExecuteAction: boolean
  code: string
  prevCode: string
  type: ActionType
  pageId: number
  actionType: ActionName | undefined
}

export type TFormulaWidget = {
  isExecuteAction: boolean
  actionsCode: SaveActionInputArgs[]
  prevActionsCode: SaveActionInputArgs[]
  type: ActionType
  actionType: ActionName | undefined
  selectedWidgets: number[]
}

export type TFormula = TFormulaPage | TFormulaWidget

export const useFormulaRollback = () => {
  const {addWidgetAction} = useWidgets()
  const [savePageAction] = useSavePageActionMutation()

  return useCallback(
    async (data: TFormula): Promise<TFormula | void> => {
      try {
        const {actionType, type, isExecuteAction} = data
        const isPage = ['CLOSE', 'OPEN'].includes(type)
        setActiveActionTypeVar(data.actionType)

        if (!isPage) {
          const {actionsCode, prevActionsCode, selectedWidgets} = data as TFormulaWidget
          const widgetId = selectedWidgets[selectedWidgets.length - 1]
          setLoadingCursor(true, 'setWidgetFormulaData')
          const resp = await addWidgetAction({
            variables: {actionsCode: prevActionsCode},
            ...(type === 'CONNECTION' && {
              refetchQueries: [{query: DataSourceOptionsDocument, variables: {id: widgetId}}],
            }),
          }).finally(() => setLoadingCursor(false, 'setWidgetFormulaData'))

          if (!resp?.data) return
          setSelectedWidgetsVar(selectedWidgets)

          return {
            isExecuteAction,
            actionsCode: prevActionsCode,
            prevActionsCode: actionsCode,
            type,
            actionType,
            selectedWidgets,
          }
        } else {
          const {code, prevCode, pageId} = data as TFormulaPage
          setLoadingCursor(true, 'setPageFormulaData')
          const resp = await savePageAction({variables: {code: prevCode, type, pageId}}).finally(
            () => setLoadingCursor(false, 'setPageFormulaData'),
          )

          if (!resp?.data?.savePageAction) return
          deselectAllWidgets()

          return {
            isExecuteAction,
            code: prevCode,
            prevCode: code,
            type,
            actionType,
            pageId,
          }
        }
      } catch (e) {
        toast.warning('Lost undo-redo of code editor data', {containerId: ContainerId.ROOT})
      }
    },
    [addWidgetAction, savePageAction],
  )
}
