import {
  CSSProperties,
  MouseEvent,
  ReactChild,
  ReactNode,
  RefObject,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {useOutsideClick} from 'hooks'
import {setLoadingCursor} from 'templates/Settings/Widget/Styles/utils'

import {DropDownContainer, MenuItem, MenuText, OptionsList} from './styles'
import {MAX_HEIGHT, MAX_TOP, MENU_ITEM_HEIGHT} from './config'
import {Tooltip} from '../index'

type Value = string | number

export interface IList {
  name: Value | ReactChild
  action?: () => void
  decoration?: ReactNode
  classList?: string[]
}

interface IProps {
  children: (toggleView: () => void, visible: boolean, close: () => void) => ReactNode
  closeOnSelect?: boolean
  hasTooltip?: boolean
  fixedTopPosition?: boolean
  forceClose?: boolean
  isLoading?: boolean
  list: IList[]
  listWidth?: CSSProperties['width']
  margin?: string
  maxHeight?: CSSProperties['maxHeight']
  nameDrDn?: string
  overflow?: string
  positionX?: string
  preventBlur?: boolean
  relativeToParent?: boolean
  selection?: Value | Value[]
  textInSingleLine?: boolean
}

const DropDownList = ({
  children,
  closeOnSelect = true,
  hasTooltip = false,
  fixedTopPosition,
  forceClose,
  isLoading,
  list,
  listWidth,
  margin,
  maxHeight,
  nameDrDn = '',
  overflow,
  positionX,
  preventBlur,
  relativeToParent,
  selection,
  textInSingleLine,
}: IProps) => {
  const [visible, setVisible] = useState(false)

  const containerRef: RefObject<HTMLDivElement> = useRef(null)
  const selectionRef: RefObject<HTMLLIElement> = useRef(null)

  const close = () => setVisible(false)
  const toggleView = () => setVisible((st) => !st)

  // Scroll the selected item into view
  useEffect(() => {
    if (visible && selectionRef.current?.parentElement) {
      selectionRef.current.parentElement.scrollTop = selectionRef.current.offsetTop
    }
  }, [visible, selection])

  useEffect(() => {
    forceClose && setVisible(false)
  }, [forceClose])

  useEffect(() => {
    const [id, stopLoading] = setLoadingCursor(!!isLoading, 'DropDown')
    return () => {
      clearTimeout(id)
      stopLoading()
    }
  }, [isLoading])

  useOutsideClick(containerRef, close, visible)

  const isTopPosition = useMemo(() => {
    if (visible && containerRef.current) {
      if (relativeToParent) {
        const parent = containerRef.current.offsetParent
        if (parent) {
          const {height} = parent.getBoundingClientRect()
          const {offsetTop} = containerRef.current
          return height - offsetTop > MAX_TOP
        }
      }

      const {y} = containerRef.current.getBoundingClientRect()
      const listHeight = Math.min(list.length * MENU_ITEM_HEIGHT, MAX_HEIGHT)
      return y + listHeight <= window.innerHeight
    }
    return false
  }, [relativeToParent, list.length, visible])

  // works only for top
  const positionY = useMemo(() => {
    if (fixedTopPosition && isTopPosition && visible && containerRef.current) {
      const parent = containerRef.current.offsetParent
      if (parent) {
        const {top} = parent.getBoundingClientRect()
        const {offsetTop, offsetHeight} = containerRef.current
        return `${offsetTop + top + offsetHeight}px`
      }
    }
    return '100%'
  }, [fixedTopPosition, visible, isTopPosition])

  return (
    <DropDownContainer
      ref={containerRef}
      $margin={margin}
      onMouseDown={(e: MouseEvent) => e.stopPropagation()}
      data-testid='dropdown'
    >
      {children(toggleView, visible, close)}
      {visible && list.length > 0 && (
        <OptionsList
          $isTopPosition={isTopPosition}
          $listWidth={listWidth}
          $maxHeight={maxHeight}
          $overflow={overflow}
          $positionX={positionX}
          $positionY={positionY}
        >
          {list.map(({name, action, classList, decoration}, index) => {
            const isSelected =
              Array.isArray(selection) && typeof name !== 'object'
                ? selection.includes(name)
                : selection === name
            const hasRef =
              Array.isArray(selection) && typeof name !== 'object'
                ? selection[0] === name
                : selection === name

            return (
              <MenuItem
                key={`dropdown-item-${index}-in${nameDrDn}`}
                ref={hasRef ? selectionRef : null}
                className={['dropdown-menu-list-item', ...(classList || [])].join(' ')}
                $isSelected={isSelected}
                $textInSingleLine={textInSingleLine}
                onMouseDown={preventBlur ? (e: MouseEvent) => e.preventDefault() : undefined}
                onClick={
                  action
                    ? () => {
                        closeOnSelect && close()
                        action()
                      }
                    : undefined
                }
              >
                {decoration}
                {hasTooltip ? (
                  <Tooltip content={name} placement='fixed-container'>
                    <MenuText><span>{name}</span></MenuText>
                  </Tooltip>
                ) : (
                  name
                )}
              </MenuItem>
            )
          })}
        </OptionsList>
      )}
    </DropDownContainer>
  )
}

export default memo(DropDownList)
