import {ApolloClient} from '@apollo/client'

import {cache} from './cache/cache'
import defaultOptions from './defaultOptions'
import link from './httpLink'

export const client = new ApolloClient({
  link,
  cache,
  name: 'nedyx-apollo-client',
  version: '0.66',
  queryDeduplication: false,
  defaultOptions,
  credentials: 'include',
})
