import colors from './colors';

interface ITypographyProps {
  fontSize: string;
  fontWeight?: number;
  color?: string;
}

export interface ITypography {
  [propName: string]: string | ITypographyProps;
}

export const typography: ITypography = {
  fontFamily: 'Nunito, sans-serif',
  h1: {
    fontSize: '32px',
    fontWeight: 300,
    color: colors.header,
  },
  h2: {
    fontSize: '24px',
    fontWeight: 300,
  },
  h3: {
    fontSize: '22px',
    color: colors.text,
    fontWeight: 300,
  },
  subtitle: {
    fontSize: '18px',
    fontWeight: 400,
  },
  body1: {
    fontSize: '15px',
    color: colors.text,
    fontWeight: 400,
  },
  body1bold: {
    fontSize: '15px',
    color: colors.text,
    fontWeight: 700,
  },
  body1Small: {
    fontSize: '14px',
    color: colors.text,
    fontWeight: 400,
  },
  body1boldSmall: {
    fontSize: '14px',
    color: colors.text,
    fontWeight: 700,
  },
  body1InfoSmall: {
    fontSize: '13px',
    color: colors.infoText,
    fontWeight: 400,
  },
};
