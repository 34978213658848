import {CSSProperties} from 'react'
import styled from 'styled-components'

import {ReactComponent as LoaderSvg} from './loader.svg'

type PropTypes = {
  $isInside?: boolean
  $position?: CSSProperties['position']
}

export default function Loader({$isInside, $position}: PropTypes) {
  return (
    <LoaderWrapper $isInside={$isInside} $position={$position}>
      <p className='visually-hidden'>Loading&hellip;</p>
      <LoaderSvg aria-hidden='true' />
    </LoaderWrapper>
  )
}

const LoaderWrapper = styled.div<PropTypes>`
  position: ${({ $position = 'absolute' }) => $position};
  min-height: ${({ $isInside = false }) => ($isInside ? '100%' : ' 80vh')};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    animation: spin 1.5s linear infinite; // keyframes are defined in global.css
  }
`
