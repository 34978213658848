const menu = {
  menu: {
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)',
    listStyleType: 'none',
    position: 'absolute',
    borderRadius: '4px',
    color: '#3d3d3d',
    backgroundColor: 'white',
    fontSize: '14px',
    lineHeight: 1,
    zIndex: 1000,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '12px 16px',
    '&:hover': {
      backgroundColor: '#F8F8F8',
    },
  },
};

export default menu;
