import {makeVar} from '@apollo/client'

import {
  CodeEditorActionFragment,
  Maybe,
  Page,
  WidgetViewType,
  Widget,
  WidgetGeometry,
  WidgetStyles,
} from 'generated/graphql-operations'
import {setActiveWidgetTitleVar, setCodeEditorStatusVar} from './codeEditor.cache'

interface PrevSelectedWidget {
  curWidgetId?: Widget['id']
  prevWidgetId: Widget['id']
  prevPageId: Page['id']
}

export type ChartType =
  | 'area'
  | 'bar'
  | 'bubble'
  | 'column'
  | 'line'
  | 'pie'
  | 'polarArea'
  | 'radar'
  | 'verticalArea'
  | 'verticalLine'
  | 'vertWaterfall'
  | 'waterfall'
export type ValueType = string | number | boolean | null

type IJiveErrors = '!DIV0' | '!VALUETYPE' | '!VALUE' | '!REFERENCE' | '!RECURSION'
type WidgetValueType = ValueType | IJiveErrors
export type OneZero = 1 | 0

interface IWidgetDataType {
  value: WidgetValueType[][]
  selected?: number[]
  viewType?: Maybe<WidgetViewType>
  defaultItem?: Maybe<string>
}

export interface ISetWidgetDataOptions {
  operationType: 'insert' | 'delete'
  type: 'row' | 'column'
  startIndex: number
  endIndex: number
}

export interface IWidgetEditableEvaluated {
  value: OneZero[][]
}

export interface IWidgetGeometryIncludeKeyID {
  [key: string]: WidgetGeometry
}

export interface IWidgetGeometryWidthID extends Omit<WidgetGeometry, '__typename'> {
  id: number
}

type WidgetCellStylesValue = Array<Array<string | number>>
type WidgetCellStylesMap = WidgetStyles[]

export interface IWidgetCellStyles {
  __typename?: string
  value: WidgetCellStylesValue
  stylesMap: WidgetCellStylesMap
}

// `PageWidget` is a widget that results from `WidgetsByPageId` query.
// It's convenient to have a custom rather than a generated type for two reasons:
// 1. Some fields are typed as Scalars on the BE, which makes them pretty useless in TS.
// 2. We don't query for all the props in e.g. the `actions` field.
export type PageWidget = Pick<
  Widget,
  'id' | 'title' | 'type' | 'geometry' | 'styles'| 'labels' | 'tooltips' | 'format' | 'enabled' | 'visible' | 'scrollRefId'
> & {
  actions: CodeEditorActionFragment[]
  cellStyles: IWidgetCellStyles
  data: IWidgetDataType
  editableEvaluated: IWidgetEditableEvaluated
}
export type ActiveWidget = Pick<
  PageWidget,
  'id' | 'title' | 'type' | 'enabled' | 'visible' | 'editableEvaluated'
> & {
  actions?: CodeEditorActionFragment[]
}

type HighlightingWidgetType = {pageName: string; widgetName: string}

export type SelectedStyleTabType = 'Style' | 'Content' | 'Connection' | 'Chart Style'

// TODO change to setActiveWidgetIdVar
// Most likely, `setSelectedWidgetsVar` will be just enough.
// You can get the active widget id from `selectedWidgets` array, it's always the last one.
export const setActiveWidgetVar = makeVar<ActiveWidget | null>(null)
export const setSelectedWidgetsVar = makeVar<Widget['id'][]>([])
export const setHighlightingWidgetVar = makeVar<HighlightingWidgetType | null>(null)
export const setLatestSelectedWidgetVar = makeVar<PrevSelectedWidget | null>(null)
export const setWidgetsGeometryVarWithId = makeVar<IWidgetGeometryIncludeKeyID>({})
export const setOpenedMenuId = makeVar<number | null>(null)
export const setVisibleInEditMode = makeVar(true)
export const setSelectedStyleTabVar = makeVar<SelectedStyleTabType>('Style')

export type TScrollValue = {top: number; left: number; scrolledByUser?: boolean}
export type TScrollProps = Maybe<{
  [id: Widget['id']]: TScrollValue
}>

export const setWidgetScrollPositions = makeVar<TScrollProps>(null)

export enum TableWidgetState {
  UNSELECTED = 1,
  SELECTED,
  CELL_SELECTED,
  CELL_EDIT,
}

export const setEditTitleVar = makeVar<boolean>(false)
export const setTableWidgetStateVar = makeVar<number>(TableWidgetState.UNSELECTED)

export const resetActiveWidgetData = () => {
  setActiveWidgetVar(null)
  setEditTitleVar(false)
  setActiveWidgetTitleVar(null)
  setCodeEditorStatusVar('init')
}

export const deselectAllWidgets = () => {
  if (widgetCache.selectedWidgets.read().length) {
    setSelectedWidgetsVar([])
    resetActiveWidgetData()
  }
}

export type TActiveCell = {
  x: number | null
  y: number | null
  width: number
  height: number
}
export const setActiveCellTableVar = makeVar<TActiveCell>({
  x: null,
  y: null,
  width: 0,
  height: 0,
})
export const inactiveCell: TActiveCell = {x: null, y: null, width: 0, height: 0}

export type TCellCoordinate = {x: number; y: number}
export type TCellPosition = TCellCoordinate & {height: number; width: number}
export const setMultiSelectCellsVar = makeVar<Maybe<TCellCoordinate[]>>(null)

export const isMovedDetectionVar = makeVar<boolean>(false)
export const setCellGlobalPosition = makeVar<TCellPosition | null>(null)

export const widgetCache = {
  selectedWidgets: {
    read() {
      return setSelectedWidgetsVar()
    },
  },
}
